import 'babel-polyfill';
import VCalendar from 'v-calendar';

import Vue from 'vue';
import router from './router';

import App from './App.vue';
import i18n from './lang/lang';
import store from './store';
import { utils } from './utils';

const isDebug_mode = process.env.NODE_ENV !== 'production';

Vue.config.debug = isDebug_mode;
Vue.config.devtools = isDebug_mode;
Vue.config.productionTip = false;
Vue.mixin(utils);
Vue.prototype.$baseUrl = process.env.BASE_URL;

Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar />
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
