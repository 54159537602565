<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message :type="message.type" :message="message.text"></full-page-message>
    </div>
    <div v-if="!message.type" class="main-block">
      <app-sidebar></app-sidebar>
      <div class="main-content">
        <div class="lang-item">
          <lang-selector></lang-selector>
        </div>
        <div class="main-item">
          <img class="witcher__logo" :src="`${$baseUrl}witcher_logo.png`" />
        </div>
        <div class="main-item mt-6p">
          <div class="form__title">{{ $t('login_col_companyid') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.brandIDError }"
            v-model="form.brandID"
            v-on:focus="
              status.brandIDError = null;
              message.apiError = null;
            "
            v-on:blur="status.brandIDError = checkBrandIDFormat(form.brandID)"
            v-on:keyup.enter="postUserLogin"
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.brandIDError" class="form__error">{{ $t('login_client_companyid_format') }}</div>
        </div>
        <div class="main-item mt-3p">
          <div class="form__title">{{ $t('login_col_email') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.mailError }"
            v-model.trim="form.userEmail"
            v-on:focus="
              status.mailError = null;
              message.apiError = null;
            "
            v-on:blur="status.mailError = checkMailFormat(form.userEmail)"
            v-on:keyup.enter="postUserLogin"
          />
        </div>
        <div class="main-item mt-6">
          <div class="form__error">
            <span v-if="status.mailError">{{ $t('login_client_email_format') }}</span>
          </div>
        </div>
        <div class="main-item mt-3p">
          <div class="form__title">{{ $t('login_col_pwd') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="password"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-on:focus="message.apiError = null"
            v-model="form.userPassowrd"
            v-on:keyup.enter="postUserLogin"
          />
        </div>
        <div v-if="version === 'online'" class="main-item mt-40">
          <a class="form__link" href="/user/forget_pwd">{{ $t('login_button_forget_pwd') }}</a>
        </div>
        <div v-if="version === 'offline'" class="main-item mt-40">
          <router-link to="/license/update">
            <a class="form__link">序號更新</a>
          </router-link>
        </div>
        <div class="main-item mt-40">
          <a class="long-button" :disabled="!isComplete" v-on:click="postUserLogin">{{ $t('login_button_login') }}</a>
        </div>
        <div class="main-item mt-20">
          <div v-if="message.apiError" class="form__api__error" v-html="message.apiError"></div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import sha256 from 'js-sha256';

import { apiAccountLogin, apiCheckUserStatus } from '../api.js';
import FullPageMessage from './partials/FullPageMessage.vue';
import InlineLoading from './partials/InlineLoading.vue';
import LangSelector from './partials/LangSelector.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'UserLogin',
  data: function () {
    return {
      activeSidebar: process.env.VUE_APP_SIDEBAR.split(', '),
      version: process.env.VUE_APP_VERSION,
      status: {
        brandIDError: false,
        mailError: false,
        isLoading: false,
      },
      form: {
        brandID: null,
        userEmail: null,
        userPassowrd: null,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
    };
  },
  components: {
    'full-page-message': FullPageMessage,
    'inline-loading': InlineLoading,
    'app-sidebar': Sidebar,
    'lang-selector': LangSelector,
  },
  created() {
    const $ = this;
    $.$store.commit('setForceLogout', false);
    $.checkUserStatus();
  },
  computed: {
    isComplete() {
      return (
        !this.status.brandIDError &&
        !this.status.mailError &&
        !this.status.isLoading &&
        this.form.brandID &&
        this.form.userEmail &&
        this.form.userPassowrd
      );
    },
  },
  methods: {
    offlineLogin: function (userForm) {
      const $ = this;

      apiAccountLogin({
        brand_id: userForm.brandID,
        email: userForm.userEmail,
        password: sha256(userForm.userPassowrd),
      })
        .then(function (response) {
          const userData = response.data;
          $.$store.commit('setUserData', { userData });
          if (Array.isArray(userData['mail_service'])) {
            userData['mail_service'].forEach((element) => {
              const msData = element;
              $.$store.commit('setMailServiceData', { msData });
            });
          }
          Cookies.set('session', 'yes', { expires: 3, path: '/' });
          $.updateLicenseStatus();
          $.updateSystemVersion();
          setTimeout(function () {
            if ($.activeSidebar.includes('query') && $.isQueryValidDataset()) {
              $.$router.push('/');
            } else if ($.activeSidebar.includes('report') && $.isReportValidDataset()) {
              $.$router.push('/num_report');
            } else if ($.activeSidebar.includes('api') && $.isQueryValidDataset()) {
              $.$router.push('/api_docs');
            } else {
              if (userData.role === 'admin') {
                $.$router.push('/user/manage');
              } else {
                $.$router.push('/user/account');
              }
            }
          }, 1000);
          // Hard code Mask UUID - [B2BIDE-1722]
          const masked = $.checkMaskedUUID(userData.brand_id);
          $.$store.commit('setEnableMaskUUID', masked);
          // Hard code Mask AppKey - [B2BIDE-1723]
          const maskedAppKey = $.checkMaskedAppKey(userData.brand_id);
          $.$store.commit('setEnableMaskAppKey', maskedAppKey);
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'PASSWORD_NOT_MATCH') {
              $.message.apiError = $.$i18n.t('login_error_400_26');
            } else if (error.response.data.msg === 'ACCOUNT_NOT_EXIST') {
              $.message.apiError = $.$i18n.t('login_error_400_25');
            } else if (error.response.data.msg === 'ALREADY_LOGIN') {
              $.message.apiError = $.$i18n.t('login_error_endpoint');
            } else if (error.response.data.msg === 'ACCOUNT_BANNED') {
              $.message.type = 'LoginAccountBanned';
            } else if (error.response.data.msg === 'LICENSE_EXPIRED') {
              $.message.type = 'LoginLicenseExpire';
            } else if (error.response.data.msg === 'IP_NOT_ALLOWED') {
              $.$router.push('/forbidden');
            } else {
              $.message.apiError = $.$i18n.t(`api_error.${error.response.data.msg}`);
            }
          } else {
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        })
        .finally(function () {
          $.status.isLoading = false;
        });
    },
    onlineLogin: function (userForm) {
      const $ = this;

      apiCheckUserStatus()
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.status.isLoading = false;
            $.message.apiError = `${$.$i18n.t('login_with_another_account_title')}<br>${$.$i18n.t(
              'login_with_another_account_desc'
            )}`;
          } else {
            apiAccountLogin({
              brand_id: userForm.brandID,
              email: userForm.userEmail,
              password: userForm.userPassowrd,
            })
              .then(function (response) {
                const res = response.data;

                if (res.msg === 'OK') {
                  const userData = res.res;
                  $.$store.commit('setUserData', { userData });
                  if (Array.isArray(userData['mail_service'])) {
                    userData['mail_service'].forEach((element) => {
                      const msData = element;
                      $.$store.commit('setMailServiceData', { msData });
                    });
                  }
                  $.updateLicenseStatus();
                  setTimeout(function () {
                    if ($.activeSidebar.includes('query') && $.isQueryValidDataset()) {
                      $.$router.push('/');
                    } else {
                      if (userData.role === 'admin') {
                        $.$router.push('/user/manage');
                      } else {
                        $.$router.push('/user/account');
                      }
                    }
                  }, 1000);

                  // Hard code Mask UUID - [B2BIDE-1722]
                  const masked = $.checkMaskedUUID(userData.brand_id);
                  $.$store.commit('setEnableMaskUUID', masked);
                  // Hard code Mask AppKey - [B2BIDE-1723]
                  const maskedAppKey = $.checkMaskedAppKey(userData.brand_id);
                  $.$store.commit('setEnableMaskAppKey', maskedAppKey);
                } else if (res.msg === 'ALREADY_LOGIN') {
                  $.message.apiError = $.$i18n.t('login_error_endpoint');
                } else {
                  $.message.apiError = $.$i18n.t(`api_error: ${res.msg}`);
                }
              })
              .catch(function (error) {
                if (error.response.status === 400) {
                  if (error.response.data.msg === 'PASSWORD_NOT_MATCH') {
                    if (error.response.data.fail_count < 8) {
                      $.message.apiError = $.$i18n.t('login_error_400_26');
                    } else {
                      $.message.apiError = $.$i18n.t('login_error_400_26_client_remian', {
                        remail_fail_cnt: 11 - error.response.data.fail_count,
                      });
                    }
                  } else if (error.response.data.msg === 'ACCOUNT_NOT_EXIST') {
                    $.message.apiError = $.$i18n.t('login_error_400_25');
                  } else if (error.response.data.msg === 'ACCOUNT_BANNED') {
                    $.message.type = 'LoginAccountBanned';
                  } else if (error.response.data.msg === 'LICENSE_EXPIRED') {
                    $.message.type = 'LoginLicenseExpire';
                  } else if (error.response.data.msg === 'IP_NOT_ALLOWED') {
                    $.$router.push('/forbidden');
                  } else {
                    $.message.apiError = $.$i18n.t(`api_error.${error.response.data.msg}`);
                  }
                } else {
                  $.message.type = 'APIError';
                  $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
                }
              })
              .finally(function () {
                $.status.isLoading = false;
              });
          }
        })
        .catch(function (error) {
          $.status.isLoading = false;
          $.message.type = 'APIError';
          $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
        });
    },
    postUserLogin: function () {
      const $ = this;
      $.message.apiError = null;

      if (!$.isComplete) {
        return false;
      }

      $.status.isLoading = true;
      if (process.env.VUE_APP_VERSION === 'offline') {
        $.offlineLogin($.form);
      } else {
        $.onlineLogin($.form);
      }
    },
    checkMaskedUUID: function(brandID) {
      const maskUUIDBrandIDs = process.env.VUE_APP_MASK_UUID_BRAND_IDS;
      const brandIDArray = maskUUIDBrandIDs.replace(/\s+/g, '').split(',');
      const isBrandIDInArray = brandIDArray.includes(brandID);
      return isBrandIDInArray;
    },
    checkMaskedAppKey: function (brandID) {
      const maskAppKeyBrandIDs = process.env.VUE_APP_MASK_APPKEY_BRAND_IDS;
      const brandIDArray = maskAppKeyBrandIDs.replace(/\s+/g, '').split(',');
      const isBrandIDInArray = brandIDArray.includes(brandID);
      return isBrandIDInArray;
    }
  },
};
</script>

<style scoped>
.main-block {
  display: flex;
  height: 100vh;
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #ffffff;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
  align-items: center;
}

.main-item {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.lang-item {
  width: 160px;
  position: absolute;
  top: 40px;
  right: 40px;
}
</style>
