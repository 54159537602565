<template>
  <div class="dialog-item">
    <div v-if="type == 'UserManageAdd'" class="dialog">
      <div class="dialog__title">新增成員</div>
      <div class="dialog__desc">請輸入對方電子信箱，以寄送電子郵件邀請</div>
      <div class="main-item mt-30">
        <div class="form__title">{{ $t('license_active_col_admin_email') }}</div>
      </div>
      <div class="main-item mt-10">
        <input
          class="form__input"
          type="text"
          v-bind:class="{ 'has-error': status.mailError }"
          v-model.trim="form.inviteEmail"
          v-on:blur="status.mailError = checkMailFormat(form.inviteEmail)"
        />
      </div>
      <div v-if="status.mailError" class="main-item mt-6">
        <div class="form__error">{{ $t('license_active_client_email_format') }}</div>
      </div>
      <div class="main-item mt-60">
        <a class="long-button" :disabled="!isComplete" v-on:click="postUserInvite">{{ $t('general.submit') }}</a>
      </div>
      <div class="main-item mt-20">
        <div v-if="message.apiError" class="form__api__error">{{ message.apiError }}</div>
      </div>
      <div class="dialog__cancel icon-close"></div>
    </div>
  </div>
</template>

<script>
import { apiAccountUserInvite } from '../../api.js';

export default {
  name: 'CustomDialog',
  data() {
    return {
      licenseData: {
        remain_account: this.$store.state.license.license_remain_account,
      },
      status: {
        mailError: false,
      },
      form: {
        inviteEmail: null,
      },
      message: {
        apiError: null,
      },
    };
  },
  props: {
    type: String,
  },
  computed: {
    isComplete() {
      return !this.status.mailError && this.form.inviteEmail;
    },
  },
  methods: {
    postUserInvite: function () {
      const $ = this;
      $.message.apiError = null;

      apiAccountUserInvite({
        invite_email: $.form.inviteEmail,
      })
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            alert('OK');
            $.refreshRemainAccount();
          } else {
            $.message.apiError = $.$i18n.t(`api_error.${res.msg}`);
          }

          $.status.isLoading = false;
        })
        .catch(function (error) {
          $.message.type = 'APIError';
          $.message.text = error;
        });
    },
  },
};
</script>

<style>
.dialog-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.dialog {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 680px;
  height: fit-content;
  max-height: calc(100vh - 100px);
  left: calc(((100vw - 20% - 680px) / 2) + 20%);
  background-color: #ffffff;
  padding: 60px 70px;
  border-radius: 2px;
  text-align: center;
  overflow-y: scroll;
}

.dialog__cancel {
  position: absolute;
  top: 27.5px;
  right: 27.5px;
}

.dialog__title {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
}

.dialog__desc {
  font-size: 14px;
  color: #000000;
}

.dialog__cred__desc {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.dialog__btns {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.dialog__button {
  box-sizing: border-box;
  min-width: 200px;
}

.dialog__cancel {
  cursor: pointer;
}

.loader-text {
  color: #640061;
  margin-top: 20px;
}

#largeBox {
  height: 20px;
  width: 20px;
  /* background-color: #ffffff; */
  background-image: linear-gradient(to bottom, #640061, rgba(100, 0, 97, 0.5));
  outline: 1px solid transparent;
  position: fixed;
}

#smallBox {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  /* background-image: linear-gradient(to bottom, #640061, rgba(100, 0, 97, 0.5)); */
  position: fixed;
  z-index: 2;
  outline: 1px solid transparent;
  animation: smallBoxAnim 1.25s alternate infinite ease-in-out;
}

@keyframes smallBoxAnim {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes loaderAnim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
</style>
