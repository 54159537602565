<template>
  <div id="main-content" class="main-content" ref="printMe">
    <div class="main-item" id="main-item">
      <div class="basic__block">
        <div class="download__btn">
          <a class="main-button" v-on:click="downloadResult">{{ $t('identity_report_result_download_button') }}</a>
        </div>
        <div class="basic__block__title">
          <img class="witcher__logo__dark" :src="`${$baseUrl}witcher_logo_dark.png`" />
          <span>　{{ $t(`identity_report_${scenario}_title`) }}</span>
        </div>
        <div class="basic__block__content">
          {{ $t('identity_report_brand_name') }}: {{ $store.state.license.brand_name }}
        </div>
        <div class="basic__block__content">{{ $t('identity_report_current_time') }}: {{ currentDateTime() }}</div>
        <div class="basic__block__hint mt-5">{{ $t('identity_report_hint') }}</div>

        <div class="number mt-30">{{ number }}</div>
      </div>
      <div class="factor__detail__block mt-20" v-for="category in filteredFactorsCategory" :key="category">
        <div class="content__block" v-if="category === '1_number_info'">
          <div class="number__detail__title">{{ $t(`identity_report_category_${category}`) }}</div>
          <div class="number__detail mt-15">
            <div v-for="(feature, index) in identityReportResult[category]" :key="index">
              <div v-if="feature.name === 'status_verify_yn'">
                ▪︎ {{ $t(`identity_report_${scenario}_${feature.name}_name`) }}：{{
                  $t(`identity_report_${scenario}_${feature.name}_result_${feature.result}`)
                }}
              </div>
              <div v-else>
                ▪︎ {{ $t(`identity_report_${scenario}_${feature.name}_name`) }}：<span v-if="feature.result">{{
                  feature.result
                }}</span
                ><span v-else>-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="factor__block" v-else>
          <div class="factor__title">{{ $t(`identity_report_category_${category}`) }}</div>
          <div class="factor__block__list factor__block__head mt-20">
            <div class="factor__block__list-item feature_name">{{ $t('identity_report_table_header_name') }}</div>
            <div class="factor__block__list-item feature_result">{{ $t('identity_report_table_header_result') }}</div>
            <div class="factor__block__list-item feature_desc">{{ $t('identity_report_table_header_desc') }}</div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(feature, index) in sortingWithI18nOrder(identityReportResult[category])"
            :key="index"
          >
            <div class="factor__block__list-item feature_name">
              {{ $t(`identity_report_${scenario}_${feature.name}_name`) }}
            </div>
            <div class="factor__block__list-item feature_result">
              <span :class="[`level__circle__${feature.result}`]" class="mr-10"></span>
              {{ $t(`identity_report_${scenario}_result_${feature.result}`) }}
            </div>
            <div class="factor__block__list-item feature_desc">
              <span v-if="feature.result == 1">-</span>
              <span v-else>{{ $t(`identity_report_${scenario}_${feature.name}_${feature.result}_desc`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="credit__risk__block mt-40" v-if="hasCreditRisk">
        <div class="credit__risk__result" v-for="(feature, index) in identityReportResult['credit_risk']" :key="index">
          {{ $t(`identity_report_${scenario}_${feature.name}_name`) }}：{{ feature.result }}
        </div>
        <div class="credit__risk__desc mt-10" v-html="$t('identity_report_credit_risk_desc')"></div>
        <div class="chart__block mt-20">
          <credit-risk-chart
            :width="500"
            :height="400"
            :styles="{ border: '2px solid #000', padding: '20px' }"
          ></credit-risk-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import VueCharts from 'vue-chartjs';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-trendline';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
  name: 'IdentityReportDetailResult',
  data() {
    return {
      scenario: this.$store.state.license.scenario[0],
    };
  },
  props: {
    number: String,
    identityReportResult: Object,
  },
  computed: {
    filteredFactorsCategory() {
      let filtered = {};
      for (var category in this.identityReportResult) {
        if (category !== 'credit_risk') {
          filtered[category] = this.identityReportResult[category];
        }
      }
      return Object.keys(filtered).sort(this.sortingIdentityReportFactors);
    },
    hasCreditRisk: function () {
      return this.containsKey(this.identityReportResult || {}, 'credit_risk');
    },
  },
  methods: {
    currentDateTime() {
      return moment().tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
    },
    downloadResult: function () {
      html2canvas(this.$refs.printMe, {
        width: document.getElementById('main-item').offsetWidth + 160,
        height: document.getElementById('main-item').offsetHeight + 80,
      }).then((canvas) => {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        var pageHeight = (contentWidth / 592.28) * 841.89;
        var leftHeight = contentHeight;
        var position = 0;
        var imgWidth = 595.28;
        var imgHeight = (592.28 / contentWidth) * contentHeight;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);

        var pdf = new jsPDF('', 'pt', 'a4');
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }
        pdf.save(`witcherfin_identity_report_${this.number}.pdf`);
      });
    },
    sortingWithI18nOrder: function(data) {
      const $ = this;
      let copy = [...data]
      return copy.sort(function(a, b) {
        let i18a = $.$i18n.t(`identity_report_${$.scenario}_${a.name}_name`)
        let i18b = $.$i18n.t(`identity_report_${$.scenario}_${b.name}_name`)

        let lenA = i18a.length;
        let lenB = i18b.length;

        let prefixLength = 0;
        while (prefixLength < lenA && prefixLength < lenB && i18a[prefixLength] === i18b[prefixLength]) {
          prefixLength++;
        }

        if (prefixLength === lenA && prefixLength === lenB) {
          return 0;
        } else if (prefixLength === lenA || prefixLength === lenB) {
          return lenA - lenB;
        } else {
          const remainLengthA = lenA - prefixLength;
          const remainLengthB = lenB - prefixLength;
          return remainLengthA - remainLengthB;
        }
      })
    }
  },
  components: {
    'credit-risk-chart': {
      extends: VueCharts.Bar,
      mounted() {
        let dashImage = new Image(16, 2)
        dashImage.src = this.$baseUrl+'img_dash.png'
        this.addPlugin(ChartJsPluginDataLabels);
        this.renderChart(
          {
            labels: ['A', 'B', 'C', 'D', 'E'],
            datasets: [
              {
                type: 'line',
                label: '平均違約線',
                data: [1, 1, 1, 1, 1],
                fill: false,
                borderDash: [10, 10],
                borderColor: '#aeaeae',
                borderWidth: 2,
                pointRadius: 0,
                datalabels: {
                  display: false,
                },
              },
              {
                type: 'bar',
                label: '',
                backgroundColor: '#640061',
                // backgroundColor: '#BB93BA',
                data: [0.4, 0.6, 0.9, 1.2, 3.2],
                // trendlineLinear: {
                //   style: "#3d003c",
                //   lineStyle: "solid",
                //   width: 2,
                //   projection: true,
                // },
                datalabels: {
                  color: '#fff',
                  anchor: 'end',
                  align: 'start',
                },
                barPercentage: 0.8,
              },
            ],
          },
          {
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: '評等',
                    fontSize: 16,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: '幾倍違約率',
                    fontSize: 16,
                  },
                  ticks: {
                    stepSize: 1,
                  },
                },
              ],
            },
            title: {
              display: true,
              text: '違約倍率分佈',
              fontSize: 24,
            },
            responsive: false,
            maintainAspectRatio: false,
            legend: {
              labels: {
                filter: item => {
                  // skip without label text
                  return !!item.text.length
                },
                usePointStyle: true, 
                generateLabels: chart => chart.data.datasets.map((dataset, i) => ({
                  datasetIndex: i,
                  text: dataset.label,
                  pointStyle: dashImage,
                })) 
              }
            }
          }
        );
      },
    },
  },
};
</script>

<style scoped>
.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  flex-direction: column;
  background-color: #ffffff;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px 80px;
}

.main-item {
  width: 100%;
}

.basic__block {
  position: relative;
  justify: center;
}

.basic__block__title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
}

.witcher__logo__dark {
  width: 180px;
}

.basic__block__content {
  font-size: 12px;
}

.basic__block__hint {
  font-size: 12px;
  color: #aeaeae;
}

.download__btn {
  position: absolute;
  right: 0;
}

.number {
  font-size: 48px;
  font-weight: 600;
  color: #000000;
}

.number__detail {
  flex-basis: 100%;
  font-size: 14px;
  color: #000000;
}

.factor__detail__block {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.factor__block {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  background-color: #ffffff;
}

.content__block {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.factor__block__list {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
}

.factor__block__list-item.feature_name {
  width: 40%;
  padding: 0 20px;
  word-break: break-all;
}

.factor__block__list-item.feature_result {
  width: 10%;
  padding: 0 20px;
  word-break: break-all;
}

.factor__block__list-item.feature_desc {
  width: 50%;
  padding: 0 20px;
  word-break: break-all;
}

.factor__title,
.number__detail__title {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.factor__block__head {
  height: 30px;
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.factor__block__content {
  min-height: 30px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

.level__circle__3 {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #870136;
  border-radius: 50%;
}

.level__circle__2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #b49624;
  border-radius: 50%;
}

.level__circle__1 {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #378619;
  border-radius: 50%;
}

.credit__risk__block {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.credit__risk__result {
  flex-basis: 100%;
  font-size: 36px;
  font-weight: 600;
}

.credit__risk__desc {
  flex-basis: 100%;
  font-size: 14px;
}

.chart__block {
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
}

#canvas-img {
  display: none;
}

#bar-chart {
  border: 1px solid #000;
}
</style>
