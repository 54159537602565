<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message :type="message.type" :message="message.text"></full-page-message>
    </div>
    <div v-if="!message.type" class="main-block">
      <div class="main-content">
        <div class="lang-item">
          <lang-selector></lang-selector>
        </div>
        <div class="main-item mt-80">
          <div class="page__title">{{ $t('login_forget_pwd_title') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('login_forget_pwd_col_companyid') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-model="form.brandID"
            v-on:blur="status.brandIDError = checkBrandIDFormat(form.brandID)"
            v-on:focus="
              status.brandIDError = null;
              message.apiError = null;
            "
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.brandIDError" class="form__error">{{ $t('login_forget_pwd_client_companyid_format') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('login_forget_pwd_col_email') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-error': status.mailError, 'has-loading': status.isLoading }"
            v-model.trim="form.userEmail"
            v-on:blur="status.mailError = checkMailFormat(form.userEmail)"
            v-on:focus="
              status.mailError = null;
              message.apiError = null;
            "
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.mailError" class="form__error">{{ $t('login_forget_pwd_client_email_format') }}</div>
        </div>
        <div class="main-item mt-60">
          <a class="long-button" :disabled="!isComplete" v-on:click="postForgetPassword">{{
            $t('login_forget_pwd_button')
          }}</a>
        </div>
        <div class="main-item mt-20">
          <div v-if="message.apiError" class="form__api__error">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiAccountResetpwdEmail } from '../api.js';
import FullPageMessage from './partials/FullPageMessage.vue';
import InlineLoading from './partials/InlineLoading.vue';
import LangSelector from './partials/LangSelector.vue';

export default {
  name: 'UserChangePassword',
  data() {
    return {
      status: {
        brandIDError: false,
        mailError: false,
        isLoading: false,
      },
      form: {
        brandID: null,
        userEmail: null,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
    };
  },
  components: {
    'full-page-message': FullPageMessage,
    'inline-loading': InlineLoading,
    'lang-selector': LangSelector,
  },
  computed: {
    isComplete() {
      return (
        !this.status.brandIDError &&
        !this.status.mailError &&
        !this.status.isLoading &&
        this.form.brandID &&
        this.form.userEmail
      );
    },
  },
  methods: {
    postForgetPassword: function () {
      const $ = this;
      $.status.isLoading = true;
      $.message.apiError = null;

      apiAccountResetpwdEmail({
        brand_id: this.form.brandID,
        user_email: this.form.userEmail,
      })
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.message.type = 'ForgetPwdSuccess';
          } else if (res.msg === 'ACCOUNT_NOT_EXIST') {
            $.message.apiError = $.$i18n.t('login_forget_pwd_error_400_25');
          } else {
            $.message.apiError = $.$i18n.t(`api_error.${res.msg}`);
          }

          $.status.isLoading = false;
        })
        .catch(function (error) {
          $.status.isLoading = false;
          $.message.type = 'APIError';
          $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
        });
    },
  },
};
</script>

<style scoped>
.main-block {
  display: flex;
  height: 100vh;
}

.main-content {
  flex-basis: 100%;
  background-color: #fff;
  overflow-y: scroll;
}

.main-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang-item {
  width: 160px;
  position: absolute;
  top: 40px;
  right: 60px;
}
</style>
