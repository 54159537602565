<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message
        :type="message.type"
        :message="message.text"
        :role="$store.state.user.role"
      ></full-page-message>
    </div>
    <app-header :title="$t('numsearch_result_single_page')" v-if="!message.type"></app-header>
    <div class="main-block" v-if="!message.type">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <bank-and-rental-detail-result
        v-if="$store.state.license.dataset === 'bank' || $store.state.license.dataset === 'rental'"
        :number="number"
        :bankAndRentalResult="bankAndRentalResult"
      ></bank-and-rental-detail-result>
      <identity-report-detail-result
        v-else-if="$store.state.license.dataset === 'identity_report'"
        :number="number"
        :identityReportResult="IdentityReportResult.factorsData"
      ></identity-report-detail-result>
      <div v-else>Invalid Dataset</div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'showExitAlert'">
        <div class="dialog__title" v-html="$t('numsearch_result_pageback_title')"></div>
        <div class="dialog__desc mt-20" v-html="$t('numsearch_result_pageback_desc')"></div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialog">{{
            $t('numsearch_result_pageback_cancel')
          }}</a>
          <a class="main-button dialog__button ml-20" :href="exitNext">{{ $t('numsearch_result_pageback_button') }}</a>
        </div>
      </div>
    </div>
    <div id="canvas-img"></div>
  </div>
</template>

<script>
import sha256 from 'js-sha256';

import { apiCheckSearchApiStatus, apiSearch, apiSearchDetail } from '../api';
import { IDENTITY_REPORT } from '../identity_report';
import BankAndRentalDetailResult from './partials/BankAndRentalDetailResult.vue';
import IdentityReportDetailResult from './partials/IdentityReportDetailResult.vue';
import FullPageMessage from './partials/FullPageMessage.vue';
import Header from './base/header.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'SearchSingleResult',
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      number: this.$route.params.number,
      numberSha256: sha256(this.$route.params.number),
      state: this.$route.params.state,
      output: null,
      dialog: {
        type: null,
      },
      exitNext: null,
      hasChanged: false,
      message: {
        type: null,
        text: null,
        apiError: null,
      },
      status: {
        systemMaintenance: false,
      },
      bankAndRentalResult: {
        factorsData: null,
        radarChart: {
          labels: [],
          data: [],
          width: 0,
          bgColor: null,
          bgColorRgba: null,
        },
        scoreImg: {
          'background-image': `url(${process.env.VUE_APP_ROUTER_BASE}img_number_score.png)`,
        },
        scoreMargin: {},
        scoreWidth: {},
      },
      bankAndRentalFactorGroup: {
        radar_blacklist_score: 'radar_group',
        radar_activity_tracking_score: 'radar_group',
        radar_abnormal_pattern_score: 'radar_group',
        radar_cluster_analysis_score: 'radar_group',
        radar_state_detect_score: 'radar_group',
        spam_num: 'state_detect',
        special_num: 'state_detect',
        is_invalid_number: 'black_list',
        in_fraud_db: 'black_list',
        bad_history: 'black_list',
        using_frequency: 'activity_tracking',
        reachable: 'activity_tracking',
        last_activity_range: 'activity_tracking',
        has_social_activity: 'activity_tracking',
        has_traffic_pattern: 'crime_pattern',
        has_abnormal_pattern: 'crime_pattern',
        has_multiple_region: 'crime_pattern',
        special_loan_cluster: 'cluster_analysis',
        whitelist_cluster: 'cluster_analysis',
        phony_account_cluster: 'cluster_analysis',
        agency_cluster: 'cluster_analysis',
        debt_collect_cluster: 'cluster_analysis',
        spec_career_cluster: 'cluster_analysis',
        pawnshop_cluster: 'cluster_analysis',
        installment_cluster: 'cluster_analysis',
      },
      scenario: this.$store.state.license.scenario[0],
      IdentityReportResult: {
        factorsData: null,
      },
      identityReportFactorsCategory: IDENTITY_REPORT.FACTORS_CATEGORY,
    };
  },
  filters: {
    roundToInt: function (value) {
      return Math.round(value);
    },
    replaceSeparation: function (value, locale) {
      if (locale === 'en_US') {
        return value.replace(/\//g, ' | ');
      }

      return value.replace(/\//g, '、');
    },
  },
  components: {
    'app-header': Header,
    'full-page-message': FullPageMessage,
    'bank-and-rental-detail-result': BankAndRentalDetailResult,
    'identity-report-detail-result': IdentityReportDetailResult,
    'app-sidebar': Sidebar,
  },
  created() {
    const $ = this;

    if (this.version === 'offline') {
      $.searchDetail();
    } else {
      apiCheckSearchApiStatus()
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'SYSTEM_MAINTENANCE') {
            $.status.systemMaintenance = true;
            $.$router.push('/');
          } else {
            $.searchDetail();
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }
  },
  beforeRouteEnter: function (to, from, next) {
    const stateList = ['single', 'batch'];
    let state = null;

    if (to.name === 'SearchSingleNumber') {
      state = to.params.state;

      if (!stateList.includes(state)) {
        next({ path: '/' });
      } else {
        next();
      }
    }
  },
  beforeRouteLeave: function (to, from, next) {
    if (to.path !== '/user/login' && !this.status.systemMaintenance) {
      const $ = this;
      $.exitNext = this.pathJoin(process.env.VUE_APP_ROUTER_BASE, to.path);
      $.dialog.type = 'showExitAlert';
    } else {
      next();
    }
  },
  methods: {
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
    searchDetail: function () {
      const dataset = this.$store.state.license.dataset;
      if (dataset === 'bank' || dataset === 'rental') {
        this.searchBankAndRentalDetail();
      } else if (dataset === 'identity_report') {
        this.searchIdentityReport();
      }
    },
    searchBankAndRentalDetail: function () {
      const userLang = this.$store.state.user.lang;
      const searchNumber = this.version === 'offline' ? this.numberSha256 : this.number;

      apiSearchDetail({
        number: searchNumber,
        locale: userLang,
      })
        .then((response) => {
          const result = this.bankAndRentalResult;
          result.factorsData = response.data;
          var groupData = {
            radar_group: [],
            summary_group: [],
            state_detect: [],
            black_list: [],
            activity_tracking: [],
            crime_pattern: [],
            cluster_analysis: [],
          };

          for (var item in result.factorsData) {
            if (this.containsKey(this.bankAndRentalFactorGroup, item)) {
              groupData[`${this.bankAndRentalFactorGroup[item]}`].push({
                name: item,
                level: result.factorsData[item],
              });
            } else {
              groupData[item] = result.factorsData[item];
            }
          }

          result.factorsData = groupData;

          if (result.factorsData.alert === 3) {
            result.radarChart.bgColor = '#870136';
            result.radarChart.bgColorRgba = 'rgba(135, 1, 54, 0.6)';
          } else if (result.factorsData.alert === 2) {
            result.radarChart.bgColor = '#b49624';
            result.radarChart.bgColorRgba = 'rgba(180, 150, 36, 0.6)';
          } else {
            result.radarChart.bgColor = '#378619';
            result.radarChart.bgColorRgba = 'rgba(55, 134, 25, 0.6)';
          }

          result.radarChart.width = (document.getElementById('main-content').offsetWidth - 250) / 2;

          for (var i = 0; i < result.factorsData.radar_group.length; i++) {
            result.radarChart.labels.push(
              this.$i18n.t(`numsearch_result_single_graph_${result.factorsData.radar_group[i].name}`)
            );
            result.radarChart.data.push(result.factorsData.radar_group[i].level);
          }

          const socre = Math.round(result.factorsData.score);
          result.scoreMargin = {
            'margin-left': `calc(${socre}% - 15px)`,
          };
          result.scoreWidth = {
            width: `${socre}%`,
          };

          this.updateRemainLicenseQuota();
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'LICENSE_EXPIRED') {
              this.message.type = 'SearchLicenseExpire';
            }
          } else {
            alert(error);
          }
        });
    },
    searchIdentityReport: function () {
      const userLang = this.$store.state.user.lang;
      const searchNumber = this.version === 'offline' ? this.numberSha256 : this.number;

      apiSearch({
        numbers: [searchNumber],
        locale: userLang,
      })
        .then((response) => {
          const result = this.IdentityReportResult;
          result.factorsData = response.data[0];

          let groupData = {};
          for (let item in result.factorsData) {
            if (item === 'num') continue;
            const groupName = this.containsKey(this.identityReportFactorsCategory[this.scenario], item)
              ? this.identityReportFactorsCategory[this.scenario][item]
              : 'others';
            if (groupName in groupData) {
              groupData[groupName].push({ name: item, result: result.factorsData[item] });
            } else {
              groupData[groupName] = [{ name: item, result: result.factorsData[item] }];
            }
          }
          result.factorsData = groupData;
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'LICENSE_EXPIRED') {
              this.message.type = 'SearchLicenseExpire';
            } else {
              alert(error);
            }
          } else {
            alert(error);
          }
        });
    },
  },
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}
</style>
