<template>
  <div id="main-content" class="main-content" ref="printMe">
    <div class="main-item" id="main-item" v-if="bankAndRentalResult.factorsData && !hasKey('score')">
      <div class="summary__block">
        <div class="content__block">
          <div class="number">{{ number }}</div>
          <div class="number__detail mt-15">
            <div v-if="hasKey('number_origin')">
              ▪︎ {{ $t('numsearch_result_single_number_origin') }}：{{
                $t(`numsearch_result_single_number_origin_${bankAndRentalResult.factorsData.number_origin}`)
              }}
            </div>
            <div v-if="hasKey('is_verify')">
              ▪︎ {{ $t('numsearch_result_single_is_verify') }}：{{
                $t(`numsearch_result_single_is_verify_${bankAndRentalResult.factorsData.is_verify}`)
              }}
            </div>
            <div v-if="hasKey('spam_type')">
              ▪︎ {{ $t('numsearch_result_single_spam_type') }}：<span
                v-if="bankAndRentalResult.factorsData.spam_type"
                >{{ bankAndRentalResult.factorsData.spam_type | replaceSeparation($store.state.user.lang) }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
            <div v-if="hasKey('yp_type')">
              ▪︎ {{ $t('numsearch_result_single_yp_type') }}：<span
                v-if="bankAndRentalResult.factorsData.yp_type != ''"
                >{{ $t(bankAndRentalResult.factorsData.yp_type) }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
            <div v-if="hasKey('report_tag')">
              ▪︎ {{ $t('numsearch_result_single_report_tag') }}：<span
                v-if="bankAndRentalResult.factorsData.report_tag != ''"
                >{{ bankAndRentalResult.factorsData.report_tag }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
            <div v-if="hasKey('special_type')">
              ▪︎ {{ $t('numsearch_result_single_special_type') }}：<span
                v-if="bankAndRentalResult.factorsData.special_type"
                >{{ bankAndRentalResult.factorsData.special_type | replaceSeparation($store.state.user.lang) }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
            <div v-if="hasKey('special_tag')">
              ▪︎ {{ $t('numsearch_result_single_special_tag') }}：<span
                v-if="bankAndRentalResult.factorsData.special_tag != ''"
                >{{ bankAndRentalResult.factorsData.special_tag }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
          </div>
        </div>
        <div class="radar__block ml-80">
          <div class="download__btn">
            <a class="main-button" v-on:click="downloadResult">{{ $t('numsearch_result_single_button') }}</a>
          </div>
        </div>
      </div>
      <hr class="mt-30" />
      <div class="factor__detail__block mt-40" v-if="hasPartialFactors">
        <div class="factor__block--partial">
          <div class="factor__title"></div>
          <div class="factor__block__list factor__block__head mt-20">
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_partial_header_categ') }}</div>
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_item') }}</div>
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_risk') }}</div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.state_detect"
          >
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_graph_state_detect') }}</div>
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_special_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.cluster_analysis"
          >
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_graph_cluster_analysis') }}</div>
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.black_list"
          >
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_graph_black_list') }}</div>
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.activity_tracking"
          >
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_graph_activity_tracking') }}</div>
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.crime_pattern"
          >
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_graph_crime_pattern') }}</div>
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-item" id="main-item" v-if="bankAndRentalResult.factorsData && hasKey('score')">
      <div class="summary__block">
        <div class="content__block">
          <div class="number">{{ number }}</div>
          <div class="number__result mt-10">
            {{ $t('numsearch_result_single_summary') }}
            <span
              class="number__risk"
              v-bind:class="{
                'number__risk--high': bankAndRentalResult.factorsData.alert == 3,
                'number__risk--moderate': bankAndRentalResult.factorsData.alert == 2,
                'number__risk--low': bankAndRentalResult.factorsData.alert == 1,
              }"
              >{{ $t(`numsearch_result_summary_${bankAndRentalResult.factorsData.alert}`) }}</span
            >
          </div>
          <div class="number__detail mt-15">
            <div v-if="hasKey('number_origin')">
              ▪︎ {{ $t('numsearch_result_single_number_origin') }}：{{
                $t(`numsearch_result_single_number_origin_${bankAndRentalResult.factorsData.number_origin}`)
              }}
            </div>
            <div v-if="hasKey('is_verify')">
              ▪︎ {{ $t('numsearch_result_single_is_verify') }}：{{
                $t(`numsearch_result_single_is_verify_${bankAndRentalResult.factorsData.is_verify}`)
              }}
            </div>
            <div v-if="hasKey('spam_type')">
              ▪︎ {{ $t('numsearch_result_single_spam_type') }}：<span
                v-if="bankAndRentalResult.factorsData.spam_type"
                >{{ bankAndRentalResult.factorsData.spam_type | replaceSeparation($store.state.user.lang) }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
            <div v-if="hasKey('yp_type')">
              ▪︎ {{ $t('numsearch_result_single_yp_type') }}：<span
                v-if="bankAndRentalResult.factorsData.yp_type != ''"
                >{{ $t(bankAndRentalResult.factorsData.yp_type) }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
            <div v-if="hasKey('report_tag')">
              ▪︎ {{ $t('numsearch_result_single_report_tag') }}：<span
                v-if="bankAndRentalResult.factorsData.report_tag != ''"
                >{{ bankAndRentalResult.factorsData.report_tag }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
            <div v-if="hasKey('special_type')">
              ▪︎ {{ $t('numsearch_result_single_special_type') }}：<span
                v-if="bankAndRentalResult.factorsData.special_type"
                >{{ bankAndRentalResult.factorsData.special_type | replaceSeparation($store.state.user.lang) }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
            <div v-if="hasKey('special_tag')">
              ▪︎ {{ $t('numsearch_result_single_special_tag') }}：<span
                v-if="bankAndRentalResult.factorsData.special_tag != ''"
                >{{ bankAndRentalResult.factorsData.special_tag }}</span
              ><span v-else>{{ $t('numsearch_result_no_result') }}</span>
            </div>
          </div>
          <div
            class="number__score mt-10"
            v-bind:style="[bankAndRentalResult.scoreMargin, bankAndRentalResult.scoreImg]"
          >
            {{ $t('numsearch_result_single_risk_score') }} {{ bankAndRentalResult.factorsData.score | roundToInt }}
          </div>
          <div class="number__score__bar__block">
            <div
              class="number__score__bar"
              v-bind:style="bankAndRentalResult.scoreWidth"
              v-bind:class="{
                'number__score__bar--high': bankAndRentalResult.factorsData.alert == 3,
                'number__score__bar--moderate': bankAndRentalResult.factorsData.alert == 2,
                'number__score__bar--low': bankAndRentalResult.factorsData.alert == 1,
              }"
            ></div>
            <div class="number__score__bar--bg"></div>
          </div>
        </div>
        <div class="radar__block ml-80">
          <div class="download__btn">
            <a class="main-button" v-on:click="downloadResult">{{ $t('numsearch_result_single_button') }}</a>
          </div>
          <div class="Chart mt-40">
            <radar-chart
              :width="bankAndRentalResult.radarChart.width"
              :height="360"
              :labels="bankAndRentalResult.radarChart.labels"
              :data="bankAndRentalResult.radarChart.data"
              :bgColor="bankAndRentalResult.radarChart.bgColor"
              :bgColorRgba="bankAndRentalResult.radarChart.bgColorRgba"
            ></radar-chart>
          </div>
        </div>
      </div>
      <div class="factor__detail__block mt-40">
        <div class="factor__block">
          <div class="factor__title">{{ $t('numsearch_result_single_graph_state_detect') }}</div>
          <div class="factor__block__list factor__block__head mt-20">
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_item') }}</div>
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_risk') }}</div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.state_detect"
          >
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_special_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
        </div>
        <div class="factor__block ml-40">
          <div class="factor__title">{{ $t('numsearch_result_single_graph_cluster_analysis') }}</div>
          <div class="factor__block__list factor__block__head mt-20">
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_item') }}</div>
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_risk') }}</div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.cluster_analysis"
          >
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
        </div>
        <div class="factor__block mt-40">
          <div class="factor__title">{{ $t('numsearch_result_single_graph_black_list') }}</div>
          <div class="factor__block__list factor__block__head mt-20">
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_item') }}</div>
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_risk') }}</div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.black_list"
          >
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
        </div>
        <div class="factor__block mt-40 ml-40">
          <div class="factor__title">{{ $t('numsearch_result_single_graph_activity_tracking') }}</div>
          <div class="factor__block__list factor__block__head mt-20">
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_item') }}</div>
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_risk') }}</div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.activity_tracking"
          >
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
        </div>
        <div class="factor__block mt-40">
          <div class="factor__title">{{ $t('numsearch_result_single_graph_crime_pattern') }}</div>
          <div class="factor__block__list factor__block__head mt-20">
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_item') }}</div>
            <div class="factor__block__list-item">{{ $t('numsearch_result_single_table_header_risk') }}</div>
          </div>
          <div
            class="factor__block__list factor__block__content"
            v-for="(item, index) in bankAndRentalResult.factorsData.crime_pattern"
          >
            <div class="factor__block__list-item">{{ $t(`numsearch_result_single_${item.name}`) }}</div>
            <div class="factor__block__list-item">
              <span class="level__circle__low mr-10" v-if="item.level === 1"></span>
              <span class="level__circle__mid mr-10" v-if="item.level === 2"></span>
              <span class="level__circle__high mr-10" v-if="item.level === 3"></span>
              {{ $t(`numsearch_result_risk_${item.level}`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCharts from 'vue-chartjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
  name: 'BankAndRentalDetailResult',
  props: {
    number: String,
    bankAndRentalResult: Object,
  },
  filters: {
    roundToInt: function (value) {
      return Math.round(value);
    },
  },
  components: {
    'radar-chart': {
      extends: VueCharts.Radar,
      props: {
        labels: Array,
        data: Array,
        bgColor: String,
        bgColorRgba: String,
      },
      mounted() {
        const $ = this;

        var ctx = document.getElementById('radar-chart').getContext('2d');
        var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, $.bgColor);
        var gradientFill = ctx.createLinearGradient(500, 0, 100, 0);
        gradientFill.addColorStop(0, $.bgColorRgba);

        this.renderChart(
          {
            labels: $.labels,
            datasets: [
              {
                borderColor: gradientStroke,
                pointBorderColor: gradientStroke,
                pointBackgroundColor: gradientStroke,
                pointHoverBackgroundColor: gradientStroke,
                pointHoverBorderColor: gradientStroke,
                backgroundColor: gradientFill,
                data: $.data,
              },
            ],
          },
          {
            tooltips: {
              callbacks: {
                title: () => '',
                label: (tooltipItem, data) =>
                  data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']],
              },
            },
            legend: {
              display: false,
            },
            scale: {
              pointLabels: {
                fontSize: 14,
              },
              ticks: {
                display: false,
                beginAtZero: true,
                min: 0,
                max: 100,
                stepSize: 20,
              },
            },
            responsive: false,
            maintainAspectRatio: false,
          }
        );
      },
    },
  },
  computed: {
    hasKey() {
      return (key) => this.containsKey(this.bankAndRentalResult.factorsData, key);
    },
    hasPartialFactors() {
      return (
        this.bankAndRentalResult.factorsData.state_detect.length > 0 ||
        this.bankAndRentalResult.factorsData.cluster_analysis.length > 0 ||
        this.bankAndRentalResult.factorsData.black_list.length > 0 ||
        this.bankAndRentalResult.factorsData.activity_tracking.length > 0 ||
        this.bankAndRentalResult.factorsData.crime_pattern.length > 0
      );
    },
  },
  methods: {
    downloadResult: function () {
      html2canvas(this.$refs.printMe, {
        width: document.getElementById('main-item').offsetWidth + 160,
        height: document.getElementById('main-item').offsetHeight + 80,
      }).then((canvas) => {
        canvas.fillStyle = '#ffffff';

        var imgW = canvas.width < canvas.height ? canvas.width : canvas.width * 0.85;
        var imgH = canvas.width < canvas.height ? canvas.height : canvas.height * 0.85;
        var doc = new jsPDF('', 'pt', [canvas.width, canvas.height]);
        doc.addImage(canvas, 'png', 10, 10, imgW, imgH, '', 'FAST');
        doc.save(this.number + '.pdf');
      });
    },
  },
};
</script>

<style scoped>
.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  flex-direction: column;
  background-color: #ffffff;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px 80px;
}

.main-item {
  width: 100%;
}

.summary__block {
  display: flex;
}

.radar__block {
  position: relative;
  flex-basis: calc((100% - 80px) / 2);
}

#radar-chart {
  width: 480px;
  height: 360px;
}

.content__block {
  position: relative;
  flex-basis: calc((100% - 80px) / 2);
}

.download__btn {
  position: absolute;
  right: 0;
}

.number {
  font-size: 60px;
  font-weight: 600;
  color: #000000;
}

.number__result {
  font-size: 24px;
  color: #000000;
}

.number__detail {
  font-size: 14px;
  color: #000000;
}

.number__risk {
  font-weight: 600;
}

.number__risk--high {
  color: #870136;
}

.number__risk--moderate {
  color: #b49624;
}

.number__risk--low {
  color: #378619;
}

.number__score {
  width: 115px;
  height: 50px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
}

.number__score__bar__block {
  position: relative;
  display: block;
  height: 10px;
}

.number__score__bar {
  position: absolute;
  height: 10px;
}

.number__score__bar--bg {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #eaeaea;
  height: 10px;
  z-index: -1;
}

.number__score__bar--high {
  background-image: linear-gradient(to right, #d8d8d8, #870136);
}

.number__score__bar--moderate {
  background-image: linear-gradient(to right, #d8d8d8, #b49624);
}

.number__score__bar--low {
  background-image: linear-gradient(to right, #d8d8d8, #378619);
}

.factor__detail__block {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.factor__block--partial {
  max-width: calc(230px * 3);
  flex-basis: 100%;
}

.factor__block {
  flex-basis: calc((100% - 40px) / 2);
  background-color: #ffffff;
}

.factor__block__list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.factor__block__list-item {
  flex-basis: 50%;
  padding: 0 20px;
}

.factor__title {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.factor__block__head {
  height: 30px;
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.factor__block__content {
  min-height: 30px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

.level__circle__high {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #870136;
  border-radius: 50%;
}

.level__circle__mid {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #b49624;
  border-radius: 50%;
}

.level__circle__low {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #378619;
  border-radius: 50%;
}

#canvas-img {
  display: none;
}
</style>
