<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message
        :type="message.type"
        :message="message.text"
        :role="$store.state.user.role"
      ></full-page-message>
    </div>
    <app-header :title="$t('dataset_update_main_page_title')" v-if="!message.type"></app-header>
    <div class="main-block" v-if="!message.type">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div class="main-content">
        <div class="main-item">
          <div class="update__block">
            <div class="upload__block">
              <div class="upload__title">{{ $t('dataset_update_uploading_title') }}</div>
              <label
                for="file-upload"
                class="custom-dataset-upload long-button mt-30"
                :disabled="status.datasetRunning"
                >{{ $t('dataset_update_uploading_button') }}</label
              >
              <input id="file-upload" type="file" accept=".zip" v-on:change="uploadFile" v-on:click="resetUpload" />
            </div>
            <div class="status__block">
              <div class="status__title__block">
                <div class="status__title mt-40">{{ $t('dataset_update_status_title') }}</div>
                <div class="main-button status__refresh mt-40" v-on:click="getDataStatus">
                  {{ $t('dataset_update_status_refresh_button') }}
                </div>
              </div>
              <div class="status__block__list status__block__head mt-30">
                <div class="status__block__list-item">{{ $t('dataset_update_status_table_header_version') }}</div>
                <div class="status__block__list-item">{{ $t('dataset_update_status_table_header_uploader') }}</div>
                <div class="status__block__list-item">{{ $t('dataset_update_status_table_header_upload_time') }}</div>
                <div class="status__block__list-item">{{ $t('dataset_update_status_table_header_start_time') }}</div>
                <div class="status__block__list-item">{{ $t('dataset_update_status_table_header_end_time') }}</div>
                <div class="status__block__list-item">
                  {{ $t('dataset_update_status_table_header_current_status') }}
                </div>
                <div class="status__block__list-item">{{ $t('dataset_update_status_table_header_err_msg') }}</div>
              </div>
              <div
                class="status__block__list status__block__content"
                v-for="(item, index) in statusData"
                v-bind:key="index"
              >
                <div class="status__block__list-item">{{ item.data_ver }}</div>
                <div class="status__block__list-item">{{ item.uploader }}</div>
                <div class="status__block__list-item">{{ item.upload_time | dtFormat }}</div>
                <div class="status__block__list-item">{{ item.start_time | dtFormat }}</div>
                <div class="status__block__list-item">{{ item.end_time | dtFormat }}</div>
                <div class="status__block__list-item">{{ $t(`dataset_update_status_${item.status}`) }}</div>
                <div class="status__block__list-item">{{ item.err_msg }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type === 'datasetIsRunning'">
        <div class="dialog__title">{{ $t('dataset_update_client_error_dialog_job_is_running_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('dataset_update_client_error_dialog_job_is_running_desc') }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button" v-on:click="dismissDialog">{{
            $t('dataset_update_client_error_dialog_job_is_running_button')
          }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type === 'datasetIsPending'">
        <div class="dialog__title">{{ $t('dataset_update_client_error_dialog_job_is_pending_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('dataset_update_client_error_dialog_job_is_pending_desc') }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button" v-on:click="dismissDialog">{{
            $t('dataset_update_client_error_dialog_job_is_pending_button_no')
          }}</a>
          <a class="main-button dialog__button" v-on:click="forceUpload">{{
            $t('dataset_update_client_error_dialog_job_is_pending_button_yes')
          }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type === 'FileUploadFail'">
        <div class="dialog__title">{{ $t('dataset_update_upload_api_err_file_upload_fail_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('dataset_update_upload_api_err_file_upload_fail_desc') }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button" v-on:click="dismissDialog">{{
            $t('dataset_update_upload_api_err_file_upload_fail_button')
          }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type === 'InvalidFileFormat'">
        <div class="dialog__title">{{ $t('dataset_update_client_error_dialog_filename_invalid_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('dataset_update_client_error_dialog_filename_invalid_desc') }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button" v-on:click="dismissDialog">{{
            $t('dataset_update_client_error_dialog_filename_invalid_button')
          }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type === 'InvalidFileContent'">
        <div class="dialog__title">{{ $t('dataset_update_upload_api_err_invalid_file_content_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('dataset_update_upload_api_err_invalid_file_content_desc') }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button" v-on:click="dismissDialog">{{
            $t('dataset_update_upload_api_err_invalid_file_content_button')
          }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type === 'InvalidDataVersion'">
        <div class="dialog__title">{{ $t('dataset_update_upload_api_err_version_invalid_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('dataset_update_upload_api_err_version_invalid_desc') }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button" v-on:click="dismissDialog">{{
            $t('dataset_update_upload_api_err_version_invalid_button')
          }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type === 'UncompressFileError'">
        <div class="dialog__title">{{ $t('dataset_update_upload_api_err_uncompress_file_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('dataset_update_upload_api_err_uncompress_file_desc') }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button" v-on:click="dismissDialog">{{
            $t('dataset_update_upload_api_err_uncompress_file_button')
          }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type === 'APIError'">
        <div class="dialog__title">APIError</div>
        <div class="dialog__desc mt-20">{{ dialog.text }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button" v-on:click="dismissDialog">我知道了</a>
        </div>
      </div>
    </div>
    <div class="full-loading" v-if="status.uploading">
      <full-page-loading :message="$t('dataset_update_uploading_hint')"></full-page-loading>
    </div>
    <div class="full-loading" v-if="status.jobRunning">
      <full-page-loading :message="$t('dataset_update_job_running_hint')"></full-page-loading>
    </div>
    <div id="canvas-img"></div>
  </div>
</template>

<script>
import moment from 'moment-timezone';

import { apiDataStatus, apiDataUpload } from '../api.js';
import FullPageMessage from './partials/FullPageMessage.vue';
import FullPageLoading from './partials/FullPageLoading.vue';
import Header from './base/header.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'DatasetUpdate',
  props: {
    msg: String,
  },
  data() {
    return {
      dataset: this.$store.state.license.dataset,
      statusData: [],
      datasetFile: new FormData(),
      status: {
        datasetRunning: false,
        datasetPending: false,
        uploading: false,
        jobRunning: false,
      },
      dialog: {
        type: null,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
    };
  },
  components: {
    'app-header': Header,
    'app-sidebar': Sidebar,
    'full-page-loading': FullPageLoading,
    'full-page-message': FullPageMessage,
  },
  created() {
    const $ = this;
    $.getDataStatus();
  },
  filters: {
    roundToInt: function (value) {
      return Math.round(value);
    },
    dtFormat: function (datetime) {
      if (!datetime) return '';
      return moment.tz(datetime, 'UTC').tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
    },
  },
  methods: {
    resetUpload: function (e) {
      e.target.value = null;
    },
    uploadFile: function () {
      const fileLoader = document.getElementById('file-upload');
      const $ = this;

      if (fileLoader.files.length > 0 && !$.checkDataSetFileNameFormat(fileLoader.files[0].name)) {
        $.getDataStatus();
        $.status.uploading = true;

        setTimeout(function () {
          if ($.status.datasetRunning) {
            $.dialog.type = 'datasetIsRunning';
            $.status.uploading = false;
          } else if ($.status.datasetPending) {
            $.dialog.type = 'datasetIsPending';
            $.status.uploading = false;
          } else {
            $.dataUpload();
          }
        }, 1000);
      } else {
        $.dialog.type = 'InvalidFileFormat';
      }
    },
    dataUpload: function () {
      const fileLoader = document.getElementById('file-upload');
      const $ = this;

      $.datasetFile.append('files', fileLoader.files[0]);

      apiDataUpload($.datasetFile)
        .then(function () {})
        .catch(function (error) {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'FILE_UPLOAD_FAIL') {
              $.dialog.type = 'FileUploadFail';
            } else if (error.response.data.msg === 'INVALID_FILE_FORMAT') {
              $.dialog.type = 'InvalidFileFormat';
            } else if (error.response.data.msg === 'INVALID_FILE_CONTENT') {
              $.dialog.type = 'InvalidFileContent';
            } else if (error.response.data.msg === 'FILE_CANNOT_OPEN') {
              $.dialog.type = 'FileCanNotOpen';
            } else if (error.response.data.msg === 'INVALID_DATA_VERSION') {
              $.dialog.type = 'InvalidDataVersion';
            } else if (error.response.data.msg === 'UNCOMPRESS_FILE_ERROR') {
              $.dialog.type = 'UncompressFileError';
            }
          } else {
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
            $.linkInvalid = true;
            $.link_error = error.response.data.msg;
          }
        })
        .finally(function () {
          $.getDataStatus();
          $.status.uploading = false;
          $.datasetFile = new FormData();
        });
    },
    forceUpload: function () {
      const $ = this;
      $.dismissDialog();
      $.dataUpload();
    },
    getDataStatus: function () {
      const $ = this;

      apiDataStatus()
        .then(function (response) {
          $.statusData = response.data[$.dataset];
          $.status.datasetRunning = false;
          $.status.datasetPending = false;
          $.status.jobRunning = false;
          $.updateSystemVersion();
          for (var i = 0; i < $.statusData.length; i++) {
            if ($.statusData[i].status === 'running' || $.statusData[i].status === 'checking') {
              $.status.datasetRunning = true;
              $.status.jobRunning = true;
            }
            if ($.statusData[i].status === 'pending') {
              $.status.datasetPending = true;
            }
          }
        })
        .catch(function (error) {
          Object.getOwnPropertyDescriptor(error, 'message').value;
        });
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #eaeaea;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.main-item {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.update__block {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 40px;
}

.upload__block {
  display: flex;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 40px;
  flex-direction: column;
}

.status__title__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload__title,
.status__title {
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

input[type='file'] {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.custom-dataset-upload {
  display: inline-block;
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
}

.status__block__list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status__block__list-item {
  flex: 1;
  padding: 0 20px;
  word-break: break-all;
}

.status__block__head {
  min-height: 30px;
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.status__block__content {
  display: flex;
  min-height: 60px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}
</style>
