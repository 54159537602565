<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message :type="message.type" :message="message.text"></full-page-message>
    </div>
    <div v-if="!message.type" class="main-block">
      <app-sidebar></app-sidebar>
      <div class="main-content">
        <div class="lang-item">
          <lang-selector></lang-selector>
        </div>
        <div class="main-item">
          <img class="witcher__logo" :src="`${$baseUrl}witcher_logo.png`" />
        </div>
        <div class="main-item mt-40">
          <div class="page__title">{{ $t('login_button_license_token_update_entry') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('login_license_token_update_input_dialog_brandid') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.brandIDError }"
            v-model.trim="form.brandID"
            v-on:blur="status.brandIDError = checkBrandIDFormat(form.brandID)"
            v-on:focus="
              status.brandIDError = null;
              message.apiError = null;
            "
            v-on:keyup.enter="postLicenseTokenUpdate"
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.brandIDError" class="form__error">{{ $t('license_active_client_companyid_format') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('login_license_token_update_input_dialog_token') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-model="form.token"
            v-on:focus="message.apiError = null"
            v-on:keyup.enter="postLicenseTokenUpdate"
          />
        </div>
        <div class="main-item mt-40">
          <router-link to="/user/login">
            <a class="form__link">回登入頁</a>
          </router-link>
        </div>
        <div class="main-item mt-60">
          <a class="long-button" :disabled="!isComplete" v-on:click="postLicenseTokenUpdate">{{
            $t('login_license_token_update_input_dialog_action')
          }}</a>
        </div>
        <div class="main-item mt-20" v-if="message.apiError">
          <div class="form__api__error">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'LicenseNotActivated'">
        <div class="dialog__title">{{ $t('login_license_token_update_error_license_not_activated_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('login_license_token_update_error_license_not_activated_desc') }}</div>
        <a class="long-button mt-60" v-on:click="dismissDialog">{{
          $t('login_license_token_update_error_license_not_activated_desc_bottom')
        }}</a>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'MismatchBrandID'">
        <div class="dialog__title">{{ $t('login_license_token_update_error_mismatch_brand_id_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('login_license_token_update_error_mismatch_brand_id_desc') }}</div>
        <a class="long-button mt-60" v-on:click="dismissDialog">{{
          $t('login_license_token_update_error_mismatch_brand_id_button')
        }}</a>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'LicenseUpdateTokenAlreadyUsed'">
        <div class="dialog__title" v-html="$t('login_license_token_update_error_token_used_title')"></div>
        <div class="dialog__desc mt-20" v-html="$t('login_license_token_update_error_token_used_desc')"></div>
        <a class="long-button mt-60" v-on:click="dismissDialog">{{
          $t('login_license_token_update_error_token_used_button')
        }}</a>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'LicenseUpdateTokenInvalid'">
        <div class="dialog__title" v-html="$t('login_license_token_update_error_invalid_token_title')"></div>
        <div class="dialog__desc mt-20" v-html="$t('login_license_token_update_error_invalid_token_desc')"></div>
        <a class="long-button mt-60" v-on:click="dismissDialog">{{
          $t('login_license_token_update_error_invalid_token_button')
        }}</a>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'LicenseUpdateSuccess'">
        <div class="dialog__title" v-html="$t('login_license_token_update_succeed_title')"></div>
        <div
          v-if="licenseData.unlimitedQuota"
          class="dialog__desc mt-20"
          v-html="
            $t('login_license_token_update_succeed_desc_unlimited_version', {
              remain_quota: licenseData.remainQuota,
              license_etime_TW: licenseData.licenseEtime,
              acc_limit: licenseData.account,
            })
          "
        ></div>
        <div
          v-else
          class="dialog__desc mt-20"
          v-html="
            $t('login_license_token_update_succeed_desc_limited_version', {
              remain_quota: licenseData.remainQuota,
              license_etime_TW: licenseData.licenseEtime,
              acc_limit: licenseData.account,
            })
          "
        ></div>
        <div class="dialog__desc mt-20" v-html="$t('login_license_token_update_succeed_desc_2')"></div>
        <a class="long-button mt-60" v-on:click="dismissDialog">{{
          $t('login_license_token_update_succeed_button')
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { apiLicenseTokenUpdate } from '../api.js';
import FullPageMessage from './partials/FullPageMessage.vue';
import InlineLoading from './partials/InlineLoading.vue';
import Sidebar from './base/sidebar.vue';
import LangSelector from './partials/LangSelector.vue';

export default {
  name: 'LicenseUpdate',
  data() {
    return {
      status: {
        brandIDError: false,
        isLoading: false,
      },
      form: {
        brandID: null,
        token: null,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
      dialog: {
        type: null,
      },
      licenseData: {
        licenseEtime: null,
        account: null,
        remainQuota: null,
        unlimitedQuota: null,
      },
    };
  },
  components: {
    'app-sidebar': Sidebar,
    'full-page-message': FullPageMessage,
    'inline-loading': InlineLoading,
    'lang-selector': LangSelector,
  },
  created() {},
  computed: {
    isComplete() {
      return !this.status.brandIDError && !this.status.isLoading && this.form.brandID && this.form.token;
    },
  },
  methods: {
    postLicenseTokenUpdate: function () {
      const $ = this;
      $.message.apiError = null;

      if (!$.isComplete) {
        return false;
      }

      $.status.isLoading = true;
      apiLicenseTokenUpdate({
        brand_id: $.form.brandID,
        token: $.form.token,
      })
        .then(function (response) {
          $.licenseData.licenseEtime = response.data.etime;
          $.licenseData.account = response.data.lic_acc;
          $.licenseData.remainQuota = response.data.remain;
          $.licenseData.unlimitedQuota = response.data.unlimited_quota;
          $.dialog.type = 'LicenseUpdateSuccess';
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'LICENSE_NOT_ACTIVATED') {
              $.dialog.type = 'LicenseNotActivated';
            } else if (error.response.data.msg === 'MISMATCH_BRAND_ID') {
              $.dialog.type = 'MismatchBrandID';
            } else if (error.response.data.msg === 'LICENSE_UPDATE_TOKEN_ALREADY_USED') {
              $.dialog.type = 'LicenseUpdateTokenAlreadyUsed';
            } else if (error.response.data.msg === 'LICENSE_UPDATE_TOKEN_INVALID') {
              $.dialog.type = 'LicenseUpdateTokenInvalid';
            } else {
              $.message.apiError = $.$i18n.t(`api_error.${error.response.data.msg}`);
            }
          } else {
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        })
        .finally(function () {
          $.status.isLoading = false;
        });
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
  },
};
</script>

<style scoped>
.main-block {
  display: flex;
  height: 100vh;
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #ffffff;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
  align-items: center;
}

.main-item {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.lang-item {
  width: 160px;
  position: absolute;
  top: 40px;
  right: 60px;
}
</style>
