<template>
  <div class="lang-selector__block">
    <span class="lang-selector-container">
      <div class="lang-selector-label" v-if="userLang == 'zh_TW'">中文</div>
      <div class="lang-selector-label" v-if="userLang == 'en_US'">English</div>
      <div class="lang-selector-label" v-if="userLang == 'ja_JP'">日本語</div>
      <select class="lang-selector" v-model="userLang" @change="changeLang(userLang)">
        <option v-for="option in options" v-if="appLangs.includes(option.value)" v-bind:value="option.value">
          {{ option.text }}
        </option>
      </select>
    </span>
  </div>
</template>

<script>
import store from '../../store';
import i18n from '../../lang/lang';

export default {
  name: 'LangSelector',
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      userLang: store.state.user.lang,
      appLangs: process.env.VUE_APP_LANGS.split(', '),
      options: [
        { text: '中文', value: 'zh_TW' },
        { text: 'English', value: 'en_US' },
        { text: '日本語', value: 'ja_JP' },
      ],
    };
  },
  methods: {
    changeLang: function (lang) {
      store.commit('setUserLang', lang);
      i18n.locale = lang;
    },
  },
};
</script>

<style scoped>
.lang-selector-container {
  width: 160px;
  position: relative;
  overflow: hidden;
  display: block;
  height: 40px;
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
}

.lang-selector-container::after {
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 16px;
  height: 16px;
  font-size: 60%;
  line-height: 40px;
  font-family: 'witcherfin';
  content: '\e905';
  pointer-events: none;
}

.lang-selector-label {
  padding-right: 25px;
  font-size: 14px;
  color: #000000;
  line-height: 40px;
  padding: 0 20px;
}

.lang-selector {
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  -webkit-appearance: none;
}
</style>
