<template>
  <div class="site-main">
    <app-header :title="$t('side_bar_menu_api')"></app-header>
    <div class="main-block">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div class="main-content" v-if="$store.state.license.api_service === true">
        <div class="main-item mt-6">
          <div class="token__block">
            <div class="brand__info">
              <div class="brand__title">{{ $t('tryapi_api_credentials_uuid') }}</div>
              <div class="brand__text mt-5" v-if="$store.state.enableMaskUUID">{{ $store.state.user.maskedUUID }}</div>
              <div class="brand__text mt-5" v-else>{{ $store.state.user.uuid }}</div>
              <div class="brand__title mt-20">{{ $t('tryapi_api_credentials_companyid') }}</div>
              <div class="brand__text mt-5">{{ $store.state.user.brand_id }}</div>
            </div>
            <div class="token__info">
              <a class="small-button" :disabled="$store.state.user.role !== 'admin'" v-on:click="getApiToken">{{
                $t('tryapi_api_credentials_button')
                }}</a>
            </div>
          </div>
        </div>
        <div class="api-select mt-30">
          API Documents
          <div v-if="!licenseData.unlimited_quota" class="custom-selector mt-10">
            <span class="api-selector-container">
              <div class="api-selector-label">{{ form.api }}</div>
              <select class="api-selector" v-model="form.api">
                <option v-for="(api, index) in form.apis" v-bind:value="api" v-bind:key="index">{{ api }}</option>
              </select>
            </span>
          </div>
        </div>
        <div v-if="form.api == 'Verify API'" class="api-item mt-20">
          <div class="api__block">
            <div class="api__summary">Welcome to the Verify API</div>
            <div class="api__detail mt-40">
              <span id="verify_api_document">1</span>
              <span id="api_reference">2</span>
              <span id="data_crypto">3</span>
              <div id="tab">
                <!-- 頁籤按鈕 -->
                <ul>
                  <li><a href="#verify_api_document">Verify API Document</a></li>
                  <li><a href="#api_reference">API Reference</a></li>
                  <li><a href="#data_crypto">Data Crypto</a></li>
                </ul>
                <!-- 頁籤的內容區塊 -->
                <div class="tab-content-1">
                  <div class="api__title mt-40">About the API</div>
                  <div class="api__content mt-20">
                    This API allows you to programmatically query WitcherFin services.<br />Each API call can handle a
                    maximum of 1,000 numbers.
                  </div>

                  <div class="inline-hl highlight mt-5">
                    - <span class="api__text--green">Single</span> number query:
                    <span class="api__text--purple">30</span> requests per second<br />
                    - <span class="api__text--green">Multiple</span> number query:
                    <span class="api__text--purple">2</span> requests per second (Maxima
                    <span class="api__text--purple">1,000</span> numbers per request)
                  </div>

                  <div class="api__content mt-5">
                    Each number will consume 1 remaining query.<br /><br />Within the response body you will find all
                    <span v-if="$store.state.license.dataset === 'bank'">32</span><span v-else>31</span> factors as well
                    as a summary alert rating.
                  </div>

                  <div class="api__content mt-20">All URLs start with the root URL:</div>
                  <div class="inline-hl highlight mt-20">{{ apiUrl }}/search/batch/v3</div>

                  <div class="api__content mt-20">
                    API will response English values as a default.<br />We provide Chinese and Japanese translations for
                    the responsing values of <code>spam_type</code> and <code>special_type</code>, if yout want to get
                    values translated to local language, please add <code>?locale=zh_TW</code> or
                    <code>?locale=ja_JP</code> by the end of the requesting URL.
                  </div>

                  <div class="api__content mt-20">Examples:</div>
                  <div class="inline-hl highlight mt-20">
                    To get Chinese values:<br />{{ apiUrl }}/search/batch/v3/?locale=zh_TW<br /><br />To get Japanese
                    values:<br />{{ apiUrl }}/search/batch/v3/?locale=ja_JP
                  </div>

                  <div class="api__content mt-20">
                    Please note that all requests to the Verify API must be made over HTTPS.<br />The full details of
                    each endpoint offered by our API can be found in the <a href="#api_reference">API Reference</a>.
                  </div>

                  <div class="api__title mt-40">Data Formats</div>
                  <div class="api__content mt-20">The service currently supports JSON.</div>
                  <div class="api__content">
                    The valid media types are: <span class="api__content__strong">application/json</span>
                  </div>
                  <div class="api__content mt-20">Similarly, we accept data in JSON formats.</div>
                  <div class="api__content">
                    When sending data to the API, set the
                    <span class="highlight p-rl-5"><code>Content-Type</code></span> header to
                    <span class="highlight p-rl-5"><code>application/json</code></span> with a JSON body.
                  </div>
                  <div class="api__content">
                    You need the <span class="highlight p-rl-5"><code>app_id</code></span>, <span
                      class="highlight p-rl-5"><code>company_id</code></span>, <span
                      class="highlight p-rl-5"><code>uid</code></span>, <span
                      class="highlight p-rl-5"><code>api_key</code></span> to access the Verify API.
                  </div>
                  <div class="api__code__title api__code__hl mt-20">Code sample (curl)</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
curl -X POST \
  {{ apiUrl }}/search/batch/v3 \
  -H 'Content-Type: application/json' \
  -H 'User-Agent: {app_id}|{company_id}|{uid}' \
  -H 'appkey: {api_key}'
  -d '["AES_num_1", "AES_num_2"]'

  #if app_id = abc123, company_id = gogo, uid = cde456 --> 'User-Agent: abc123|gogo|cde456'
  -H 'appkey: {api_key}'
  #Please replace "AES_num_1", "AES_num_2" , etc with the AES encrypted numbers.
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__code__title api__code__hl mt-20">Code sample (C# Compiler .NET 4.5 up)</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
using System;
using System.Net;
using System.IO;

namespace RequestSample
{
    public class MainClass
    {
        public static void Main()
        {
            try{
                string webAddr="{{ apiUrl }}/search/batch/v3";
                var httpWebRequest = (HttpWebRequest)WebRequest.Create(webAddr);

                httpWebRequest.ContentType = "application/json; charset=utf-8";
                httpWebRequest.Method = "POST";
                httpWebRequest.UserAgent = "{app_id}|{company_id}|{uid}";
                httpWebRequest.Headers["appkey"] = "{api_key}";

                using (var streamWriter = new StreamWriter(httpWebRequest.GetRequestStream()))
                {
                    string json = "[\"AES_num_1\", \"AES_num_2\"]";

                    //Please replace "AES_num_1", "AES_num_2" , etc with the AES encrypted numbers.


                    streamWriter.Write(json);
                    streamWriter.Flush();
                }
                var httpResponse = (HttpWebResponse)httpWebRequest.GetResponse();
                using (var streamReader = new StreamReader(httpResponse.GetResponseStream()))
                {
                    var responseText = streamReader.ReadToEnd();
                    Console.WriteLine(responseText);

                    //Now you have your response.
                    //or false depending on information in the response
                }
            }catch(WebException ex){
                Console.WriteLine(ex.Message);
            }
        }
    }
}
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__title mt-40">Error</div>
                  <div class="api__content mt-20">
                    Other errors you may see will start with 5xx, e.g. 500 Internal Server Error.<br />
                    If this happens, try your request again later. If the error persists please contact us at the email
                    below.<br />
                    Check <a href="#api_reference">API Reference</a> to see all status codes.
                  </div>
                  <div class="api__title mt-40">About The Factors</div>
                  <div class="api__content mt-20">
                    If you have questions about the definition of the factors provided by Verify API, please see the
                    document sent to your admin’s email address.
                  </div>
                  <div class="api__title mt-40">API Improvements</div>
                  <div class="api__content mt-20">
                    For any issues or feature requests please contact us at
                    <a href="mailto:witcherfin.service@gogolook.com.tw">witcherfin.service@gogolook.com.tw</a>.
                  </div>
                </div>
                <div class="tab-content-2">
                  <div class="api__title mt-40">Implementation Notes</div>
                  <div class="api__content mt-20">Search data for all the items in your request body.</div>
                  <div class="api__title mt-40">Request Body</div>
                  <div class="api__content mt-20">
                    <span class="api__method">POST</span><span class="api__path ml-10">/search/batch/v3</span>
                  </div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
URL Parameters:
    type

Header:
   - appkey: {api_key}
   - User-Agent: {app_id}|{company_id}|{uid}
   - content-type: application/json

We provide Chinese and Japanese translations for the
responsing values of yp_type, spam_type and special_type.
(Default as English)

  - To get Chinese values:
  {{ apiUrl }}/search/batch/v3/?locale=zh_TW

  - To get Japanese values:
  {{ apiUrl }}/search/batch/v3/?locale=ja_JP
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__code__title mt-20">Model</div>
                  <div class="api__content mt-20">
                    Please see <a href="#data_crypto">AES Data Crypto</a> for encrypting function.
                  </div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
List of items [
    AES_num1 (string): The encrypted number you want to search for WitcherFin's data,
    AES_num2 (string): The encrypted number you want to search for WitcherFin's data,
    ...
]
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__code__title">Example Value</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
[
  "AESXXXXXXXXXX",
  "AESYYYYYYYYYY"
]
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__title mt-40">Response Body</div>
                  <div class="api__content">Returns the list of the factors with each item.</div>
                  <div class="api__code__title mt-20">Model</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code v-if="$store.state.license.dataset === 'bank'">
List [
    OrderedMap {
        num (string),
        alert (integer),
        score (integer),
        spam_num (integer),
        special_num (integer),
        is_invalid_number (integer),
        in_fraud_db (integer),
        bad_history (integer),
        using_frequency (integer),
        reachable (integer),
        last_activity_range (integer),
        has_social_activity (integer),
        has_traffic_pattern (integer),
        has_abnormal_pattern (integer),
        has_multiple_region (integer),
        special_cluster (integer), <span class="code--caption"># removed</span>
        suspicious_cluster (integer), <span class="code--caption"># removed</span>
        whitelist_cluster (integer),
        phony_account_cluster (integer),
        special_loan_cluster (integer), <span class="code--caption"># new</span>
        agency_cluster (integer), <span class="code--caption"># new</span>
        pawnshop_cluster (integer), <span class="code--caption"># new</span>
        debt_collect_cluster (integer), <span class="code--caption"># new</span>
        spec_career_cluster (integer), <span class="code--caption"># new</span>
        installment_cluster (integer), <span class="code--caption"># new</span>
        number_origin (boolean),
        is_verify (boolean),
        yp_type (string), <span class="code--caption"># Provide translated values</span>
        spam_type (string), <span class="code--caption"># Provide translated values</span>
        special_type (string), <span class="code--caption"># Provide translated values</span>
           <span class="code--caption"># 2 additional new values of special_type: (1)Pawn Shops (2)Loan & Installments</span>
        report_tag (string), <span class="code--caption"># With the original tagging language</span>
        special_tag (string) <span class="code--caption"># With the original tagging language</span>
 },
     OrderedMap {
        num (string),
        alert (integer)
        score (integer),
        ...
}
                        </code>
                        <code v-if="$store.state.license.dataset === 'rental'">
List [
    OrderedMap {
        num (string),
        alert (integer),
        score (integer),
        spam_num (integer),
        special_num (integer),
        is_invalid_number (integer),
        in_fraud_db (integer),
        bad_history (integer),
        using_frequency (integer),
        reachable (integer),
        last_activity_range (integer),
        has_social_activity (integer),
        has_traffic_pattern (integer),
        has_abnormal_pattern (integer),
        has_multiple_region (integer),
        special_cluster (integer), <span class="code--caption"># removed</span>
        suspicious_cluster (integer), <span class="code--caption"># removed</span>
        whitelist_cluster (integer),
        phony_account_cluster (integer),
        special_loan_cluster (integer), <span class="code--caption"># new</span>
        agency_cluster (integer), <span class="code--caption"># new</span>
        pawnshop_cluster (integer), <span class="code--caption"># new</span>
        debt_collect_cluster (integer), <span class="code--caption"># new</span>
        spec_career_cluster (integer), <span class="code--caption"># new</span>
        number_origin (boolean),
        is_verify (boolean),
        yp_type (string), <span class="code--caption"># Provide translated values</span>
        spam_type (string), <span class="code--caption"># Provide translated values</span>
        special_type (string), <span class="code--caption"># Provide translated values</span>
           <span class="code--caption"># 1 additional new values of special_type: (1)Pawn Shops</span>
        report_tag (string), <span class="code--caption"># With the original tagging language</span>
        special_tag (string) <span class="code--caption"># With the original tagging language</span>
 },
     OrderedMap {
        num (string),
        alert (integer)
        score (integer),
        ...
}
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__content">
                    # To check the definition of the value, please see the document sent to your admin’s email address.
                  </div>
                  <div class="api__code__title">Example Value</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code v-if="$store.state.license.dataset === 'bank'">
{
     <span class="code--red">"num": "AESXXXXXXXXXX"</span>,
     <span class="code--red">"alert"</span> 2,
     <span class="code--red">"score"</span>: 50.2635,
     <span class="code--red">"spam_num"</span>: 1,
     <span class="code--red">"special_num"</span>: 1,
     <span class="code--red">"is_invalid_number"</span>: 1,
     <span class="code--red">"in_fraud_db"</span>: 1,
     <span class="code--red">"bad_history"</span>: 1,
     <span class="code--red">"using_frequency"</span>: 1,
     <span class="code--red">"reachable"</span>: 1,
     <span class="code--red">"last_activity_range"</span>: 1,
     <span class="code--red">"has_social_activity"</span>: 1,
     <span class="code--red">"has_traffic_pattern"</span>: 1,
     <span class="code--red">"has_abnormal_pattern"</span>: 1,
     <span class="code--red">"has_multiple_region"</span>: 1,
     <span class="code--red">"whitelist_cluster"</span>: 3,
     <span class="code--red">"phony_account_cluster"</span>: 1,
     <span class="code--red">"special_loan_cluster"</span>: 3, <span class="code--caption"># new</span>
     <span class="code--red">"agency_cluster"</span>: 2, <span class="code--caption"># new</span>
     <span class="code--red">"pawnshop_cluster"</span>: 1, <span class="code--caption"># new</span>
     <span class="code--red">"debt_collect_cluster"</span>: 1, <span class="code--caption"># new</span>
     <span class="code--red">"spec_career_cluster"</span>: 1, <span class="code--caption"># new</span>
     <span class="code--red">"installment_cluster"</span>: 2, <span class="code--caption"># new</span>
     <span class="code--red">"number_origin"</span>: <span class="code--blue">true</span>,
     <span class="code--red">"is_verify"</span>: <span class="code--blue">true</span>,
     <span class="code--red">"yp_type": "",</span>
     <span class="code--red">"spam_type": "Harassment/Ads",</span>
     <span class="code--red">"special_type": "Pawn Shops/Loan & Installments",</span> <span class="code--caption"># add 2 new values</span>
     <span class="code--red">"report_tag": "",</span>
     <span class="code--red">"special_tag": ""</span>
},
{
     <span class="code--red">"num": "AESYYYYYYYYYY"</span>,
     <span class="code--red">"alert"</span> 3,
     <span class="code--red">"score"</span> 90, ...
},
...
                        </code>
                        <code v-if="$store.state.license.dataset === 'rental'">
{
     <span class="code--red">"num": "AESXXXXXXXXXX"</span>,
     <span class="code--red">"alert"</span> 2,
     <span class="code--red">"score"</span>: 50.2635,
     <span class="code--red">"spam_num"</span>: 1,
     <span class="code--red">"special_num"</span>: 1,
     <span class="code--red">"is_invalid_number"</span>: 1,
     <span class="code--red">"in_fraud_db"</span>: 1,
     <span class="code--red">"bad_history"</span>: 1,
     <span class="code--red">"using_frequency"</span>: 1,
     <span class="code--red">"reachable"</span>: 1,
     <span class="code--red">"last_activity_range"</span>: 1,
     <span class="code--red">"has_social_activity"</span>: 1,
     <span class="code--red">"has_traffic_pattern"</span>: 1,
     <span class="code--red">"has_abnormal_pattern"</span>: 1,
     <span class="code--red">"has_multiple_region"</span>: 1,
     <span class="code--red">"whitelist_cluster"</span>: 3,
     <span class="code--red">"phony_account_cluster"</span>: 1,
     <span class="code--red">"special_loan_cluster"</span>: 3, <span class="code--caption"># new</span>
     <span class="code--red">"agency_cluster"</span>: 2, <span class="code--caption"># new</span>
     <span class="code--red">"pawnshop_cluster"</span>: 1, <span class="code--caption"># new</span>
     <span class="code--red">"debt_collect_cluster"</span>: 1, <span class="code--caption"># new</span>
     <span class="code--red">"spec_career_cluster"</span>: 1, <span class="code--caption"># new</span>
     <span class="code--red">"installment_cluster"</span>: 2, <span class="code--caption"># new</span>
     <span class="code--red">"number_origin"</span>: <span class="code--blue">true</span>,
     <span class="code--red">"is_verify"</span>: <span class="code--blue">true</span>,
     <span class="code--red">"yp_type": "",</span>
     <span class="code--red">"spam_type": "Harassment/Ads",</span>
     <span class="code--red">"special_type": "Pawn Shops",</span> <span class="code--caption"># add 1 new values</span>
     <span class="code--red">"report_tag": "",</span>
     <span class="code--red">"special_tag": ""</span>
},
{
     <span class="code--red">"num": "AESYYYYYYYYYY"</span>,
     <span class="code--red">"alert"</span> 3,
     <span class="code--red">"score"</span> 90, ...
},
...
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__content">
                    # To check the definition of the value, please see the document sent to your admin’s email address.
                  </div>
                  <div class="api__title mt-40">Response Messages</div>
                  <div class="api__table">
                    <div class="api__table__list api__table__head">
                      <div class="api__table__list-item">HTTP Status Code</div>
                      <div class="api__table__list-item">Error code</div>
                      <div class="api__table__list-item">Reason</div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">401</div>
                      <div class="api__table__list-item">-</div>
                      <div class="api__table__list-item">
                        Access denied due to invalid subscription key. Make sure to provide a valid key.
                      </div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">403</div>
                      <div class="api__table__list-item">-</div>
                      <div class="api__table__list-item">Forbidden.</div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">422</div>
                      <div class="api__table__list-item">-</div>
                      <div class="api__table__list-item">
                        Format error. Please check whether the numbers are crypto with correct AES function.
                      </div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">429</div>
                      <div class="api__table__list-item">-</div>
                      <div class="api__table__list-item">Too many requests in a given amount of time.</div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">400</div>
                      <div class="api__table__list-item">14</div>
                      <div class="api__table__list-item">License expired.</div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">400</div>
                      <div class="api__table__list-item">18</div>
                      <div class="api__table__list-item">There’s not enough remaining quota in your license.</div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">400</div>
                      <div class="api__table__list-item">32</div>
                      <div class="api__table__list-item">
                        Limit exceeded in request body (max 1000 numbers per request)
                      </div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">400</div>
                      <div class="api__table__list-item">33</div>
                      <div class="api__table__list-item">
                        Value out of bound. Please check whether the numbers are crypto with correct AES function.
                      </div>
                    </div>
                    <div class="api__table__list api__table__content">
                      <div class="api__table__list-item">200</div>
                      <div class="api__table__list-item">-</div>
                      <div class="api__table__list-item">Success</div>
                    </div>
                  </div>
                </div>
                <div class="tab-content-3">
                  <div class="api__title mt-40">Implementation Notes</div>
                  <div class="api__content mt-20">Encryption is needed for security purposes.</div>
                  <div class="api__title mt-40">AES Encryption (Advanced Encryption Standard)</div>
                  <div class="api__content__strong mt-20">Secret Key</div>
                  <div class="api__content">
                    Your <span class="highlight p-rl-5"><code>api_key</code></span> concats with
                    <span class="highlight p-rl-5"><code>#</code></span> and your
                    <span class="highlight p-rl-5"><code>company_id</code></span>.
                  </div>
                  <div class="api__code__title mt-20">For example</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
api_key: thisisyourkey1
company_id: herescompanyid

secret key: thisisyourkey1#herescompanyid
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__content__strong api__code__hl mt-20">Code Sample with Python</div>
                  <div class="api__content">Please encrypt as below.</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
# -*- coding: UTF-8 -*-

__all__ = ['encrypt', 'decrypt']

import binascii
import hashlib
from Crypto.Cipher import AES


def _pad(text):
    text_length = len(text)
    amount_to_pad = AES.block_size - (text_length % AES.block_size)
    if amount_to_pad == 0:
        amount_to_pad = AES.block_size
    pad = chr(amount_to_pad)
    return text + pad * amount_to_pad


def _unpad(text):
    if len(text) == 0:
        return ""

    pad = ord(text[-1])
    return text[:-pad]


def encrypt(secretkey, msg):
    if msg is None or len(msg) == 0:
        pass
    sha = hashlib.sha256()
    bin_src = secretkey.encode("utf-8")
    sha.update(bin_src)

    md5 = hashlib.md5()
    md5.update(bin_src)
    raw = _pad(msg)
    cipher = AES.new(sha.digest(), AES.MODE_CBC, md5.digest())
    return cipher.encrypt(raw.encode("utf-8"))


def decrypt(secretkey, msg):
    sha = hashlib.sha256()
    bin_src = secretkey.encode("utf-8")
    sha.update(bin_src)

    md5 = hashlib.md5()
    md5.update(bin_src)
    cipher = AES.new(sha.digest(), AES.MODE_CBC, md5.digest())
    return _unpad(cipher.decrypt(msg).decode('utf-8'))


if __name__ == '__main__':
    key = "&lt;appkey&gt;#&lt;brand_id&gt;"
    num = "&lt;num&gt;"

    print("secret key: ", key)
    print("msg: ", num)
    encryptmsg = encrypt(key, num)
    encryptmsg = binascii.hexlify(encryptmsg).decode('utf-8')
    print("encrypt msg: ", encryptmsg)

    decryptmsg = decrypt(key, binascii.unhexlify(encryptmsg))
    print("decrypt msg: ", decryptmsg)
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__content__strong api__code__hl mt-20">Code Sample with C#</div>
                  <div class="api__content">Please encrypt as below.</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
using System;
using System.Collections.Generic;
using System.Linq;
using System.Text;
using System.Threading.Tasks;
using System.Security.Cryptography;
using System.IO;

namespace AESEncryptSample
{
    public static class StringEncrypt
    {
        /// &lt;summary&gt;
        /// 字串加密(非對稱式)
        /// &lt;/summary&gt;
        /// &lt;param name="Source"&gt;加密前字串&lt;/param&gt;
        /// &lt;param name="CryptoKey"&gt;加密金鑰&lt;/param&gt;
        /// &lt;returns&gt;加密後字串&lt;/returns&gt;
        public static string aesEncrypt(string SourceStr, string CryptoKey)
        {
            string hexEncrypt = "";
            try
            {
                using (AesCryptoServiceProvider aes = new AesCryptoServiceProvider())
                {
                    MD5CryptoServiceProvider md5 = new MD5CryptoServiceProvider();
                    SHA256CryptoServiceProvider sha256 = new SHA256CryptoServiceProvider();
                    byte[] key = sha256.ComputeHash(Encoding.UTF8.GetBytes(CryptoKey));
                    byte[] iv = md5.ComputeHash(Encoding.UTF8.GetBytes(CryptoKey));

                    aes.Key = key;
                    aes.IV = iv;
                    aes.Mode = CipherMode.CBC;
                    aes.Padding = PaddingMode.PKCS7;

                    byte[] dataByteArray = Encoding.UTF8.GetBytes(SourceStr);
                    using (MemoryStream ms = new MemoryStream())
                    using (CryptoStream cs = new CryptoStream(ms, aes.CreateEncryptor(), CryptoStreamMode.Write))
                    {
                        cs.Write(dataByteArray, 0, dataByteArray.Length);
                        cs.FlushFinalBlock();
                        hexEncrypt = ByteToHex(ms.ToArray());
                    }
                }
            }
            catch (Exception e)
            {
                Console.WriteLine(e.Message);
            }
            return hexEncrypt;
        }

        /// &lt;summary&gt;
        /// 字串解密(非對稱式)
        /// &lt;/summary&gt;
        /// &lt;param name="Source"&gt;解密前字串&lt;/param&gt;
        /// &lt;param name="CryptoKey"&gt;解密金鑰&lt;/param&gt;
        /// &lt;returns&gt;解密後字串&lt;/returns&gt;
        public static string aesDecrypt(string SourceStr, string CryptoKey)
        {
            string decrypt = "";
            try
            {
                using (AesCryptoServiceProvider aes = new AesCryptoServiceProvider())
                {
                    MD5CryptoServiceProvider md5 = new MD5CryptoServiceProvider();
                    SHA256CryptoServiceProvider sha256 = new SHA256CryptoServiceProvider();
                    byte[] key = sha256.ComputeHash(Encoding.UTF8.GetBytes(CryptoKey));
                    byte[] iv = md5.ComputeHash(Encoding.UTF8.GetBytes(CryptoKey));
                    aes.Key = key;
                    aes.IV = iv;
                    aes.Mode = CipherMode.CBC;
                    aes.Padding = PaddingMode.PKCS7;
                    byte[] dataByteArray = SourceStr.HexToByte();

                    using (MemoryStream ms = new MemoryStream())
                    {
                        using (CryptoStream cs = new CryptoStream(ms, aes.CreateDecryptor(), CryptoStreamMode.Write))
                        {
                            cs.Write(dataByteArray, 0, dataByteArray.Length);
                            cs.FlushFinalBlock();
                            decrypt = Encoding.UTF8.GetString(ms.ToArray());
                        }
                    }
                }
            }
            catch (Exception e)
            {
                Console.WriteLine(e.Message);
            }
            return decrypt;
        }
        public static byte[] HexToByte(this string hexString)
        {
            byte[] byteOUT = new byte[hexString.Length / 2];
            for (int i = 0; i < hexString.Length; i = i + 2)
            {
                byteOUT[i / 2] = Convert.ToByte(hexString.Substring(i, 2), 16);
            }
            return byteOUT;
        }
        public static string ByteToHex(this byte[] Bdata )
        {
            return BitConverter.ToString(Bdata).Replace("-", "");
        }

        public static void Main()
        {
            try
            {
                string original_num = "&lt;num>";
                string key = "&lt;appkey>#&lt;brand_id>";

                string enc_num = aesEncrypt(original_num, key);
                string dec_num = aesDecrypt(enc_num, key);
                Console.WriteLine(enc_num);
                Console.WriteLine(dec_num);
            }
            catch (Exception e)
            {
                Console.WriteLine("Error: {0}", e.Message);
            }
        }
    }
}
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="form.api == 'Remain API'" class="api-item mt-20">
          <div class="api__block">
            <div class="api__detail mt-20">
              <span id="remain_api_document">1</span>
              <span id="remain_api_reference">2</span>
              <div id="tab">
                <!-- 頁籤按鈕 -->
                <ul>
                  <li><a href="#remain_api_document">Remain API Documentation</a></li>
                  <li><a href="#remain_api_reference">API Reference</a></li>
                </ul>
                <!-- 頁籤的內容區塊 -->
                <div class="tab-content-4">
                  <div class="api__title mt-40">About the API</div>
                  <div class="api__content mt-20">
                    This API allows you to programmatically query how many quotas are remaining which needed for
                    requesting WitcherFin Verify API.<br />Within the response body you will find the count of remaining
                    quota.
                  </div>

                  <div class="api__content mt-20">All URLs start with the root URL:</div>
                  <div class="inline-hl highlight mt-20">{{ apiUrl }}/license/quota/remain</div>

                  <div class="api__content mt-20">
                    Please note that all requests to the Remain API must be made over HTTPS.<br />The full details of
                    each endpoint offered by our API can be found in the
                    <a href="#remain_api_reference">API Reference</a>.
                  </div>

                  <div class="api__title mt-40">Data Formats</div>
                  <div class="api__content mt-20">The service currently supports JSON.</div>
                  <div class="api__content">
                    The valid media types are: <span class="api__content__strong">application/json</span>
                  </div>
                  <div class="api__content mt-20">Similarly, we accept data in JSON formats.</div>
                  <div class="api__content">
                    When sending data to the API, set the
                    <span class="highlight p-rl-5"><code>Content-Type</code></span> header to
                    <span class="highlight p-rl-5"><code>application/json</code></span> with a JSON body.
                  </div>
                  <div class="api__content">
                    You need the <span class="highlight p-rl-5"><code>app_id</code></span>, <span
                      class="highlight p-rl-5"><code>company_id</code></span>, <span
                      class="highlight p-rl-5"><code>uid</code></span>, <span
                      class="highlight p-rl-5"><code>api_key</code></span> to access the Verify API.
                  </div>
                  <div class="api__code__title api__code__hl mt-20">Code sample (curl)</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
curl -X GET \
  {{ apiUrl }}/license/quota/remain \
  -H 'Content-Type: application/json' \
  -H 'User-Agent: {app_id}|{company_id}|{uid}' \
  -H 'appkey: {api_key}'

  #if app_id = abc123, company_id = gogo, uid = cde456 --> 'User-Agent: abc123|gogo|cde456'
  -H 'appkey: {api_key}'
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__code__title api__code__hl mt-20">Code sample (C# Compiler .NET 4.5 up)</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
using System;
using System.Net;
using System.IO;

namespace RequestSample
{
    public class MainClass
    {
        public static void Main()
        {
            try{
                string webAddr="https://verify.witcherfin.com/license/quota/remain";
                var httpWebRequest = (HttpWebRequest)WebRequest.Create(webAddr);

                httpWebRequest.ContentType = "application/json; charset=utf-8";
                httpWebRequest.Method = "GET";
                httpWebRequest.UserAgent = "{app_id}|{company_id}|{uid}";
                httpWebRequest.Headers["appkey"] = "{api_key}";

                var httpResponse = (HttpWebResponse)httpWebRequest.GetResponse();
                using (var streamReader = new StreamReader(httpResponse.GetResponseStream()))
                {
                    var responseText = streamReader.ReadToEnd();
                    Console.WriteLine(responseText);

                    //Now you have your response.
                    //or false depending on information in the response
                }
            }catch(WebException ex){
                Console.WriteLine(ex.Message);
            }
        }
    }
}
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__title mt-40">Error</div>
                  <div class="api__content mt-20">
                    Other errors you may see will start with 5xx, e.g. 500 Internal Server Error.<br />
                    If this happens, try your request again later. If the error persists please contact us at the email
                    below.<br />
                    Check <a href="#remain_api_reference">API Reference</a> to see all status codes.
                  </div>
                  <div class="api__title mt-40">API Improvements</div>
                  <div class="api__content mt-20">
                    For any issues or feature requests please contact us at
                    <a href="mailto:witcherfin.service@gogolook.com.tw">witcherfin.service@gogolook.com.tw</a>.
                  </div>
                </div>
                <div class="tab-content-5">
                  <div class="api__title mt-40">Implementation Notes</div>
                  <div class="api__content mt-20">
                    To get how many quotas are remaining which needed for requesting Verify API.
                  </div>
                  <div class="api__title mt-40">Request Body</div>
                  <div class="api__content mt-20">
                    <span class="api__method">GET</span><span class="api__path ml-10">/license/quota/remain</span>
                  </div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
URL Parameters:
    No parameters

Header:
   - appkey: {api_key}
   - User-Agent: {app_id}|{company_id}|{uid}
   - content-type: application/json
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__title mt-40">Response Body</div>
                  <div class="api__content">Return the count.</div>
                  <div class="api__code__title mt-20">Model</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code>
OrderedMap {
    remain_quota (integer)
}
                        </code>
                      </pre>
                    </div>
                  </div>
                  <div class="api__code__title">Example Value</div>
                  <div class="highlighter-rouge">
                    <div class="highlight">
                      <pre>
                        <code v-if="$store.state.license.dataset === 'bank'">
{
     <span class="code--red">"remain_quota"</span> 10
}
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content" v-else>
        <div class="noauth-item mt-40">
          <img class="noauth__img" :src="`${$baseUrl}img_no_purchase.png`" />
        </div>
        <div class="noauth-item mt-80">
          <span>
            {{ $t('tryapi_no_api_service_desc', {
            contactus_business_email: $t('contactus_business_email'),
            }) }}
          </span>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main"
        v-if="version === 'online' && $store.state.user.role === 'admin' && status.tokenAuth">
        <div class="dialog__title">{{ $t('tryapi_api_credentials_result_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('tryapi_api_credentials_appid') }}</div>
        <div class="dialog__cred__desc mt-5">{{ message.licesneID }}</div>
        <div class="dialog__desc mt-20">{{ $t('tryapi_api_credentials_apikey') }}</div>
        <div class="dialog__cred__desc mt-5" v-if="$store.state.enableMaskAppKey">{{ message.maskedLicenseToken }}</div>
        <div class="dialog__cred__desc mt-5" v-else>{{ message.licenseToken }}</div>
        <div class="main-item mt-60">
          <a class="long-button" v-on:click="dismissDialog">{{ $t('tryapi_api_credentials_view_admin_button') }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main"
        v-else-if="version === 'online' && $store.state.user.role === 'admin' && !status.tokenAuth">
        <div class="dialog__title">{{ $t('tryapi_api_credentials_view_admin_title') }}</div>
        <div class="main-item mt-70">
          <input class="form__input" type="password" v-bind:class="{ 'has-error': status.mailError }"
            v-model.trim="form.password" v-on:focus="message.apiError = null" v-on:keyup.enter="checkUserPassword" />
        </div>
        <div class="main-item mt-96">
          <a class="long-button" :disabled="!isComplete" v-on:click="checkUserPassword">{{
            $t('tryapi_api_credentials_view_admin_button')
            }}</a>
        </div>
        <div class="main-item mt-20" v-if="message.apiError">
          <div class="form__api__error">{{ message.apiError }}</div>
        </div>
        <div class="dialog__cancel icon-close" v-on:click="dismissDialog"></div>
      </div>
      <div class="dialog" id="dialog-main" v-else-if="version === 'offline' && $store.state.user.role === 'admin'">
        <div class="dialog__title">{{ $t('tryapi_api_credentials_view_admin_user_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('tryapi_api_credentials_view_admin_user_desc') }}</div>
        <div class="main-item mt-60">
          <a class="long-button" v-on:click="dismissDialog">{{
            $t('tryapi_api_credentials_view_admin_user_button')
            }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-else>
        <div class="dialog__title">{{ $t('tryapi_api_credentials_view_user_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('tryapi_api_credentials_view_user_desc') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiCheckAccountPwd, apiLicenseAppToken } from '../api.js';
import Header from './base/header.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'ApiDocs',
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      apiUrl: process.env.VUE_APP_API_DOCS_URL,
      dialog: {
        type: null,
      },
      status: {
        passwordError: false,
        tokenAuth: false,
      },
      form: {
        email: this.$store.state.user.email,
        brand_id: this.$store.state.user.brand_id,
        password: null,
        apis: ['Verify API', 'Remain API'],
        api: 'Verify API',
      },
      message: {
        apiError: null,
        licesneID: null,
        licenseToken: null,
        maskedLicenseToken: null,
      },
      licenseData: {
        unlimited_quota: this.$store.state.license.unlimited_quota,
      },
    };
  },
  computed: {
    isComplete() {
      return !this.status.passwordError && this.form.password;
    },
  },
  components: {
    'app-header': Header,
    'app-sidebar': Sidebar,
  },
  created() {
    const $ = this;

    $.updateRemainLicenseQuota();
  },
  methods: {
    getApiToken: function () {
      const $ = this;

      $.updateLicenseStatus();
      $.dialog.type = 'getApiToken';
    },
    checkUserPassword: function () {
      const $ = this;
      $.message.apiError = null;

      apiCheckAccountPwd({
        brand_id: $.form.brand_id,
        email: $.form.email,
        password: $.form.password,
      })
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.getLicenseToken();
          } else {
            $.message.apiError = $.$i18n.t('tryapi_api_credentials_view_admin_error_400_26');
          }
        })
        .catch(function (error) {
          $.message.apiError = error;
        });
    },
    getLicenseToken: function () {
      const $ = this;

      apiLicenseAppToken()
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.message.licesneID = res.res.id;
            $.message.licenseToken = res.res.token;
            $.message.maskedLicenseToken = res.res.token.replace(/./g, '*');
            $.status.tokenAuth = true;
          } else {
            $.status.passwordError = true;
          }
        })
        .catch(function () {
          $.status.passwordError = true;
        });
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #eaeaea;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.main-item {
  max-width: 1100px;
  background-color: #ffffff;
}

.api-item {
  max-width: 1100px;
  background-color: #ffffff;
}

.noauth-item {
  width: 100%;
  text-align: center;
}

.noauth__img {
  width: 440px;
}

.token__block {
  /* display: flex;
  align-items: center; */
  position: relative;
  padding: 20px 40px;
}

.brand__info {
  flex-basis: 50%;
}

.brand__text {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.token__info {
  position: absolute;
  width: fit-content;
  top: 70px;
  right: 40px;
}

.api__block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
}

.api__summary,
.api__detail {
  width: 100%;
  font-size: 14px;
  color: #000000;
}

.tab__menu {
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
}

.tab__item {
  width: fit-content;
  line-height: 40px;
  padding: 0 26px;
  border-bottom: 5px solid #870136;
}
.tab__content {
  width: 100%;
}

.tab__content__title {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.highlight {
  background-color: #f5f5f5;
  /* padding: 20px 30px; */
}

.inline-hl {
  padding: 7.5px 30px;
}

.api__title {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}

.api__content {
  font-size: 14px;
  color: #000000;
}

.api__method {
  background-color: #000000;
  height: 30px;
  width: fit-content;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  color: #ffffff;
  padding: 0 6px;
}

.api__path {
  line-height: 30px;
}

.api__content__strong {
  font-weight: 600;
}

.api__code__title {
  font-size: 14px;
  font-style: italic;
  color: #aeaeae;
}

.api__code__hl {
  color: #640061;
}

.api__text--purple {
  color: #870136;
}

.api__text--green {
  color: #378619;
}

.api__table {
  background-color: #ffffff;
  padding: 30px 0;
}

.api__table__list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.api__table__list-item {
  padding: 0 20px;
  flex-basis: 33%;
}

.api__table__head {
  height: 30px;
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.api__table__content {
  min-height: 30px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

pre {
  padding: 20px 30px;
  color: #252525;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border: 0;
}

.code--red {
  color: #db415f;
}

.code--blue {
  color: #4184db;
}

.code--caption {
  color: #aeaeae;
}

#tab {
  width: 100%;
}
/* 頁籤ul */
#tab > ul {
  overflow: hidden;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
}
#tab > ul > li {
  list-style-type: none;
}
#tab > ul > li > a {
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  color: #333;
  float: left;
  padding: 10px 26px;
}

/*頁籤div內容*/
#tab > div {
  clear: both;
  padding: 0 15px;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

/* span:target */
#verify_api_document,
#api_reference,
#data_crypto,
#remain_api_document,
#remain_api_reference {
  display: none;
}

/*第一筆的底色*/
span:target ~ #tab > ul li:first-child a {
  border-bottom: none;
}

/*第一筆的底色*/
span:target ~ #tab > div:first-of-type {
  visibility: hidden;
  height: 0;
  padding: 0 15px;
}

/*頁籤變換&第一筆*/
span ~ #tab > ul li:first-child a,
#verify_api_document:target ~ #tab > ul li a[href$='#verify_api_document'],
#api_reference:target ~ #tab > ul li a[href$='#api_reference'],
#data_crypto:target ~ #tab > ul li a[href$='#data_crypto'],
#remain_api_document:target ~ #tab > ul li a[href$='#remain_api_document'],
#remain_api_reference:target ~ #tab > ul li a[href$='#remain_api_reference'] {
  border-bottom: 5px solid #870136;
}

/*頁籤內容顯示&第一筆*/
span ~ #tab > div:first-of-type,
#verify_api_document:target ~ #tab > div.tab-content-1,
#api_reference:target ~ #tab > div.tab-content-2,
#data_crypto:target ~ #tab > div.tab-content-3,
#remain_api_document:target ~ #tab > div.tab-content-4,
#remain_api_reference:target ~ #tab > div.tab-content-5 {
  visibility: visible;
  height: fit-content;
  background: #fff;
}

.main-button:disabled,
.main-button[disabled] {
  pointer-events: unset;
}

.api-selector-container {
  width: 480px;
  position: relative;
  overflow: hidden;
  display: block;
  height: 40px;
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
}

.api-selector-container::after {
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 16px;
  height: 16px;
  font-size: 60%;
  line-height: 40px;
  font-family: 'witcherfin';
  content: '\e905';
  pointer-events: none;
}

.api-selector-label {
  padding-right: 25px;
  font-size: 14px;
  color: #000000;
  line-height: 40px;
  padding: 0 20px;
}

.api-selector {
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  -webkit-appearance: none;
}
</style>
