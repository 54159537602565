import axios from 'axios';

import store from './store';

const withCredentials = config => {
  config.withCredentials = true
  return config
}

const witcherRequest = axios.create({
  baseURL: process.env.VUE_APP_WITCHER_API_URL,
  headers: {
    'Content-Type': 'application/json',
    user: 'verify_web||',
    appkey: 'aboDcMGTasddbuembBfYkhnv9SvdzV5i',
  },
});

witcherRequest.interceptors.request.use(withCredentials)

const witcherRequestWithAuth = axios.create({
  baseURL: process.env.VUE_APP_WITCHER_API_URL,
  headers: {
    appkey: 'aboDcMGTasddbuembBfYkhnv9SvdzV5i',
  },
});

witcherRequestWithAuth.interceptors.request.use(
  (config) => {
    config = withCredentials(config)
    const brand_id = store.state.user.brand_id;
    const uuid = store.state.user.uuid;

    if (brand_id && uuid) {
      config.headers.common['user'] = `verify_web|${brand_id}|${uuid}`;
    }

    if (
      config.url === process.env.VUE_APP_API_DATA_UPLOAD ||
      config.url === process.env.VUE_APP_API_REPORTS_GENERATE_FILES
    ) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

// License API
export const apiOldLicenseUpdate = (data) => witcherRequestWithAuth.post(process.env.VUE_APP_API_LICENSE_UPDATE, data);
export const apiLicenseUpdate = (data) => witcherRequestWithAuth.patch(process.env.VUE_APP_API_LICENSE_UPDATE, data);
export const apiLicenseActive = (data) => witcherRequest.post(process.env.VUE_APP_API_LICENSE_ACTIVE, data);
export const apiLicenseStatus = () => witcherRequestWithAuth.get(process.env.VUE_APP_API_LICENSE_STATUS);
export const apiLicenseQuotaRemain = () => witcherRequestWithAuth.get(process.env.VUE_APP_API_LICENSE_QUOTA_REMAIN);
export const apiLicenseQuotaReport = (data) =>
  witcherRequestWithAuth.get(
    process.env.VUE_APP_API_LICENSE_QUOTA_REPORT.replace(/\{year\}/g, data.year).replace(/\{month\}/g, data.month)
  );
export const apiLicenseAppToken = () => witcherRequestWithAuth.get(process.env.VUE_APP_API_LICENSE_APP_TOKEN);
export const apiLicenseTokenUpdate = (data) => witcherRequest.post(process.env.VUE_APP_API_LICENSE_TOKEN_UPDATE, data);

// Account API
export const apiAccountRegister = (data) => witcherRequest.post(process.env.VUE_APP_API_ACCOUNT_REGISTER, data);
export const apiAccountLogin = (data) => witcherRequest.post(process.env.VUE_APP_API_ACCOUNT_LOGIN, data);
export const apiAccountUsers = () => witcherRequestWithAuth.get(process.env.VUE_APP_API_ACCOUNT_USERS);
export const apiAccountUser = (data) => witcherRequestWithAuth.put(process.env.VUE_APP_API_ACCOUNT_USER, data);
export const apiAccountUserPassword = (data) => witcherRequest.put(process.env.VUE_APP_API_ACCOUNT_USER_PASSWORD, data);
export const apiAccountUserInvite = (data) =>
  witcherRequestWithAuth.post(process.env.VUE_APP_API_ACCOUNT_USER_INVITE, data.invite_email);
export const apiAccountDelete = (data) => witcherRequestWithAuth.delete(process.env.VUE_APP_API_ACCOUNT_DELETE, data);
export const apiAccountResetpwdEmail = (data) =>
  witcherRequest.post(process.env.VUE_APP_API_ACCOUNT_RESETPWD_EMAIL, data);
export const apiCheckAccountPwd = (data) => witcherRequest.post(process.env.VUE_APP_API_CHECK_ACCOUNT_PWD, data);

// RegCode API
export const apiRegCodeStatus = (data) => {
  let url = process.env.VUE_APP_API_REGCODE_STATUS.replace(/\{code\}/g, data.code);
  if (data.token !== undefined) url = `${url}/${data.token}`;
  return witcherRequest.get(url);
};

// Search API
export const apiSearchDetail = (data) =>
  witcherRequestWithAuth.get(
    process.env.VUE_APP_API_SEARCH_DETAIL.replace(/\{number\}/g, data.number).replace(/\{locale\}/g, data.locale)
  );
export const apiSearchBatch = (data) =>
  witcherRequestWithAuth.post(
    process.env.VUE_APP_API_SEARCH_BATCH.replace(/\{locale\}/g, data.locale),
    data.number_list
  );
export const apiSearch = (data) => {
  const datasets = [store.state.license.dataset];
  let args_datasets = [];
  datasets.forEach((dataset) => {
    args_datasets.push(`dataset=${dataset}`);
  });
  return witcherRequestWithAuth.post(
    process.env.VUE_APP_API_SEARCH.replace(/\{locale\}/g, data.locale).replace(
      /\{datasets\}/g,
      args_datasets.join('&')
    ),
    data.numbers
  );
};

// Data API
export const apiDataStatus = () => witcherRequestWithAuth.get(process.env.VUE_APP_API_DATA_STATUS);
export const apiDataUpload = (data) => witcherRequestWithAuth.post(process.env.VUE_APP_API_DATA_UPLOAD, data);
export const apiDataDelete = (data) =>
  witcherRequestWithAuth.delete(process.env.VUE_APP_API_DATA_DELETE.replace(/\{id\}/g, data.id));

// Reports API
export const apiReportGenerateFiles = (data) =>
  witcherRequestWithAuth.post(process.env.VUE_APP_API_REPORTS_GENERATE_FILES, data);
export const apiReportStatus = (data) =>
  witcherRequestWithAuth.get(
    process.env.VUE_APP_API_REPORTS_STATUS.replace(/\{stime\}/g, data.stime).replace(/\{etime\}/g, data.etime)
  );
export const apiReportDelete = (data) =>
  witcherRequestWithAuth.delete(process.env.VUE_APP_API_REPORTS_DELETE.replace(/\{id\}/g, data.id));

// System API
export const apiSystemVersion = () => witcherRequestWithAuth.get(process.env.VUE_APP_API_SYSTEM_VERSION);
export const apiSystemStatus = () => witcherRequestWithAuth.get(process.env.VUE_APP_API_SYSTEM_STATUS);

// Web API
export const apiLogout = () => witcherRequestWithAuth.get(process.env.VUE_APP_API_LOGOUT);
export const apiCheckUserStatus = () => witcherRequest.get(process.env.VUE_APP_API_CHECK_USER_STATUS);
export const apiCheckSearchApiStatus = () => witcherRequest.get(process.env.VUE_APP_API_CHECK_SEARCH_API_STATUS);
