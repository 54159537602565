<template>
  <div class="message-item">
    <div class="lang-item mt-40 mr-60">
      <lang-selector></lang-selector>
    </div>
    <div v-if="type == 'LicenseActivatedSuccess'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_active.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('license_active_sentemail_title') }}</div>
      <div class="message__content mt-3p">{{ $t('license_active_sentemail_desc') }}</div>
    </div>
    <div v-if="type == 'LicenseCodeError'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('license_active_regcode_200_status4_title') }}</div>
      <a
        class="long-button mt-6p"
        :href="
          $t('contactus_support_email', {
            subject: $t('license_active_regcode_200_status4_mail_subject'),
            body: message,
          })
        "
        >{{ $t('license_active_regcode_200_status4_button') }}</a
      >
    </div>
    <div v-if="type == 'LoginLicenseExpire'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('login_error_400_14_title') }}</div>
      <div class="message__content mt-3p">{{ $t('login_error_400_14_desc') }}</div>
    </div>
    <div v-if="type == 'LoginAccountBanned'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_email.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('login_error_400_28_title') }}</div>
      <div class="message__content mt-3p">{{ $t('login_error_400_28_desc') }}</div>
    </div>
    <div v-if="type == 'APIError'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_error.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('license_active_regcode_200_status4_title') }}</div>
      <div class="message__content mt-3p">{{ message }}</div>
    </div>
    <div v-if="type == 'RegisterCodeError'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('register_regcode_200_status4_title') }}</div>
      <a
        class="long-button mt-6p"
        v-if="role == 'admin'"
        :href="
          $t('contactus_support_email', { subject: $t('register_regcode_200_status4_mail_subject'), body: message })
        "
        >{{ $t('register_regcode_200_status4_button_admin') }}</a
      >
      <div class="message__content mt-3p" v-else>{{ $t('register_regcode_200_status4_desc_user') }}</div>
    </div>
    <div v-if="type == 'RegisterLinkInvalid'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('register_error_400_22_title') }}</div>
    </div>
    <div v-if="type == 'RegisterCodeExpire'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('register_error_400_23_title') }}</div>
      <a
        class="long-button mt-6p"
        v-if="role == 'admin'"
        :href="$t('contactus_support_email', { subject: $t('register_error_400_23_mail_subject'), body: message })"
        >{{ $t('register_error_400_23_button_admin') }}</a
      >
      <div class="message__content mt-3p" v-else>{{ $t('register_error_400_23_desc_user') }}</div>
    </div>
    <div v-if="type == 'AlreadyRegistered'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('register_error_400_24_title') }}</div>
      <div class="message__content mt-3p">{{ $t('register_error_400_24_desc') }}</div>
      <div class="message__btn mt-6p">
        <a class="long-button" v-on:click="redirectLoginPage">{{ $t('register_error_400_24_button') }}</a>
      </div>
    </div>
    <div v-if="type == 'ForgetPwdSuccess'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_email.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('login_forget_pwd_sentemail_title') }}</div>
      <div class="message__content mt-3p">{{ $t('login_forget_pwd_sentemail_desc') }}</div>
    </div>
    <div v-if="type == 'ResetPwdCodeError'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('reset_pwd_regcode_200_status4_title') }}</div>
      <div class="message__content mt-3p">{{ $t('reset_pwd_regcode_200_status4_desc_isnot_login') }}</div>
      <div class="message__btn mt-6p">
        <a class="long-button" v-on:click="redirectLoginPage">{{
          $t('reset_pwd_regcode_200_status4_button_isnot_login')
        }}</a>
      </div>
    </div>
    <div v-if="type == 'ResetPwdLinkInvalid'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('reset_pwd_error_400_22_title ') }}</div>
    </div>
    <div v-if="type == 'ResetPwdCodeExpire'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}/img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('reset_pwd_error_400_23_title') }}</div>
      <div class="message__content mt-3p">{{ $t('reset_pwd_error_400_23_desc_isnot_login') }}</div>
      <div class="message__btn mt-6p">
        <a class="long-button" v-on:click="redirectLoginPage">{{ $t('reset_pwd_error_400_23_button_isnot_login') }}</a>
      </div>
    </div>
    <div v-if="type == 'ResetPwdSuccess'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_complete.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('reset_pwd_regcode_200_status1_title') }}</div>
      <div class="message__content mt-3p">{{ $t('reset_pwd_regcode_200_status1_desc') }}</div>
      <div class="message__btn mt-6p">
        <a class="long-button" v-on:click="redirectLoginPage">{{ $t('reset_pwd_regcode_200_status1_button') }}</a>
      </div>
    </div>
    <div v-if="type == 'SearchLicenseExpire'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_expire.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('numsearch_error_400_14_title') }}</div>
      <div class="message__content mt-3p">{{ $t('numsearch_error_400_14_desc') }}</div>
      <div class="message__btn mt-6p">
        <a class="long-button" v-on:click="logout()">{{ $t('numsearch_error_400_14_desc_button') }}</a>
      </div>
    </div>
    <div v-if="type == 'ForceLogout'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_logout.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('session_timeout_already_timeout_title') }}</div>
      <div class="message__content mt-3p">{{ $t('session_timeout_already_timeout_desc') }}</div>
      <div class="message__btn mt-6p">
        <a class="long-button" v-on:click="redirectLoginPage">{{ $t('session_timeout_already_timeout_button') }}</a>
      </div>
    </div>
    <div v-if="type == 'UnsupportedBrowser'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_active.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('unsupported_browser_page_title') }}</div>
      <div class="message__content mt-3p">{{ $t('unsupported_browser_page_desc') }}</div>
    </div>
    <div v-if="type == 'IPNotAllowed'" class="message">
      <div class="full__message__img mt-7p">
        <img class="message__img" :src="`${$baseUrl}img_active.png`" />
      </div>
      <div class="message__title mt-7p">{{ $t('unsupported_browser_page_title') }}</div>
      <div class="message__content mt-3p">{{ $t('unsupported_browser_ip_restricted') }}</div>
    </div>
  </div>
</template>

<script>
import LangSelector from './LangSelector.vue';

export default {
  name: 'FullPageMessage',
  components: {
    'lang-selector': LangSelector,
  },
  props: {
    type: String,
    message: String,
    role: String,
  },
  methods: {
    redirectLoginPage: function () {
      const $ = this;

      $.$router.push('/user/login/');
    },
  },
};
</script>

<style scoped>
.message-item {
  display: flex;
  flex-direction: column;
}

.lang-item {
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.message {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message__img {
  height: 35vh;
}

.message__title {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
}

.message__content {
  font-size: 14px;
  color: #000000;
  line-height: 24px;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
