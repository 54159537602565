<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message :type="message.type" :message="message.text"></full-page-message>
    </div>
    <div v-if="status.linkValid && !message.type" class="main-block">
      <div class="main-content">
        <div class="lang-item">
          <lang-selector></lang-selector>
        </div>
        <div class="main-item mt-80">
          <div class="page__title">{{ $t('reset_pwd_page_title') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('reset_pwd_col_new') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="password"
            v-bind:class="{ 'has-error': status.passwordError, 'has-loading': status.isLoading }"
            v-model="form.userPassowrd"
            v-on:blur="status.passwordError = checkPasswordFormat(form.userPassowrd)"
            v-on:focus="
              status.passwordError = null;
              message.apiError = null;
            "
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.passwordError" class="form__error">{{ $t('reset_pwd_client_diff_pwd') }}</div>
        </div>
        <div class="main-item mt-6">
          <div class="form__hint" v-html="$t('reset_pwd_desc_set_pwd')"></div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('reset_pwd_col_new_check') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="password"
            v-bind:class="{ 'has-error': status.passwordAgainError, 'has-loading': status.isLoading }"
            v-model="form.userPassowrdAgain"
            v-on:blur="status.passwordAgainError = checkPasswordEqual(form.userPassowrd, form.userPassowrdAgain)"
            v-on:focus="
              status.passwordAgainError = null;
              message.apiError = null;
            "
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.passwordAgainError" class="form__error">{{ $t('reset_pwd_client_diff_pwd') }}</div>
        </div>
        <div class="main-item mt-60">
          <a class="long-button" :disabled="!isComplete" v-on:click="postNewPassword">{{ $t('reset_pwd_button') }}</a>
        </div>
        <div class="main-item mt-20">
          <div v-if="message.apiError" class="form__api__error">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiAccountUserPassword, apiRegCodeStatus } from '../api.js';
import InlineLoading from './partials/InlineLoading.vue';
import FullPageMessage from './partials/FullPageMessage.vue';
import LangSelector from './partials/LangSelector.vue';

export default {
  name: 'UserChangePassword',
  data() {
    return {
      status: {
        linkValid: false,
        passwordError: false,
        passwordAgainError: false,
        isLoading: false,
      },
      form: {
        code: this.$route.query.rc,
        userEmail: null,
        userPassowrd: null,
        userPassowrdAgain: null,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
    };
  },
  components: {
    'full-page-message': FullPageMessage,
    'inline-loading': InlineLoading,
    'lang-selector': LangSelector,
  },
  created() {
    const $ = this;

    if ($.form.code && $.form.code.length === 6) {
      apiRegCodeStatus({
        code: $.form.code,
      })
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.status.linkValid = true;
          } else {
            $.message.type = 'ResetPwdCodeError';
          }
        })
        .catch(function (error) {
          $.message.type = 'APIError';
          $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
        });
    } else {
      $.message.type = 'LicenseCodeError';
    }
  },
  computed: {
    isComplete() {
      return (
        !this.status.passwordError &&
        !this.status.passwordAgainError &&
        !this.status.isLoading &&
        this.form.code &&
        this.form.userPassowrd &&
        this.form.userPassowrdAgain
      );
    },
  },
  methods: {
    postNewPassword: function () {
      const $ = this;
      $.status.isLoading = true;
      $.message.apiError = null;

      apiAccountUserPassword({
        code: $.form.code,
        user_password: $.form.userPassowrd,
      })
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.message.type = 'ResetPwdSuccess';
          } else if (res.msg === 'REG_CODE_NOT_MATCH') {
            $.message.type = 'ResetPwdLinkInvalid';
          } else if (res.msg === 'REG_CODE_EXPIRED') {
            $.message.type = 'ResetPwdCodeExpire';
          } else if (res.msg === 'UNSUPPORT_FORMAT') {
            $.message.type = 'ResetPwdLinkInvalid';
          } else if (res.msg === 'ACCOUNT_NOT_EXIST') {
            $.message.type = 'ResetPwdLinkInvalid';
          } else {
            $.message.apiError = res.msg;
          }

          $.status.isLoading = false;
        })
        .catch(function (error) {
          $.status.isLoading = false;
          $.message.type = 'APIError';
          $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
        });
    },
  },
};
</script>

<style scoped>
.site-container {
  display: flex;
  height: 100vh;
}

.main-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang-item {
  width: 160px;
  position: absolute;
  top: 40px;
  right: 60px;
}
</style>
