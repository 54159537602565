<template>
  <div class="site-main">
    <app-header :title="$t('side_bar_menu_account_search_cnt')"></app-header>
    <div class="main-block">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div v-if="message.type" class="main-content">
        <full-page-message :type="message.type" :message="message.text"></full-page-message>
      </div>
      <div v-if="!message.type" class="main-content">
        <div class="main-item">{{ $t('reportcnt_col_period') }}</div>
        <div class="main-item mt-10">
          <div class="action__block">
            <div class="custom-selector">
              <span class="quota-selector-container">
                <div class="quota-selector-label">{{ form.year }}</div>
                <select class="quota-selector" v-model="form.year">
                  <option v-for="(year, index) in years" v-bind:value="year" v-bind:key="index">{{ year }}</option>
                </select>
              </span>
            </div>
            <div class="custom-selector ml-20">
              <span class="quota-selector-container">
                <div class="quota-selector-label">{{ form.month }}</div>
                <select class="quota-selector" v-model="form.month">
                  <option v-for="(month, index) in months" v-bind:value="month" v-bind:key="index">{{ month }}</option>
                </select>
              </span>
            </div>
            <a class="main-button ml-20" :disabled="!checkDateFormat" v-on:click="getQuotaHistory">{{
              $t('reportcnt_button_search')
            }}</a>
          </div>
          <div class="form__error mt-6" v-if="!checkDateFormat">{{ $t('reportcnt_client_error_date') }}</div>
        </div>
        <div class="main-item mt-40">
          <div v-if="status.isLoading" class="main-item mt-30">
            <inline-loading></inline-loading>
          </div>
          <div class="result__block__empty" v-if="resultData != null && resultData.length == 0">
            {{ $t('reportcnt_200_blank') }}
          </div>
          <div class="result__block" v-if="resultData != null && resultData.length > 0">
            <div class="result__block__list result__block__head">
              <div class="result__block__list-item">{{ $t('reportcnt_result_table_date') }}</div>
              <div class="result__block__list-item">{{ $t('reportcnt_result_table_cnt') }}</div>
            </div>
            <div
              class="result__block__list result__block__content"
              v-for="(item, index) in resultData"
              v-bind:key="index"
            >
              <div class="result__block__list-item">{{ item.date }}</div>
              <div class="result__block__list-item">{{ item.quota | commaFormat }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiLicenseQuotaReport } from '../api.js';
import Header from './base/header.vue';
import InlineLoading from './partials/InlineLoading.vue';
import Sidebar from './base/sidebar.vue';
import FullPageMessage from './partials/FullPageMessage.vue';

export default {
  name: 'UserQuota',
  data() {
    return {
      form: {
        year: this.currentYear(),
        month: this.currentMonth(),
      },
      resultData: null,
      message: {
        type: null,
        text: null,
        apiError: null,
      },
      status: {
        isLoading: false,
      },
    };
  },
  components: {
    'app-header': Header,
    'app-sidebar': Sidebar,
    'inline-loading': InlineLoading,
    'full-page-message': FullPageMessage,
  },
  computed: {
    checkDateFormat() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const chooseYear = parseInt(this.form.year);
      const chooseMonth = parseInt(this.form.month);

      if (currentYear === chooseYear && currentMonth < chooseMonth) {
        return false;
      }

      return true;
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from({ length: year - 2017 }, (value, index) => 2018 + index);
    },
    months() {
      return Array.from({ length: 12 }, (value, index) => {
        if (index < 9) {
          return `0${index + 1}`;
        } else {
          return index + 1;
        }
      });
    },
  },
  methods: {
    currentYear: function () {
      return new Date().getFullYear();
    },
    currentMonth: function () {
      const currentMonth = new Date().getMonth() + 1;
      if (currentMonth < 10) {
        return `0${currentMonth}`;
      } else {
        return currentMonth;
      }
    },
    getQuotaHistory: function () {
      const $ = this;
      $.status.isLoading = true;
      $.message.apiError = null;
      $.resultData = null;

      apiLicenseQuotaReport({
        year: $.form.year,
        month: $.form.month,
      })
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.resultData = res.res;
          } else {
            $.message.apiError = $.$i18n.t(`api_error.${res.msg}`);
          }

          $.status.isLoading = false;
        })
        .catch(function (error) {
          $.message.type = 'APIError';
          $.message.text = error;
        });
    },
  },
  filters: {
    commaFormat: function (value) {
      return value.toString().replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function (all, pre, groupOf3Digital) {
        return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
      });
    },
  },
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #eaeaea;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.main-item {
  max-width: 480px;
}

.action__block {
  display: flex;
}

.result__block {
  background-color: #ffffff;
  padding: 30px 40px;
}

.result__block__empty {
  padding: 30px 40px;
  font-size: 14px;
  color: #aeaeae;
  text-align: center;
}

.result__block__list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.result__block__list-item {
  flex-basis: 50%;
}

.result__block__head {
  height: 30px;
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.result__block__content {
  height: 30px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

/* .custom-selector {
  position: relative;
  height: 40px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  background-color: #fff;
  overflow: hidden;
}

.custom-selector::before {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 100%;
  background-color: #fff;
  content: "";
}

.custom-selector::after {
  display: block;
  position: absolute;
  top: 45%;
  right: 12px;
  width: 0;
  height: 0;
  border-width: 6px 4px 0;
  border-style: solid;
  border-color: #000 transparent transparent;
  content: "";
}

.quota-selector {
  width: 160px;
  height: 40px;
  padding: 5px 16px;
  border: none;
  border-radius: 0;
  background-color: #fff;
  font-size: 14px;
  padding: 0 16px 0 12px;
  word-break: normal;
  word-wrap: normal;
} */

.quota-selector-container {
  width: 160px;
  position: relative;
  overflow: hidden;
  display: block;
  height: 40px;
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
}

.quota-selector-container::after {
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 16px;
  height: 16px;
  font-size: 60%;
  line-height: 40px;
  font-family: 'witcherfin';
  content: '\e905';
  pointer-events: none;
}

.quota-selector-label {
  padding-right: 25px;
  font-size: 14px;
  color: #000000;
  line-height: 40px;
  padding: 0 20px;
}

.quota-selector {
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  -webkit-appearance: none;
}

.form__error {
  text-align: left;
}
</style>
