<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message
        :type="message.type"
        :message="message.text"
        :role="$store.state.user.role"
      ></full-page-message>
    </div>
    <app-header :title="headerTitle" v-if="!message.type"></app-header>
    <div class="main-block" v-if="!message.type">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div class="main-content unsupport-content" v-if="!isQueryValidDataset()">
        <div class="unsupport-item unsupport__title mt-80">invalid dataset</div>
      </div>
      <div class="main-content maintenace-content" v-if="isQueryValidDataset() && status.systemMaintenance">
        <div class="maintenace-item mt-40">
          <img class="maintenace__img" :src="`${$baseUrl}img_maintenance.png`" />
        </div>
        <div class="maintenace-item maintenace__title mt-80" v-html="$t('updating_tem_disable_title')"></div>
        <div class="maintenace-item maintenace__desc mt-30" v-html="$t('updating_tem_disable_desc')"></div>
      </div>
      <div
        id="batch-table"
        class="main-content"
        v-if="
          isQueryValidDataset() && status.isValid && !status.systemMaintenance && batchNumberForm.resultData.length > 0
        "
      >
        <div class="main-item">
          <div class="multiple__search__result__block">
            <div class="action__block">
              <div class="search__progressbar">
                {{ $t('numsearch_result_multi_progress_bar_remain') }}
                {{ batchNumberForm.numberList.length - batchNumberForm.resultData.length }} /
                {{ $t('numsearch_result_multi_progress_bar_total') }} {{ batchNumberForm.numberList.length }}
              </div>
              <div
                class="download__btn"
                :disabled="batchNumberForm.numberList.length !== batchNumberForm.resultData.length"
              >
                <a class="main-button" :href="download.href" target="_blank" :download="download.filename">{{
                  $t('numsearch_result_single_button')
                }}</a>
              </div>
            </div>
            <div class="bg__white mt-40">
              <div class="result__block" ref="printMe">
                <div class="result__block__list result__block__head">
                  <div class="result__block__list-item">{{ $t('numsearch_result_multi_table_header_num') }}</div>
                  <div class="result__block__list-item">{{ $t('numsearch_result_multi_table_header_progress') }}</div>
                </div>
                <div
                  class="result__block__list result__block__content"
                  v-for="(item, index) in batchNumberForm.resultData"
                  v-bind:key="index"
                >
                  <div class="result__block__list-item">{{ item.num }}</div>
                  <div class="result__block__list-item">
                    <span class="icon-check mr-10"></span>{{ $t('numsearch_result_multi_table_progress_value') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="main-content"
        v-if="
          isQueryValidDataset() && status.isValid && !status.systemMaintenance && batchNumberForm.resultData.length == 0
        "
      >
        <div class="main-item">
          <div class="search__block">
            <div
              class="search__left__block"
              v-on:click="status.searchSingle = true"
              v-bind:class="{ active: status.searchSingle }"
            >
              <div class="item-title mt-70">{{ $t('numsearch_com_single_title') }}</div>
              <div class="item-input-title mt-40" v-html="$t('numsearch_com_single_desc')"></div>
              <input
                class="item-input form__input mt-10"
                type="text"
                v-model.trim="singleNumberForm.number"
                v-bind:class="{ 'has-loading': !status.searchSingle }"
                v-on:focus="singleNumberForm.numberError = null"
                v-on:keyup.enter="getNumberResult"
              />
              <a
                class="long-button single-btn mt-40"
                :disabled="!singleNumberForm.number || !status.searchSingle"
                v-on:click="getNumberResult"
                >{{ $t('numsearch_com_single_button') }}</a
              >
              <div class="form__api__error mt-20" v-if="singleNumberForm.numberError">
                {{ singleNumberForm.numberError }}
              </div>
            </div>
            <div
              class="search__right__block ml-40"
              v-on:click="status.searchSingle = false"
              v-bind:class="{ active: !status.searchSingle }"
            >
              <div class="item-title mt-70">{{ $t('numsearch_com_multi_title') }}</div>
              <div v-if="!batchNumberForm.file">
                <div class="item-multip-title mt-70" v-html="$t('numsearch_com_multi_desc')"></div>
                <label for="file-upload" class="custom-file-upload long-button mt-60" :disabled="status.searchSingle">{{
                  $t('numsearch_com_multi_find')
                }}</label>
                <input id="file-upload" type="file" accept=".csv" v-on:change="uploadFile" />
              </div>
              <div v-else>
                <div class="item-multip-filetitle mt-40">{{ $t('numsearch_com_multi_file_name') }}</div>
                <div class="item-multip-filename mt-10">{{ batchNumberForm.file.name }}</div>
                <div class="item-multip-btns mt-40">
                  <label for="file-upload" class="second-button custom-file-upload" :disabled="status.searchSingle">{{
                    $t('numsearch_com_multi_find')
                  }}</label>
                  <input id="file-upload" type="file" accept=".csv" v-on:change="uploadFile" />
                  <a
                    class="main-button custom-file-upload ml-20"
                    :disabled="status.searchSingle"
                    v-on:click="parseFile"
                    >{{ $t('numsearch_com_multi_button') }}</a
                  >
                </div>
                <div
                  class="form__api__error mt-20"
                  v-if="batchNumberForm.numberError"
                  v-html="batchNumberForm.numberError"
                ></div>
              </div>
              <div class="item-title mt-40" v-if="status.multipError">{{ status.multipError }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'LicenseQuotaAlert'">
        <div class="dialog__title">{{ $t('numsearch_error_400_18_title') }}</div>
        <div class="dialog__desc mt-20">
          {{
            $t('numsearch_error_400_18_desc', {
              remail_quota: $store.state.license.remain_quota,
            })
          }}
        </div>
        <a class="long-button mt-60" v-on:click="dismissDialog">{{ $t('numsearch_error_400_18_button') }}</a>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'showExitAlert'">
        <div class="dialog__title" v-html="$t('numsearch_result_pageback_title')"></div>
        <div class="dialog__desc mt-20" v-html="$t('numsearch_result_pageback_desc')"></div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialog">{{
            $t('numsearch_result_pageback_cancel')
          }}</a>
          <a class="main-button dialog__button ml-20" :href="exitNext">{{ $t('numsearch_result_pageback_button') }}</a>
        </div>
      </div>
    </div>
    <div class="full-loading" v-if="status.isFullLoading">
      <full-page-loading :message="$t('numsearch_200_on_search_warning_title')"></full-page-loading>
    </div>
    <div id="canvas-img"></div>
  </div>
</template>

<script>
import sha256 from 'js-sha256';
import Papa from 'papaparse';

import { apiCheckSearchApiStatus, apiCheckUserStatus, apiLicenseStatus, apiSearch, apiSearchBatch } from '../api.js';
import { IDENTITY_REPORT } from '../identity_report.js';
import FullPageMessage from './partials/FullPageMessage.vue';
import FullPageLoading from './partials/FullPageLoading.vue';
import Header from './base/header.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'Home',
  props: {
    msg: String,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      headerTitle: this.$i18n.t('side_bar_menu_num_search'),
      licenseData: {
        dataset: this.$store.state.license.dataset,
        scenario: this.$store.state.license.scenario[0],
        displayCreditRisk: this.$store.state.license.display_credit_risk,
      },
      singleNumberForm: {
        numberError: false,
        number: null,
      },
      batchNumberForm: {
        numberError: false,
        numberErrorIndex: null,
        file: null,
        numberList: [],
        resultData: [],
        numberSha256List: [],
        numberSha256MappingList: {},
      },
      status: {
        searchSingle: true,
        isFullLoading: false,
        systemMaintenance: false,
        isValid: false,
      },
      form: {
        number: null,
        multipNumber: [],
        invalidNumber: null,
      },
      dialog: {
        type: null,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
      download: {
        href: null,
        filename: null,
      },
      identityReportFactorsCategory: IDENTITY_REPORT.FACTORS_CATEGORY,
    };
  },
  components: {
    'app-header': Header,
    'app-sidebar': Sidebar,
    'full-page-loading': FullPageLoading,
    'full-page-message': FullPageMessage,
  },
  watch: {
    'batchNumberForm.resultData': {
      handler(value) {
        if (value.length > 0) {
          this.headerTitle = this.$i18n.t('numsearch_result_multi_page');
        } else {
          this.headerTitle = this.$i18n.t('side_bar_menu_num_search');
        }
      },
      immediate: true,
    },
  },
  created() {
    const $ = this;

    if (process.env.VUE_APP_VERSION === 'offline') {
      $.status.isValid = true;
      $.updateRemainLicenseQuota();
      $.updateLicenseStatus();
      $.updateSystemVersion();
    } else {
      apiCheckUserStatus().then(function (response) {
        const res = response.data;

        if (res.msg === 'OK') {
          apiCheckSearchApiStatus()
            .then(function (response) {
              const res = response.data;

              if (res.msg === 'SYSTEM_MAINTENANCE') {
                $.status.systemMaintenance = true;
              } else {
                $.status.isValid = true;
              }
            })
            .catch(function (error) {
              $.status.isFullLoading = false;
              $.singleNumberForm.numberError = Object.getOwnPropertyDescriptor(error, 'message').value;
            });

          $.updateRemainLicenseQuota();
          $.updateLicenseStatus();
        }
      });
    }

    document.addEventListener('beforeunload', $.showExitAlert);
  },
  filters: {
    roundToInt: function (value) {
      return Math.round(value);
    },
  },
  beforeRouteLeave: function (to, from, next) {
    if (this.batchNumberForm.resultData.length > 0 && to.path !== '/user/login') {
      const $ = this;
      $.exitNext = this.pathJoin(process.env.VUE_APP_ROUTER_BASE, to.path);
      $.dialog.type = 'showExitAlert';
    } else {
      next();
    }
  },
  methods: {
    getIdentityReportSortedFactors: function () {
      const factors = this.identityReportFactorsCategory[this.licenseData.scenario];
      let sortedFactors = [];
      for (const [factor, category] of Object.entries(factors)) {
        if (category !== 'credit_risk') sortedFactors.push(`${category}/${factor}`);
      }
      sortedFactors.sort(this.sortingIdentityReportFactors).forEach((factor, index) => {
        sortedFactors[index] = factor.split('/')[1];
      });
      return sortedFactors;
    },
    getNumberResult: function () {
      const $ = this;
      const number = $.singleNumberForm.number;
      $.status.isFullLoading = true;
      $.singleNumberForm.numberError = null;

      if ($.checkNumberFormat(number)) {
        $.status.isFullLoading = false;
        $.singleNumberForm.numberError = $.$i18n.t('numsearch_client_error_format_single');
      } else {
        apiLicenseStatus()
          .then(function (response) {
            const licenseData = response.data;
            $.linkValid = true;

            let scenario = [];
            if ('data_args' in licenseData) {
              for (const [, dimVals] of Object.entries(licenseData.data_args)) {
                for (const [key, value] of Object.entries(dimVals)) {
                  if (key === 'scenario') {
                    scenario.push(value);
                  }
                  if (key === 'display_credit_risk') {
                    licenseData.display_credit_risk = value;
                  }
                }
              }
            }
            licenseData.scenario = scenario;
            $.$store.commit('setLicenseData', { licenseData });

            if ($.$store.state.license.unlimited_quota || $.$store.state.license.remain_quota >= 1) {
              $.$router.push(`/search/single/${$.singleNumberForm.number}/`);
            } else {
              $.dialog.type = 'LicenseQuotaAlert';
            }

            $.status.isFullLoading = false;
          })
          .catch(function (error) {
            $.status.isFullLoading = false;
            $.singleNumberForm.numberError = Object.getOwnPropertyDescriptor(error, 'message').value;
          });
      }
    },
    uploadFile: function (e) {
      const $ = this;

      $.batchNumberForm.numberList = [];
      $.batchNumberForm.numberSha256List = [];
      $.batchNumberForm.numberSha256MappingList = {};
      $.batchNumberForm.numberErrorIndex = null;
      $.batchNumberForm.file = e.target.files[0];
    },
    parseFile: function () {
      const $ = this;
      const file = $.batchNumberForm.file;
      $.status.isFullLoading = true;

      apiLicenseStatus()
        .then(function (response) {
          let licenseData = response.data;

          $.linkValid = true;
          let scenario = [];
          if ('data_args' in licenseData) {
            for (const [, dimVals] of Object.entries(licenseData.data_args)) {
              for (const [key, value] of Object.entries(dimVals)) {
                if (key === 'scenario') {
                  scenario.push(value);
                }
                if (key === 'display_credit_risk') {
                  licenseData.display_credit_risk = value;
                }
              }
            }
          }
          licenseData.scenario = scenario;
          $.$store.commit('setLicenseData', { licenseData });

          Papa.LocalChunkSize = 1;
          Papa.RemoteChunkSize = 1;

          Papa.parse(file, {
            complete: function (results) {
              const numberList = results.data;

              if ($.$store.state.license.unlimited_quota || $.$store.state.license.remain_quota >= numberList.length) {
                if (numberList.length <= 1000) {
                  for (var i = 0; i < results.data.length; i++) {
                    const number = results.data[i][0];

                    if ($.checkNumberFormat(number)) {
                      $.batchNumberForm.numberErrorIndex = i + 1;
                      $.batchNumberForm.numberError = $.$i18n.t('numsearch_client_error_format_multi', {
                        fail_row: $.batchNumberForm.numberErrorIndex,
                      });
                      $.status.isFullLoading = false;
                      break;
                    } else {
                      $.batchNumberForm.numberList.push(number);

                      if ($.version === 'offline') {
                        const numberSha = sha256(number);
                        $.batchNumberForm.numberSha256List.push(numberSha);
                        $.batchNumberForm.numberSha256MappingList[numberSha] = number;
                      }
                    }
                  }

                  if (!$.batchNumberForm.numberErrorIndex) {
                    $.status.isFullLoading = false;
                    $.postMultipNumbers();
                  }
                } else {
                  $.status.isFullLoading = false;
                  $.batchNumberForm.numberError = $.$i18n.t('numsearch_client_error_exceed_batch_amount');
                }
              } else {
                $.status.isFullLoading = false;
                $.dialog.type = 'LicenseQuotaAlert';
              }
            },
          });
        })
        .catch(function (error) {
          $.linkInvalid = true;
          $.link_error = error;
        });
    },
    async postMultipNumbers() {
      const $ = this;
      const searchNumberList =
        $.version === 'offline' ? $.batchNumberForm.numberSha256List : $.batchNumberForm.numberList;

      const chunkSize = 100;
      const groupedArr = $.createGroupedArray(searchNumberList, chunkSize);
      let userLang = $.$store.state.user.lang;

      $.status.isFullLoading = $.batchNumberForm.resultData.length === 0 ? true : false;

      for (var i = 0; i < groupedArr.length; i++) {
        // ##### Bank and Rental #####
        if (this.licenseData.dataset === 'bank' || this.licenseData.dataset === 'rental') {
          await apiSearchBatch({
            number_list: groupedArr[i],
            locale: userLang,
          })
            .then(function (response) {
              const res = response.data;

              if ($.version === 'offline') {
                let originRes = [];
                res.forEach((result) => {
                  result.num = $.batchNumberForm.numberSha256MappingList[result.num];
                  originRes.push(result);
                });
                $.batchNumberForm.resultData = $.batchNumberForm.resultData.concat(originRes);
              } else {
                $.batchNumberForm.resultData = $.batchNumberForm.resultData.concat(res);
              }

              $.updateRemainLicenseQuota();

              if (i === groupedArr.length - 1) {
                $.genDownloadData();
              }

              $.status.isFullLoading = $.batchNumberForm.resultData.length === 0 ? true : false;
            })
            .catch(function (error) {
              $.status.isFullLoading = false;
              $.singleNumberForm.numberError = Object.getOwnPropertyDescriptor(error, 'message').value;
            });
          // ##### Bank and Rental #####
          // ##### Identity Report #####
        } else if (this.licenseData.dataset === 'identity_report') {
          await apiSearch({
            numbers: groupedArr[i],
            locale: userLang,
          })
            .then((response) => {
              const res = response.data;

              if ($.version === 'offline') {
                let originRes = [];
                res.forEach((result) => {
                  result.num = $.batchNumberForm.numberSha256MappingList[result.num];
                  originRes.push(result);
                });
                $.batchNumberForm.resultData = $.batchNumberForm.resultData.concat(originRes);
              } else {
                $.batchNumberForm.resultData = $.batchNumberForm.resultData.concat(res);
              }
              $.updateRemainLicenseQuota();

              if (i === groupedArr.length - 1) {
                $.genDownloadData();
              }

              $.status.isFullLoading = $.batchNumberForm.resultData.length === 0 ? true : false;
            })
            .catch((error) => {
              $.status.isFullLoading = false;
              $.singleNumberForm.numberError = Object.getOwnPropertyDescriptor(error, 'message').value;
            });
        }
        // ##### Identity Report #####
        else {
          alert('invalid dataset');
        }
      }
    },
    createGroupedArray: function (arr, chunkSize) {
      var groups = [];
      for (var i = 0; i < arr.length; i += chunkSize) {
        groups.push(arr.slice(i, i + chunkSize));
      }
      return groups;
    },
    sortObject: function (obj) {
      return Object.keys(obj)
        .sort()
        .reduce(function (result, key) {
          result[key] = obj[key];
          return result;
        }, {});
    },
    genDownloadData: function () {
      const $ = this;
      $.status.isFullLoading = true;
      let data = $.batchNumberForm.resultData;
      let csvRows = [];

      // ##### Identity Report #####
      if ($.licenseData.dataset === 'identity_report') {
        const sortedFactor = $.getIdentityReportSortedFactors();
        let identityReportResults = [];

        data.forEach((num_data) => {
          let sortedResult = new Map();

          sortedResult.set('num', num_data.num);
          sortedFactor.forEach((factor) => {
            if (factor in num_data) {
              sortedResult.set(factor, num_data[factor]);
            }
          });
          if ($.licenseData.displayCreditRisk !== 'none') {
            if ('rank' in num_data) {
              sortedResult.set('rank', num_data.rank);
            } else {
              sortedResult.set('rank', '');
            }
          }
          identityReportResults.push(sortedResult);
        });

        identityReportResults.forEach((result, index) => {
          if (index === 0) {
            let header = [];
            result.forEach((_, key) => {
              header.push($.$i18n.t(`identity_report_${this.licenseData.scenario}_${key}_name`));
            });
            csvRows.push(header.join(','));
          }
          csvRows.push([...result.values()].join(','));
        });
      }
      // ##### Identity Report #####

      // ##### Bank and Rental #####
      if (this.licenseData.dataset === 'bank' || this.licenseData.dataset === 'rental') {
        let totalData = [];

        totalData.push(Object.keys(data[0]));

        for (var i = 0; i <= data.length - 1; ++i) {
          var numVals = new Array();

          for (var key in data[i]) {
            numVals.push(`"${data[i][key]}"`);
          }
          totalData.push(numVals);
        }

        const numIndex = totalData[0].indexOf('num');

        for (var i = 0; i <= totalData.length - 1; ++i) {
          const number = totalData[i][numIndex];
          totalData[i].splice(numIndex, 1);
          totalData[i].unshift(number);
        }

        for (var hi = 0, hl = totalData[0].length; hi < hl; ++hi) {
          totalData[0][hi] = $.$i18n.t(`numsearch_result_single_${totalData[0][hi]}`);
        }

        for (var i = 0, l = totalData.length; i < l; ++i) {
          csvRows.push(totalData[i].join(','));
        }
      }

      var csvString = csvRows.join('\r\n');

      // Add '\uFEFF' for windows excel.
      // Please refer https://www.zhuwenlong.com/blog/article/599c2a21f21d525b8e227638
      $.download.href = 'data:application/csv;charset=utf-8,\uFEFF' + encodeURIComponent(csvString);
      if (this.licenseData.dataset === 'bank' || this.licenseData.dataset === 'rental') {
        $.download.filename = 'verify_report.csv';
      } else if (this.licenseData.dataset === 'identity_report') {
        $.download.filename = 'identity_reports.csv';
      }
      $.status.isFullLoading = false;
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #eaeaea;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.main-item {
  flex-basis: 100%;
  display: flex;
}

.search__left__block.active,
.search__right__block.active {
  border: 10px solid #640061;
}

.search__block {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__left__block,
.search__right__block {
  box-sizing: border-box;
  flex-basis: calc((100% - 50px) / 2);
  max-width: 400px;
  height: 400px;
  background-color: #ffffff;
  padding: 0 40px;
  text-align: center;
}

.multiple__search__result__block {
  display: flex;
  flex-basis: 100%;
  max-width: 560px;
  flex-direction: column;
}

.action__block {
  position: relative;
  height: 40px;
  box-sizing: border-box;
}

.result__block {
  box-sizing: border-box;
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
}

.bg__white {
  background-color: #ffffff;
}

.search__progressbar {
  line-height: 40px;
}

.download__btn {
  position: absolute;
  top: 0;
  right: 0;
  flex-basis: 50%;
}

.download__btn:disabled,
.download__btn[disabled] {
  opacity: 0.3;
  pointer-events: none;
}

.item-title {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.item-input-title {
  width: 100%;
  font-size: 14px;
  color: #000000;
  text-align: left;
}

.item-multip-filetitle {
  font-size: 14px;
  text-align: left;
  color: #000000;
}

.item-multip-title {
  width: 100%;
  font-size: 14px;
  color: #000000;
}

.item-multip-filename {
  font-size: 14px;
  line-height: 40px;
  color: #000000;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  text-align: left;
  padding: 0 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item-multip-btns {
  display: flex;
  justify-content: center;
}

input[type='file'] {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
}

.result__block__list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.result__block__list-item {
  flex-basis: 50%;
  padding: 0 20px;
}

.result__block__head {
  flex-basis: 100%;
  height: 30px;
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.result__block__content {
  flex-basis: 100%;
  min-height: 30px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

.single-btn {
  width: 200px;
}

.maintenace-content,
.unsupport-item {
  flex-direction: column;
}

.maintenace-item,
.unsupport-item {
  width: 100%;
  text-align: center;
}

.maintenace__img {
  width: 440px;
}

.maintenace__title,
.unsupport__title {
  font-size: 36px;
  font-weight: 600;
  color: #000000;
}

.maintenace__desc {
  font-size: 14px;
  color: #000000;
}
</style>
