<template>
  <div id="app" class="site-container">
    <router-view :isLogin="isOnLoginPage()"></router-view>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'LogoutConfirm'">
        <div class="dialog__title">{{ $t('logout_confirm_dialog') }}</div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialog">{{
            $t('logout_confirm_dialog_cancel')
          }}</a>
          <a class="main-button dialog__button ml-20" v-on:click="logout()">{{
            $t('logout_confirm_dialog_confirm')
          }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'SessionCountdown5mins'">
        <div class="dialog__title">{{ $t('session_timeout_countdown_5min_title') }}</div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialogAndLogout">{{
            $t('session_timeout_countdown_5min_button_logout')
          }}</a>
          <a class="main-button dialog__button ml-20" v-on:click="dismissDialog">{{
            $t('session_timeout_countdown_5min_button_continue')
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiCheckUserStatus } from './api.js';

export default {
  name: 'app',
  data() {
    return {
      dialog: {
        type: null,
      },
      message: {
        type: null,
        text: null,
      },
      isActive: false,
      timerCount: 60 * 30,
      TimerIdforIdle: null,
    };
  },
  computed: {
    timerCountAndhasPermission() {
      return `${this.timerCount}|${this.isOnLoginPage()}|${this.isActive}`;
    },
  },
  watch: {
    isActive: {
      handler(value) {
        this.TimerIdforIdle = setTimeout(() => {
          this.isActive = false;
        }, 10000);
      },
      immediate: true,
    },
    timerCount: {
      handler(value) {
        if (value === 60 * 10) {
          this.dialog.type = 'SessionCountdown5mins';
        } else if (value <= 0) {
          this.dialog.type = null;
          this.$store.commit('setForceLogout', true);
          this.$router.push('/user/logout');
        }
      },
      immediate: true,
    },
    timerCountAndhasPermission: {
      handler(value) {
        const timeCount = value.split('|')[0];
        const hasPermission = value.split('|')[1] === 'true';
        const isActive = value.split('|')[2] === 'true';
        if (timeCount > 0 && hasPermission && !isActive) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.timerCount = 60 * 30;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    isOnLoginPage: function () {
      return this.$route.meta.requiresAuth;
    },
    checkRemainSession(time) {
      const $ = this;
      $.dialog.type = 'SessionCountdown5mins';
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    dismissDialogAndLogout: function () {
      const $ = this;

      $.dialog.type = null;
      $.$router.push('user/logout/');
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
  },
  created() {
    const $ = this;

    window.onmousedown = function () {
      $.isActive = true;
    };
    window.onmousemove = function () {
      $.isActive = true;
    };
  },
};
</script>

<style>
@font-face {
  font-family: NotoSans;
  src: url('assets/fonts/NotoSansCJKtc-Regular.otf') format('opentype');
}

@font-face {
  font-family: TitilliumWeb;
  src: url('assets/fonts/TitilliumWeb-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'witcherfin';
  src: url('assets/fonts/witcherfin.ttf?y6y7uh') format('truetype'),
    url('assets/fonts/witcherfin.woff?y6y7uh') format('woff'),
    url('assets/fonts/witcherfin.svg?y6y7uh#witcherfin') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'witcherfin' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back:before {
  content: '\e900';
}
.icon-close:before {
  content: '\e901';
}
.icon-delete:before {
  content: '\e902';
}
.icon-check:before {
  content: '\e903';
}
.icon-arrow-up:before {
  content: '\e904';
}
.icon-arrow-down:before {
  content: '\e905';
}
.icon-number-1:before {
  content: '\e90a';
}
.icon-number-2:before {
  content: '\e90b';
}
.icon-number-3:before {
  content: '\e90c';
}
.icon-number-4:before {
  content: '\e90d';
}

html {
  font-family: 'TitilliumWeb', 'NotoSans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  height: auto;
}

.content {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
}

.content.with-header {
  height: calc(100vh - 60px);
  top: 80px;
}

::-webkit-scrollbar {
  display: none;
}

.witcher__logo {
  width: 120px;
}

.witcher__header__logo {
  width: 150px;
}

.long-button {
  box-sizing: border-box;
  display: block;
  width: fit-content;
  min-width: 200px;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  background-color: #640061;
  cursor: pointer;
  margin: 0 auto;
  text-decoration: none;
  padding: 0 10px;
}

.main-button {
  display: block;
  width: fit-content;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  background-color: #640061;
  cursor: pointer;
  text-decoration: none;
  padding: 0 20px;
  overflow: hidden;
}

.main-button:hover,
.long-button:hover {
  background-color: #460044;
}

.long-button:disabled,
.main-button:disabled,
.long-button[disabled],
.main-button[disabled] {
  opacity: 0.3;
  background-color: #640061;
  pointer-events: none;
}

.second-button {
  box-sizing: border-box;
  display: block;
  height: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #640061;
  line-height: 40px;
  background-color: #ffffff;
  border: solid 2px #640061;
  padding: 0 20px;
  cursor: pointer;
  text-decoration: none;
}

.second-button:hover {
  background-color: rgba(100, 0, 97, 0.1);
}

.second-button:disabled,
.second-button[disabled] {
  opacity: 0.3;
  pointer-events: none;
}

.small-button {
  box-sizing: border-box;
  display: block;
  height: 30px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  line-height: 30px;
  background-color: #640061;
  padding: 0 20px;
  cursor: pointer;
}

.small-button:hover {
  background-color: #640061;
}

.round-button {
  display: inline-block;
  width: fit-content;
  min-width: 40px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  color: #000000;
  line-height: 30px;
  border: 2px solid #640061;
  border-radius: 9999px;
  cursor: pointer;
  text-decoration: none;
  padding: 0 20px;
}

.round-button--active {
  background-color: #640061;
  color: #ffffff;
}

.page__title {
  font-size: 36px;
  font-weight: 600;
}

.form__title {
  width: 100%;
  max-width: 400px;
  font-size: 14px;
  text-align: left;
}

.form__input {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  height: 40px;
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  padding: 0 20px;
  font-size: 14px;
  word-break: normal;
  word-wrap: normal;
}

.form__link {
  font-size: 14px;
  color: #640061;
}

.form__input:disabled,
.form__input[disabled] {
  color: rgba(0, 0, 0, 0.3);
}

.form__error {
  width: 100%;
  max-width: 400px;
  font-size: 8px;
  color: #870136;
  text-align: right;
}

.form__success {
  font-size: 18px;
  color: #640061;
}

.form__api__error {
  font-size: 12px;
  color: #870136;
}

.form__hint {
  width: 100%;
  max-width: 400px;
  font-size: 12px;
  color: #aeaeae;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.form__input.has-error {
  border: solid 1px #870136;
}

.form__input.has-loading {
  opacity: 0.3;
}

.margin-center {
  margin: 0 auto;
}

.mt-center {
  margin-left: auto;
  margin-right: auto;
}

.mt-150 {
  margin-top: 150px;
}

.mt-96 {
  margin-top: 96px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-56 {
  margin-top: 56px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-60 {
  margin-right: 60px;
}

.pr-20 {
  padding-right: 20px;
}

.mt-3p {
  margin-top: 3vh;
}

.mt-5p {
  margin-top: 5vh;
}

.mt-6p {
  margin-top: 6vh;
}

.mt-7p {
  margin-top: 7vh;
}

.mt-9p {
  margin-top: 9vh;
}

.p-rl-5 {
  padding: 0 5px;
}
</style>
