<template>
  <div class="full-loading-dialog">
    <div class="loading__block">
      <div class="loading">
        <div class="loading__square"></div>
        <div class="loading__square"></div>
        <div class="loading__square"></div>
        <div class="loading__square"></div>
        <div class="loading__square"></div>
        <div class="loading__square"></div>
        <div class="loading__square"></div>
      </div>
      <div class="loading__desc">{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullPageLoading',
  props: {
    message: String,
  },
};
</script>

<style scoped>
.loading__block {
  position: relative;
  width: 80vw;
  left: 20vw;
}

.loading__desc {
  position: relative;
  top: calc((100vh - 80px) / 2);
  width: 100%;
  color: #ffffff;
  text-align: center;
}

.full-loading-dialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }

  10.5% {
    left: 0;
    top: 0;
  }
  12.5% {
    left: 32px;
    top: 0;
  }

  23% {
    left: 32px;
    top: 0;
  }
  25% {
    left: calc(32px * 2);
    top: 0;
  }

  35.5% {
    left: calc(32px * 2);
    top: 0;
  }
  37.5% {
    left: calc(32px * 2);
    top: 32px;
  }

  48% {
    left: calc(32px * 2);
    top: 32px;
  }
  50% {
    left: 32px;
    top: 32px;
  }

  60.5% {
    left: 32px;
    top: 32px;
  }
  62.5% {
    left: 32px;
    top: calc(32px * 2);
  }

  73% {
    left: 32px;
    top: calc(32px * 2);
  }
  75% {
    left: 0;
    top: calc(32px * 2);
  }

  85.5% {
    left: 0;
    top: calc(32px * 2);
  }
  87.5% {
    left: 0;
    top: 32px;
  }

  98% {
    left: 0;
    top: 32px;
  }
  100% {
    left: 0;
    top: 0;
  }
}

@keyframes hue-rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.loading {
  position: relative;
  top: calc((100vh - 136px) / 2);
  left: calc((80vw - 96px) / 2);
  width: calc(32px * 3);
  height: calc(32px * 3);
  transform: rotate(45deg);
  animation: hue-rotate 10s linear infinite both;
}

.loading__square {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(32px - 4px);
  height: calc(32px - 4px);
  margin: 2px;
  border-radius: 2px;
  background: #9f3785;
  background-image: linear-gradient(45deg, #f1e2ed 40%, #9f3785 60%);
  background-image: -moz-linear-gradient(#f1e2ed, #9f3785);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: square-animation 10s ease-in-out infinite both;
}

.loading__square:nth-of-type(0) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.loading__square:nth-of-type(1) {
  -webkit-animation-delay: -1.4285714286s;
  animation-delay: -1.4285714286s;
}
.loading__square:nth-of-type(2) {
  -webkit-animation-delay: -2.8571428571s;
  animation-delay: -2.8571428571s;
}
.loading__square:nth-of-type(3) {
  -webkit-animation-delay: -4.2857142857s;
  animation-delay: -4.2857142857s;
}
.loading__square:nth-of-type(4) {
  -webkit-animation-delay: -5.7142857143s;
  animation-delay: -5.7142857143s;
}
.loading__square:nth-of-type(5) {
  -webkit-animation-delay: -7.1428571429s;
  animation-delay: -7.1428571429s;
}
.loading__square:nth-of-type(6) {
  -webkit-animation-delay: -8.5714285714s;
  animation-delay: -8.5714285714s;
}
.loading__square:nth-of-type(7) {
  -webkit-animation-delay: -10s;
  animation-delay: -10s;
}
</style>
