<template>
  <div class="site-main">
    <app-header :title="$t('side_bar_menu_account_info')"></app-header>
    <div class="main-block">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div class="main-content">
        <div class="main-item">
          <div class="item__left">
            <div class="item__left--info">
              <div class="item-title">{{ $t("change_info_companyid") }}</div>
              <div class="item-info mt-5">{{ $store.state.user.brand_id }}</div>
              <hr class="item_hr mt-20">
              <div class="item-title">{{ $t("change_info_uuid") }}</div>
              <div class="item-info mt-5" v-if="$store.state.enableMaskUUID">{{ $store.state.user.maskedUUID }}</div>
              <div class="item-info mt-5" v-else>{{ $store.state.user.uuid }}</div>
              <hr class="item_hr mt-20">
              <div class="main-button button--credentials mt-15" :disabled="$store.state.user.role !== 'admin'"
                v-on:click="getApiToken">{{ $t("tryapi_api_credentials_button") }}</div>
              <hr class="item_hr mt-20">
              <div class="item-title mt-20">{{ $t("change_info_email") }}</div>
              <div class="item-info mt-5">{{ $store.state.user.email }}</div>
              <hr class="item_hr mt-20">
              <div class="item-title mt-20">{{ $t("change_info_role") }}</div>
              <div class="item-info mt-5" v-if="$store.state.user.role==='admin'">{{ $t("change_info_role_admin") }}
              </div>
              <div class="item-info mt-5" v-else>{{ $t("change_info_role_user") }}</div>
              <div v-if="!isUnlimitedQuota">
                <hr class="item_hr mt-20">
                <div class="item-title mt-20">{{ $t("change_info_credit_quota") }}</div>
                <div class="item-info mt-5">{{ $store.state.license.total_quota }} {{
                  $t("change_info_credit_quota_unit") }}</div>
                <div class="item-subinfo">{{ $store.state.license.end_time }} {{ $t("change_info_etime") }}</div>
                <div class="item-hint mt-15">
                  <span>
                    {{ $t('change_info_extend', { contactus_business_email: $t('contactus_business_email'),
                    }) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="item__left--mail system-mail__items mt-20" v-if="hasSendMailInAPI">
              <div class="system-mail__intro">
                <div class="system-mail__intro__title">{{ $t("change_info_email_setting") }}</div>
                <div class="system-mail__intro__desc mt-5">{{ $t("change_info_email_setting_desc") }}</div>
              </div>
              <div class="system-mail__item mt-16" v-if="!isUnlimitedQuota">
                <div class="system-mail__item__left">
                  <div class="item-title mt-20">{{ $t("change_info_email_setting_remain_quota") }}</div>
                  <div class="item-hint mt-5">{{ $t("change_info_email_setting_remain_quota_desc") }}</div>
                  <div class="item-info mt-15">{{ $t("change_info_quota_alert_level_desc", {"quota_alert_level":
                    $store.state.license.quota_alert_level }) }}</div>
                  <div class="small-button item__change__button mt-15" v-on:click="editQuotaAlertLevel">{{
                    $t("change_info_quota_alert_level_edit") }}</div>
                </div>
                <div class="system-mail__item__right">
                  <div class="item__switch">
                    <span class="switch__btn__wrap">
                      <input type="checkbox" id="input__switch__1" name="switch" class="switch__btn__blind"
                        @change="updateMailServiceStatus('remain_quota_notifications')"
                        v-model="mailForm.mailService.remain_quota_notifications.is_enabled"
                        value="remain_quota_notifications">
                      <label for="input__switch__1"><span class="switch__btn__txt"></span></label>
                    </span>
                  </div>
                  <div class="item__change__button small-button"
                    :disabled="!mailForm.mailService.remain_quota_notifications.is_enabled"
                    v-on:click="editMailServiceRecipients('remain_quota_notifications')">{{
                    $t("change_info_change_email_button") }}</div>
                </div>
              </div>
              <div class="system-mail__item system-mail__item--hr mt-20">
                <div class="system-mail__item__left">
                  <div class="item-title mt-20">{{ $t("change_info_email_setting_license_expire") }}</div>
                  <div class="item-hint mt-5">{{ $t("change_info_email_setting_license_expire_desc") }}</div>
                </div>
                <div class="system-mail__item__right">
                  <div class="item__switch">
                    <span class="switch__btn__wrap">
                      <input type="checkbox" id="input__switch__2" name="switch" class="switch__btn__blind"
                        @change="updateMailServiceStatus('license_expiration_reminder')"
                        v-model="mailForm.mailService.license_expiration_reminder.is_enabled"
                        value="license_expiration_reminder">
                      <label for="input__switch__2"><span class="switch__btn__txt"></span></label>
                    </span>
                  </div>
                  <div class="item__change__button small-button"
                    :disabled="!mailForm.mailService.license_expiration_reminder.is_enabled"
                    v-on:click="editMailServiceRecipients('license_expiration_reminder')">{{
                    $t("change_info_change_email_button") }}</div>
                </div>
              </div>
              <div class="system-mail__item system-mail__item--hr mt-20" v-if="version==='offline'">
                <div class="system-mail__item__left">
                  <div class="item-title mt-20">{{ $t("change_info_email_setting_not_enough_space") }}</div>
                  <div class="item-hint mt-5">{{ $t("change_info_email_setting_not_enough_space_desc") }}</div>
                </div>
                <div class="system-mail__item__right">
                  <div class="item__switch">
                    <span class="switch__btn__wrap">
                      <input type="checkbox" id="input__switch__3" name="switch" class="switch__btn__blind"
                        @change="updateMailServiceStatus('not_enough_space')"
                        v-model="mailForm.mailService.not_enough_space.is_enabled" value="not_enough_space">
                      <label for="input__switch__3"><span class="switch__btn__txt"></span></label>
                    </span>
                  </div>
                  <div class="item__change__button small-button"
                    :disabled="!mailForm.mailService.not_enough_space.is_enabled"
                    v-on:click="editMailServiceRecipients('not_enough_space')">{{ $t("change_info_change_email_button")
                    }}</div>
                </div>
              </div>
              <div class="system-mail__item system-mail__item--hr mt-20" v-if="hasTableUpdateInSidebar">
                <div class="system-mail__item__left">
                  <div class="item-title mt-20">{{ $t("change_info_email_setting_dataset_update_result") }}</div>
                  <div class="item-hint mt-5">{{ $t("change_info_email_setting_dataset_update_result_desc") }}</div>
                </div>
                <div class="system-mail__item__right">
                  <div class="item__switch">
                    <span class="switch__btn__wrap">
                      <input type="checkbox" id="input__switch__4" name="switch__4" class="switch__btn__blind"
                        @change="updateMailServiceStatus('table_update')"
                        v-model="mailForm.mailService.table_update.is_enabled" value="table_update">
                      <label for="input__switch__4"><span class="switch__btn__txt"></span></label>
                    </span>
                  </div>
                  <div class="item__change__button small-button"
                    :disabled="!mailForm.mailService.table_update.is_enabled"
                    v-on:click="editMailServiceRecipients('table_update')">{{ $t("change_info_change_email_button") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item__left--mail system-mail__items mt-20" v-if="hasSendMailInAPI && isReportValidDataset()">
              <div class="system-mail__item">
                <div class="system-mail__item__left">
                  <div class="item-title mt-20">{{ $t("change_info_email_setting_dataset_search_result") }}</div>
                  <div class="item-hint mt-5" v-html="$t('change_info_email_setting_dataset_search_result_desc')"></div>
                </div>
                <div class="system-mail__item__right">
                  <div class="item__switch">
                    <span class="switch__btn__wrap">
                      <input type="checkbox" id="input__switch__5" name="switch__5" class="switch__btn__blind"
                        @change="updateMailServiceStatus('query')" v-model="mailForm.mailService.query.is_enabled"
                        value="query">
                      <label for="input__switch__5"><span class="switch__btn__txt"></span></label>
                    </span>
                  </div>
                  <div class="item__change__button small-button" :disabled="!mailForm.mailService.query.is_enabled"
                    v-on:click="editMailServiceRecipients('query')">{{ $t("change_info_change_email_button") }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item__right ml-40">
            <div class="item-title">{{ $t("change_user_name_col") }}</div>
            <div class="item-info mt-5">{{ $store.state.user.name }}</div>
            <div class="main-button mt-15" v-on:click="editName">{{ $t("change_pwd_button") }}</div>
            <hr class="item_hr mt-20">
            <div class="item-title mt-15">{{ $t("change_pwd_col") }}</div>
            <div class="main-button mt-15" v-on:click="postForgetPassword">{{ $t("change_pwd_button") }}</div>
            <div class="main-item mt-6">
              <div v-if="message.pwdApiError" class="form__api__error mt-center">{{ message.pwdApiError }}</div>
            </div>
            <div class="item-hint mt-15" v-if="version==='online'" v-html="$t('change_pwd_desc')"></div>
            <div class="item-hint mt-15" v-else v-html="$t('change_pwd_desc_not_mail')"></div>
            <hr class="item_hr mt-20">
            <div class="item-title mt-20">{{ $t("change_language") }}</div>
            <div class="item-info lang-item mt-10">
              <lang-selector></lang-selector>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type=='ConfirmMSIsEnabledChange'"
      v-on:click="hidePanelAndCancelMSStatusChange">
      <div class="dialog" id="dialog-main">
        <div class="dialog__title">{{ mailForm.confirmDialog.title }}</div>
        <div class="dialog__desc">{{ mailForm.confirmDialog.desc }}</div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="cancelMSStatusChange(mailForm.confirmDialog.type)">{{
            $t("change_info_email_setting_dialog_disable_no") }}</a>
          <a class="main-button dialog__button ml-20" v-on:click="postMSStatus(mailForm.confirmDialog.type)">{{
            $t("change_info_email_setting_dialog_disable_yes") }}</a>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-else-if="dialog.type=='getApiToken'" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main"
        v-if="version === 'online' && $store.state.user.role === 'admin' && status.tokenAuth">
        <div class="dialog__title">{{ $t("tryapi_api_credentials_result_title") }}</div>
        <div class="dialog__desc mt-20">{{ $t("tryapi_api_credentials_appid") }}</div>
        <div class="dialog__cred__desc mt-5">{{ message.licesneID }}</div>
        <div class="dialog__desc mt-20">{{ $t("tryapi_api_credentials_apikey") }}</div>
        <div class="dialog__cred__desc mt-5" v-if="$store.state.enableMaskAppKey">{{ message.maskedLicenseToken }}</div>
        <div class="dialog__cred__desc mt-5" v-else>{{ message.licenseToken }}</div>
        <div class="main-item mt-60">
          <a class="long-button" v-on:click="dismissDialog">{{ $t("tryapi_api_credentials_view_admin_button") }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main"
        v-else-if="version === 'online' && $store.state.user.role === 'admin' && !status.tokenAuth">
        <div class="dialog__title">{{ $t("tryapi_api_credentials_view_admin_title") }}</div>
        <div class="main-item mt-70">
          <input class="form__input dialog__input" type="password" v-bind:class="{ 'has-error': status.mailError }"
            v-model.trim="form.password" v-on:focus="message.apiError = null;" v-on:keyup.enter="checkUserPassword">
        </div>
        <div class="main-item mt-96">
          <a class="long-button" :disabled='!getToeknIsComplete' v-on:click="checkUserPassword">{{
            $t("tryapi_api_credentials_view_admin_button") }}</a>
        </div>
        <div class="main-item mt-20" v-if="message.apiError">
          <div class="form__api__error mt-center">{{ message.apiError }}</div>
        </div>
        <div class="dialog__cancel icon-close" v-on:click="dismissDialog"></div>
      </div>
      <div class="dialog" id="dialog-main" v-else-if="version === 'offline' && $store.state.user.role === 'admin'">
        <div class="dialog__title">{{ $t("tryapi_api_credentials_view_admin_user_title") }}</div>
        <div class="dialog__desc mt-20">{{ $t("tryapi_api_credentials_view_admin_user_desc") }}</div>
        <div class="main-item mt-60">
          <a class="long-button" v-on:click="dismissDialog">{{ $t("tryapi_api_credentials_view_admin_user_button")
            }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-else>
        <div class="dialog__title">{{ $t("tryapi_api_credentials_view_user_title") }}</div>
        <div class="dialog__desc mt-20">{{ $t("tryapi_api_credentials_view_user_desc") }}</div>
      </div>
    </div>
    <div class="dialog-item" v-else-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type=='UserChangeName'">
        <div class="dialog__title">{{ $t("change_user_name_col") }}</div>
        <div class="main-item mt-10">
          <input class="form__input dialog__input" type="text" v-bind:class="{ 'has-loading': status.isLoading }"
            v-model.trim="form.userName" maxlength="50" v-on:focus="message.apiError = null;">
        </div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialog">{{ $t("change_user_name_cancel") }}</a>
          <a class="main-button dialog__button ml-20" :disabled='!changeNameIsComplete' v-on:click="postChangeName">{{
            $t("change_user_name_button") }}</a>
        </div>
        <div class="main-item mt-20">
          <div v-if="message.apiError" class="form__api__error mt-center">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type=='UserChangeQuotaAlertLevel'">
        <div class="dialog__title">{{ $t("change_info_quota_alert_level_title2") }}</div>
        <div class="dialog__desc">{{ $t("change_info_quota_alert_level_desc3") }}</div>
        <div class="main-item mt-10">
          <input class="form__input dialog__input" type="number" v-bind:class="{ 'has-loading': status.isLoading }"
            v-model.trim="form.quotaAlertLevel" maxlength="50" v-on:focus="message.apiError = null;">
        </div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialog">{{
            $t("change_info_quota_alert_level_button_cancel") }}</a>
          <a class="main-button dialog__button ml-20" :disabled='!changeQALIsComplete'
            v-on:click="postQuotaAlertLevel">{{ $t("change_info_quota_alert_level_button_submit") }}</a>
        </div>
        <div class="main-item mt-20">
          <div v-if="message.apiError" class="form__api__error mt-center">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type=='UserChangeQuotaAlertLevelNotAdmin'">
        <div class="dialog__title">{{ $t("change_info_quota_alert_level_reject_msg_title") }}</div>
        <div class="dialog__desc">{{ $t("change_info_quota_alert_level_reject_msg_desc") }}</div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type=='UserChangePwd'">
        <div class="dialog__title">{{ $t("change_pwd_200_title") }}</div>
        <div class="dialog__desc mt-40">{{ $t("change_pwd_200_desc") }}</div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type=='OfflineUserChangePwd'">
        <div class="dialog__title">{{ $t("reset_pwd_page_title") }}</div>
        <div class="dialog__form">
          <div class="main-item mt-30">
            <div class="form__title">{{ $t("reset_pwd_col_new") }}</div>
          </div>
          <div class="main-item mt-10">
            <input class="form__input" type="password"
              v-bind:class="{ 'has-error': offlineChangePwdForm.passwordError, 'has-loading': status.isLoading }"
              v-model="offlineChangePwdForm.userPassowrd"
              v-on:blur="offlineChangePwdForm.passwordError = checkPasswordFormat(offlineChangePwdForm.userPassowrd)"
              v-on:focus="offlineChangePwdForm.passwordError = null; message.apiError = null;">
          </div>
          <div class="main-item mt-6">
            <div v-if="offlineChangePwdForm.passwordError" class="form__error">{{ $t("reset_pwd_client_diff_pwd") }}
            </div>
          </div>
          <div class="main-item mt-6">
            <div class="form__hint" v-html="$t('reset_pwd_desc_set_pwd')"></div>
          </div>
          <div class="main-item mt-30">
            <div class="form__title">{{ $t("reset_pwd_col_new_check") }}</div>
          </div>
          <div class="main-item mt-10">
            <input class="form__input" type="password"
              v-bind:class="{ 'has-error': offlineChangePwdForm.passwordAgainError, 'has-loading': status.isLoading }"
              v-model="offlineChangePwdForm.userPassowrdAgain"
              v-on:blur="offlineChangePwdForm.passwordAgainError = checkPasswordEqual(offlineChangePwdForm.userPassowrd, offlineChangePwdForm.userPassowrdAgain)"
              v-on:focus="offlineChangePwdForm.passwordAgainError = null; message.apiError = null;">
          </div>
          <div class="main-item mt-6">
            <div v-if="offlineChangePwdForm.passwordAgainError" class="form__error">{{ $t("reset_pwd_client_diff_pwd")
              }}</div>
          </div>
          <div class="main-item mt-60">
            <a class="long-button" :disabled='!changePwdIsComplete' v-on:click="postNewPassword">{{
              $t("reset_pwd_button") }}</a>
          </div>
          <div class="main-item mt-6">
            <div v-if="offlineChangePwdForm.apiError" class="form__error">{{ offlineChangePwdForm.apiError }}</div>
          </div>
          <div v-if="offlineChangePwdForm.isLoading" class="main-item mt-30">
            <inline-loading></inline-loading>
          </div>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type=='mailServiceEnabledSetMailHint'">
        <div class="dialog__desc">{{ $t("change_info_email_setting_dialog_enable_setmail_hint") }}</div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button ml-20" v-on:click="dismissDialog">{{
            $t("change_info_email_setting_dialog_enable_setmail_button") }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type=='editMailServiceRecipients'">
        <div class="dialog__title">{{ mailForm.editRecipientsForm.title }}</div>
        <div class="dialog__desc">{{ mailForm.editRecipientsForm.desc }}</div>
        <div class="recipients__form mt-36">
          <div id="main-item" v-for="(item, index) in mailForm.editRecipientsForm.recipients" v-bind:key="index">
            <input class="form__input dialog__input mt-12"
              :placeholder="$t('change_info_email_input_dialog_desc_mail_example')"
              v-bind:class="{ 'has-loading': status.isLoading, 'has-error': item.isInvalid }"
              v-on:blur="item.isInvalid = checkMailFormat(item.recipient)" v-model.trim="item.recipient">
            <div class="form__error recipients__form--error"><span v-if="item.isInvalid">{{
                $t("login_client_email_format") }}</span></div>
          </div>
        </div>
        <div class="recipients__action mt-12">
          <a class="second-button recipients__button" v-on:click="addRecipient">{{
            $t("change_info_email_input_dialog_button_add_mail") }}</a>
        </div>
        <div class="dialog__btns mt-60">
          <a class="main-button dialog__button ml-20" :disabled='!editRecipientsIsComplete'
            v-on:click="postMSRecipients(mailForm.editRecipientsForm.type)">{{
            $t("change_info_email_input_dialog_finish") }}</a>
        </div>
        <div class="main-item mt-20">
          <div v-if="message.apiError" class="form__api__error mt-center">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type=='editMailServiceRecipientsNotAdmin'">
        <div class="dialog__title">{{ $t("change_info_quota_alert_level_reject_msg_title") }}</div>
        <div class="dialog__desc">{{ $t("change_info_quota_alert_level_reject_msg_desc") }}</div>
      </div>
    </div>
    <div class="full-loading" v-if="status.isFullLoading">
      <full-page-loading></full-page-loading>
    </div>
  </div>
</template>

<script>
import sha256 from 'js-sha256'
import URI from 'urijs'

import { apiAccountResetpwdEmail, apiAccountUserPassword, apiAccountUser, apiCheckAccountPwd, apiLicenseAppToken, apiLicenseUpdate } from '../api.js'
import FullPageLoading from './partials/FullPageLoading.vue'
import Header from './base/header.vue'
import InlineLoading from './partials/InlineLoading.vue'
import Sidebar from './base/sidebar.vue'
import LangSelector from './partials/LangSelector.vue'

export default {
  name: 'UserAccount',
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      hasSendMailInAPI: /^true$/i.test(process.env.VUE_APP_HAS_SEND_MAIL_IN_API),
      form: {
        userName: this.$store.state.user.name,
        brandID: this.$store.state.user.brand_id,
        userEmail: this.$store.state.user.email,
        quotaAlertLevel: this.$store.state.license.quota_alert_level,
        password: null,
      },
      mailForm: {
        mailService: this.$store.state.mail_service,
        confirmDialog: {
          type: null,
          title: null,
          desc: null,
          wording: {
            remain_quota_notifications: {
              true: {
                title: this.$i18n.t('change_info_email_setting_dialog_enable_title'),
                desc: null,
              },
              false: {
                title: this.$i18n.t('change_info_email_setting_dialog_disable_title'),
                desc: this.$i18n.t('change_info_email_setting_dialog_disable_desc_remain_quota'),
              },
            },
            license_expiration_reminder: {
              true: {
                title: this.$i18n.t('change_info_email_setting_dialog_enable_title'),
                desc: null,
              },
              false: {
                title: this.$i18n.t('change_info_email_setting_dialog_disable_title'),
                desc: this.$i18n.t('change_info_email_setting_dialog_disable_desc_license_expire'),
              },
            },
            not_enough_space: {
              true: {
                title: this.$i18n.t('change_info_email_setting_dialog_enable_title'),
                desc: null,
              },
              false: {
                title: this.$i18n.t('change_info_email_setting_dialog_disable_title'),
                desc: this.$i18n.t('change_info_email_setting_dialog_disable_desc_not_enough_space'),
              },
            },
            table_update: {
              true: {
                title: this.$i18n.t('change_info_email_setting_dialog_enable_title'),
                desc: null,
              },
              false: {
                title: this.$i18n.t('change_info_email_setting_dialog_disable_title'),
                desc: null,
              },
            },
            query: {
              true: {
                title: this.$i18n.t('change_info_email_setting_dialog_enable_title'),
                desc: null,
              },
              false: {
                title: this.$i18n.t('change_info_email_setting_dialog_disable_title'),
                desc: null,
              },
            },
          },
        },
        editRecipientsForm: {
          type: null,
          title: null,
          desc: null,
          recipients: [],
          wording: {
            remain_quota_notifications: {
              title: this.$i18n.t('change_info_email_input_dialog_title'),
              desc: this.$i18n.t('change_info_email_input_dialog_desc_remain_quota'),
            },
            license_expiration_reminder: {
              title: this.$i18n.t('change_info_email_input_dialog_title'),
              desc: this.$i18n.t('change_info_email_input_dialog_desc_license_expire'),
            },
            not_enough_space: {
              title: this.$i18n.t('change_info_email_input_dialog_title'),
              desc: this.$i18n.t('change_info_email_input_dialog_desc_not_enough_space'),
            },
            table_update: {
              title: this.$i18n.t('change_info_email_input_dialog_title'),
              desc: this.$i18n.t('change_info_email_input_dialog_desc_table_update'),
            },
            query: {
              title: this.$i18n.t('change_info_email_input_dialog_title'),
              desc: this.$i18n.t('change_info_email_input_dialog_desc_search_result'),
            },
          },
        },
      },
      dialog: {
        type: null
      },
      message: {
        apiError: null,
        pwdApiError: null,
        licesneID: null,
        licenseToken: null,
      },
      status: {
        isLoading: false,
        isFullLoading: false,
        tokenAuth: false,
      },
      offlineChangePwdForm: {
        userPassowrd: null,
        userPassowrdAgain: null,
        passwordError: false,
        passwordAgainError: false,
        apiError: null,
        isLoading: false,
      }
    }
  },
  components: {
    'app-header': Header,
    'app-sidebar': Sidebar,
    'full-page-loading': FullPageLoading,
    'inline-loading': InlineLoading,
    'lang-selector': LangSelector
  },
  computed: {
    isUnlimitedQuota() {
      return this.$store.state.license.unlimited_quota
    },
    getToeknIsComplete() {
      return !this.status.passwordError && this.form.password
    },
    changeNameIsComplete() {
      return this.form.userName
    },
    changePwdIsComplete() {
      return (
        !this.offlineChangePwdForm.passwordError &&
        !this.offlineChangePwdForm.passwordAgainError &&
        this.offlineChangePwdForm.userPassowrd &&
        this.offlineChangePwdForm.userPassowrdAgain
      )
    },
    changeQALIsComplete() {
      return this.form.quotaAlertLevel > 0
    },
    editRecipientsIsComplete() {
      const recipients = this.mailForm.editRecipientsForm.recipients
      const recipientsList = Object.values(recipients).map(item => item.recipient)
      const recipietnsListNotEmpty = recipientsList.filter(n => n)
      let isComplete = true
      recipientsList.forEach(recipient => {
        if (this.checkMailFormat(recipient)) isComplete = false
      })
      if (recipietnsListNotEmpty.length === 0) isComplete = false
      return isComplete
    },
    hasTableUpdateInSidebar() {
      return process.env.VUE_APP_SIDEBAR.split(', ').includes('table_update')
    },
  },
  created() {
    const $ = this

    $.updateRemainLicenseQuota()
  },
  methods: {
    checkUserPassword: function() {
      const $ = this
      $.message.apiError = null

      apiCheckAccountPwd({
        brand_id: $.form.brandID,
        email: $.form.userEmail,
        password: $.form.password
      })
      .then(function(response) {
        const res = response.data

        if (res.msg === 'OK') {
          $.getLicenseToken()
        } else {
          $.message.apiError = $.$i18n.t(
            'tryapi_api_credentials_view_admin_error_400_26'
          )
        }
      })
      .catch(function(error) {
        $.message.apiError = error
      })
    },
    getLicenseToken: function() {
      const $ = this

      apiLicenseAppToken()
      .then(function(response) {
        const res = response.data

        if (res.msg === 'OK') {
          $.message.licesneID = res.res.id
          $.message.licenseToken = res.res.token
          $.message.maskedLicenseToken = res.res.token.replace(/./g, '*');
          $.status.tokenAuth = true
        } else {
          $.status.passwordError = true
        }
      })
      .catch(function() {
        $.status.passwordError = true
      })
    },
    getApiToken: function() {
      const $ = this

      $.updateLicenseStatus()
      $.dialog.type = 'getApiToken'
    },
    editName: function() {
      const $ = this
      $.form.userName = this.$store.state.user.name
      $.dialog.type = 'UserChangeName'
    },
    editQuotaAlertLevel: function() {
      const $ = this

      if ($.$store.state.user.role == 'admin') {
        $.dialog.type = 'UserChangeQuotaAlertLevel'
      } else {
        $.dialog.type = 'UserChangeQuotaAlertLevelNotAdmin'
        $.hideDialogAfterTwoSec()
      }

    },
    postForgetPassword: function() {
      const $ = this
      if (process.env.VUE_APP_VERSION === 'online') {
        $.status.isFullLoading = true

        apiAccountResetpwdEmail({
          brand_id: $.form.brandID,
          user_email: $.form.userEmail
        })
        .then(function(response) {
          const res = response.data

          if (res.msg === 'OK') {
            $.dialog.type = 'UserChangePwd'
            $.hideDialogAfterTwoSecAndLogout()
          } else {
            $.message.pwdApiError = $.$i18n.t(`api_error.${res.msg}`)
          }

          $.status.isFullLoading = false
        })
        .catch(function(error) {
          $.status.isFullLoading = true
          alert(error)
        })
      } else {
        $.dialog.type = "OfflineUserChangePwd"
      }
    },
    postNewPassword: function() {
      const $ = this
      $.offlineChangePwdForm.isLoading = true

      apiAccountResetpwdEmail({
        brand_id: $.form.brandID,
        email: $.form.userEmail
      })
      .then(function(response) {
        const res = response.data
        var qs = URI(res['link']).query(true)

        apiAccountUserPassword({
          code: qs.rc,
          password: sha256($.offlineChangePwdForm.userPassowrd)
        })
        .then(function(response) {
          $.dismissDialog()
          $.logout()
        })
        .catch(function(error) {
          $.message.type = 'APIError'
          $.message.text = Object.getOwnPropertyDescriptor(
            error,
            'message'
          ).value
        })
        .finally(function() {
          $.offlineChangePwdForm.isLoading = false
        })
      })
      .catch(function(error) {
        $.offlineChangePwdForm.apiError = Object.getOwnPropertyDescriptor(
          error,
          'message'
        ).value
      })
      .finally(function() {
        $.offlineChangePwdForm.isLoading = false
      })
    },
    postChangeName: function() {
      const $ = this
      $.status.isLoading = true

      apiAccountUser({
        name: this.form.userName
      })
      .then(function(response) {
        $.$store.commit('setUserName', $.form.userName)
        $.dialog.type = null
        $.status.isLoading = false
      })
      .catch(function(error) {
        $.status.isLoading = false
        $.message.apiError = error
      })
    },
    postQuotaAlertLevel: function() {
      const $ = this
      $.status.isLoading = true

      if (this.form.quotaAlertLevel < 0) {
        $.message.apiError = $.$i18n.t(`change_info_quota_alert_level_button_error`)
        $.status.isLoading = false
        return
      }

      apiLicenseUpdate({
        quota_alert_level: parseInt(this.form.quotaAlertLevel)
      })
      .then(function(response) {
        const res = response.data

        $.$store.commit('setLicesneQuotaAlertLevel', $.form.quotaAlertLevel)
        $.dialog.type = null
        $.status.isLoading = false
      })
      .catch(function(error) {
        $.status.isLoading = false
        // $.message.apiError = $.$i18n.t(`api_error.${res.msg}`)
        $.message.apiError = error
      })
    },
    updateMailServiceStatus: function(type) {
      const $ = this
      const isEnabled = $.mailForm.mailService[type].is_enabled
      $.mailForm.confirmDialog.title = $.mailForm.confirmDialog.wording[type][isEnabled].title
      $.mailForm.confirmDialog.desc = $.mailForm.confirmDialog.wording[type][isEnabled].desc
      $.mailForm.confirmDialog.type = type
      if (type !== "query" && $.$store.state.user.role === 'user') {
        $.mailForm.mailService[type].is_enabled = !isEnabled
        $.dialog.type = 'editMailServiceRecipientsNotAdmin'
      } else {
        $.dialog.type = 'ConfirmMSIsEnabledChange'
      }
    },
    cancelMSStatusChange: function(type) {
      const $ = this
      const isEnabled = $.mailForm.mailService[type].is_enabled
      $.mailForm.mailService[type].is_enabled = !isEnabled
      $.dismissDialog()
    },
    postMSStatus: function(type) {
      const $ = this
      const isEnabled = $.mailForm.mailService[type].is_enabled

      let reqBody = []
      reqBody.push({
        'type': type,
        'is_enabled': isEnabled,
      })

      if (type === 'query') {
        apiAccountUser({
          mail_service: reqBody,
        })
        .then(function(response) {
          $.status.isLoading = false
          $.$store.commit('setMailServiceStatus', {
            type,
            'is_enabled': isEnabled,
          })
          if (isEnabled) {
            $.dialog.type = 'mailServiceEnabledSetMailHint'
          } else {
            $.dismissDialog()
          }
        })
        .catch(function(error) {
          $.status.isLoading = false
          $.message.apiError = error
        })
      } else {
        apiLicenseUpdate({
          mail_service: reqBody,
        })
        .then(function(response) {
          $.status.isLoading = false
          $.$store.commit('setMailServiceStatus', {
            type,
            'is_enabled': isEnabled,
          })
          if (isEnabled) {
            $.dialog.type = 'mailServiceEnabledSetMailHint'
          } else {
            $.dismissDialog()
          }
        })
        .catch(function(error) {
          $.status.isLoading = false
          // $.message.apiError = $.$i18n.t(`api_error.${res.msg}`)
          $.message.apiError = error
        })
      }
    },
    editMailServiceRecipients: function(type) {
      const $ = this
      $.mailForm.editRecipientsForm.type = type
      $.mailForm.editRecipientsForm.recipients = []
      $.mailForm.editRecipientsForm.title = $.mailForm.editRecipientsForm.wording[type].title
      $.mailForm.editRecipientsForm.desc = $.mailForm.editRecipientsForm.wording[type].desc

      $.mailForm.mailService[type].recipients.forEach(recipient => {
          $.mailForm.editRecipientsForm.recipients.push({
            'recipient': recipient,
            'isInvalid': $.checkMailFormat(recipient),
          })
      })
      if (type !== "query" && $.$store.state.user.role === 'user') {
        $.dialog.type = 'editMailServiceRecipientsNotAdmin'
      } else {
        $.dialog.type = 'editMailServiceRecipients'
      }
    },
    postMSRecipients: function(type) {
      const $ = this
      const recipients = $.mailForm.editRecipientsForm.recipients
      const recipientsList = Object.values(recipients).map(item => item.recipient)
      const recipietnsListNotEmpty = recipientsList.filter(n => n)

      let reqBody = []
      reqBody.push({
        'type': type,
        'recipients': recipietnsListNotEmpty,
      })

      if (type === 'query') {
        apiAccountUser({
          mail_service: reqBody,
        })
        .then(function(response) {
          $.status.isLoading = false
          $.$store.commit('setMailServiceRecipients', {
            type,
            'recipients': recipietnsListNotEmpty,
          })
          $.dismissDialog()
        })
        .catch(function(error) {
          $.status.isLoading = false
          $.message.apiError = error
        })
      } else {
        apiLicenseUpdate({
          mail_service: reqBody,
        })
        .then(function(response) {
          $.status.isLoading = false
          $.$store.commit('setMailServiceRecipients', {
            type,
            'recipients': recipietnsListNotEmpty,
          })
          $.dismissDialog()
        })
        .catch(function(error) {
          $.status.isLoading = false
          // $.message.apiError = $.$i18n.t(`api_error.${res.msg}`)
          $.message.apiError = error
        })
      }
    },
    addRecipient: function() {
      this.mailForm.editRecipientsForm.recipients.push({
        'recipient': '',
        'isInvalid': this.checkMailFormat(''),
      })
    },
    dismissDialog: function() {
      const $ = this

      $.dialog.type = null
    },
    hidePanel: function(event) {
      var dm = document.getElementById('dialog-main')

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null
        }
      }
    },
    hidePanelAndCancelMSStatusChange: function(event, type) {
      var dm = document.getElementById('dialog-main')

      if (dm) {
        if (!dm.contains(event.target)) {
          this.cancelMSStatusChange(this.mailForm.confirmDialog.type)
          this.dialog.type = null
        }
      }
    },
    hideDialogAfterTwoSec: function() {
      const $ = this

      setTimeout(function() {
        $.dialog.type = null
      }, 2000)
    },
    hideDialogAfterTwoSecAndLogout: function() {
      const $ = this

      setTimeout(function() {
        $.dialog.type = null
        $.logout()
      }, 2000)
    }
  }
}
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #eaeaea;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.main-item {
  display: flex;
  height: fit-content;
}

.item__right,
.item__left--info,
.item__left--mail {
  height: fit-content;
  flex-basis: 50%;
  max-width: 320px;
  background-color: #ffffff;
  padding: 30px 40px;
}

.item-title {
  font-size: 14px;
  color: #000000;
  text-align: left;
}

.item-info {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
  text-align: left;
  word-wrap: break-word;
}

.item-subinfo {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  text-align: left;
}

.item-hint {
  font-size: 12px;
  color: #aeaeae;
  text-align: left;
}

.item_hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #d8d8d8;
  margin: 1em 0;
  padding: 0;
}

.button--credentials:disabled,
.button--credentials[disabled] {
  opacity: 1;
  background-color: #640061;
  pointer-events: unset;
}

.lang-item {
  width: 160px;
}

.dialog__input {
  margin-left: auto;
  margin-right: auto;
}

.dialog__form {
  padding: 0 70px;
}

.system-mail__item {
  display: flex;
}

.system-mail__item--hr {
  border-top: 1px solid #d8d8d8;
}

.system-mail__item__left,
.system-mail__item__right {
  height: fit-content;
  flex-basis: 50%;
  max-width: 160px;
}

.system-mail__item__right {
  display: flex;
}

.system-mail__intro__title {
  font-size: 14px;
  color: #000000;
}

.system-mail__intro__desc {
  font-size: 12px;
  color: #aeaeae;
}

.item__switch,
.item__change__button {
  height: fit-content;
  flex-basis: 50%;
  max-width: 80px;
}

.item__change__button {
  text-align: center;
  padding: 0;
  margin-top: 23px;
}

.item__change__button:disabled,
.item__change__button[disabled]{
  opacity: 0.3;
  pointer-events: none;
}

.recipients__form--error {
  margin-left: auto;
  margin-right: auto;
}

.recipients__action {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.recipients__button {
  width: fit-content;
}

/* Switch */
input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  border: 0;
  background: none;
}

.switch__btn__wrap label {
  display: block;
  position: relative;
  width: 42px;
  height: 26px;
  padding: 0 7px;
  border-radius: 28px;
  border: 2px solid #640061;
  background: #ffffff;
  cursor: pointer;
}
.switch__btn__wrap label .switch__btn__txt {
  display: block;
  font-size: 12px;
  color: #640061;
  font-weight: bold;
  line-height: 26px;
  text-transform: uppercase;
  text-align: right;
}
.switch__btn__wrap label .switch__btn__txt:before{
  content: "OFF";
}
.switch__btn__wrap label:after {
  position: absolute;
  top: 2px;
  left: 4px;
  width: 18px;
  height: 18px;
  border: 2px solid #640061;
  border-radius: 100%;
  background-color: #ffffff;
  background-clip: content-box;
  transition: left .5s;
  content: '';
}
.switch__btn__wrap input:checked + label {
    background: #640061;
}
.switch__btn__wrap input:checked + label .switch__btn__txt {
  color: #ffffff;
  text-align: left;
}
.switch__btn__wrap input:checked + label .switch__btn__txt:before{
  position: absolute;
  left: 11px;
  content: "ON";
}
.switch__btn__wrap input:checked + label:after {
  left: 32px;
}
</style>
