import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh from './translations/zh.json';
import en from './translations/en.json';
import ja from './translations/ja.json';

Vue.use(VueI18n);

const locale = 'en_US';
const messages = {
  zh_TW: zh,
  en_US: en,
  ja_JP: ja,
};

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en_US',
  messages,
});

export default i18n;
