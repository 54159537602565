<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message :type="message.type" :message="message.text" :role="form.userRole"></full-page-message>
    </div>
    <div v-if="status.linkValid && !message.type" class="main-block">
      <app-sidebar></app-sidebar>
      <div class="main-content">
        <div class="lang-item">
          <lang-selector></lang-selector>
        </div>
        <div class="main-item">
          <img class="witcher__logo" :src="`${$baseUrl}witcher_logo.png`" />
        </div>
        <div class="main-item mt-40">
          <div class="page__title">{{ $t('register_page_title') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('register_col_username') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-model="form.userName"
            maxlength="50"
            v-on:focus="message.apiError = null"
          />
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('register_col_email') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-model.trim="form.userEmail"
            disabled="disabled"
          />
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('register_col_role') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-model="form.userRole"
            disabled="disabled"
          />
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('register_col_set_pwd') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="password"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.passwordError }"
            v-model="form.userPassowrd"
            v-on:blur="status.passwordError = checkPasswordFormat(form.userPassowrd)"
            v-on:focus="
              status.passwordError = null;
              message.apiError = null;
            "
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.passwordError" class="form__error">{{ $t('register_client_diff_pwd') }}</div>
        </div>
        <div class="main-item mt-6">
          <div class="form__hint" v-html="$t('register_desc_set_pwd')"></div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('register_col_set_pwd_check') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="password"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.passwordAgainError }"
            v-model="form.userPassowrdAgain"
            @blur="checkPasswordEqual"
            v-on:blur="status.passwordAgainError = checkPasswordEqual(form.userPassowrd, form.userPassowrdAgain)"
            v-on:focus="
              status.passwordAgainError = null;
              message.apiError = null;
            "
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.passwordAgainError" class="form__error">{{ $t('register_client_diff_pwd') }}</div>
        </div>
        <div class="main-item mt-60">
          <a class="long-button" :disabled="!isComplete" v-on:click.prevent="showTosAndPP">{{
            $t('register_button')
          }}</a>
        </div>
        <div class="main-item mt-6">
          <div v-if="message.apiError" class="form__error">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
        <div class="main-item mt-30">
          <div v-if="status.registerSuccess" class="form__success pr-20">
            <span class="icon-check mr-10"></span>{{ $t('register_client_200_hint') }}
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'TosAndPP'">
        <div class="dialog__title">{{ $t('register_statement_title') }}</div>
        <div class="dialog__desc mt-20">
          A.數據服務條款<br /><br />
          本條款旨在於使用「本平台」服務時，需要注意的數據安全目標、宣言及授權對象<br /><br />
          一、前言<br />
          本平台提供需要KYC與AML的客戶進行高風險徵信的服務，促進徵信制度之穩健發展。數據的品質、安全與正當使用為本平台的服務指標，因此數據安全管理之首要目標即在於確保數據的機密、完整與可用性，保護其免於遭受來自內部、外部、人為、天災、蓄意或意外之影響，以降低任何資訊安全事件所可能帶來之衝擊，同時保障本平台、客戶及被查詢方之權益。<br /><br />
          二、數據安全目標<br />
          1.確保本平台高風險數據之機密、保護性及禁止非法使用。<br />
          2.確保數據系統、演算法及模型之安全性及禁止非法使用。<br />
          3.確保數據業務流程之有效性。<br /><br />
          三、數據安全宣言<br />
          1.本平台所有數據內容屬本平台擁有，所有在本平台之系統設備及數據資料上所處理、儲存或傳輸交換之資訊均歸屬本平台資產，本平台有權了解、觀看或取用。<br />
          2.本平台員工及外部客戶之使用人員，凡其使用本平台數據資產，均有責任及義務保護其數據內容，以防止未經授權存取、
          擅改、破壞或不當揭露。<br />
          3.風險相關數據為本平台最高業務機密，嚴禁未經授權存取與洩露， 並不得有損害客戶及被查詢方之行為。<br /><br />
          B.隱私權條款<br /><br />
          WitcherFin高風險驗證平台（以下
          簡稱「本平台」），為了尊重客戶的隱私權，因此制訂了隱私權保護宣言。對於「本平台」如何蒐集、利用及保護客戶的瀏覽行為、操作與數據使用狀況，請詳細閱讀下列內容：<br /><br />
          一、隱私權條款的適用範圍<br />
          以下的隱私權條款，適用於您在使用「本平台」服務時，所涉及的資料蒐集、利用與安全性。<br /><br />
          二、資料之蒐集及利用方式<br />
          1.單純在「本平台」的瀏覽及檔案下載行為，本平台並不會蒐集任何有關個人的身分資料。<br />
          2.「本平台」會記錄客戶上站的 IP
          位址以及在「本平台」內瀏覽及動作行為等資訊，以提供「本平台」管理單位內部作流量和調查的總量分析，以利提昇「本平台」之服務品質，並保護客戶使用「本平台」數據服務的資訊安全議題。<br /><br />
          三、隱私權保護宣言之確認<br />
          如果您對「本平台」的隱私權條款有任何疑問，歡迎與我們聯絡。<br />
        </div>
        <div class="main-item mt-20">
          <input type="checkbox" id="checkbox" v-model="form.checked" /> {{ $t('register_statement_check_box') }}
        </div>
        <div class="main-item mt-20">
          <a class="long-button" :disabled="!isChecked" v-on:click="postUserRegister">{{
            $t('register_statement_confirm')
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiAccountRegister, apiRegCodeStatus } from '../api.js';
import FullPageMessage from './partials/FullPageMessage.vue';
import InlineLoading from './partials/InlineLoading.vue';
import Sidebar from './base/sidebar.vue';
import LangSelector from './partials/LangSelector.vue';

export default {
  name: 'UserRegister',
  data: function () {
    return {
      status: {
        linkValid: false,
        passwordError: false,
        passwordAgainError: false,
        isLoading: false,
        registerSuccess: false,
      },
      form: {
        code: this.$route.query.rc,
        token: this.$route.query.token,
        userName: null,
        userEmail: null,
        userRole: null,
        userPassowrd: null,
        userPassowrdAgain: null,
        checked: false,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
      dialog: {
        type: null,
      },
    };
  },
  components: {
    'app-sidebar': Sidebar,
    'full-page-message': FullPageMessage,
    'inline-loading': InlineLoading,
    'lang-selector': LangSelector,
  },
  created() {
    const $ = this;

    if (this.form.code && this.form.code.length === 6 && this.form.token) {
      apiRegCodeStatus({
        code: this.form.code,
        token: this.form.token,
      })
        .then(function (response) {
          const res = response.data;

          $.form.userEmail = res.res.email;
          $.form.userRole = res.res.role;

          if (res.msg === 'OK') {
            $.status.linkValid = true;
          } else if (res.msg === 'USED') {
            $.message.type = 'AlreadyRegistered';
          } else {
            $.message.type = 'RegisterCodeError';
            $.message.text = `%0D%0A%0D%0A---%0D%0ARegister code : ${$.form.code}%0D%0AToken: ${$.form.token}%0D%0AStatus Code: 4`;
          }
        })
        .catch(function (error) {
          $.message.type = 'APIError';
          $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
        });
    } else {
      $.message.type = 'RegisterLinkInvalid';
      $.message.text = `%0D%0A%0D%0A---%0D%0ARegister code : ${$.form.code}%0D%0AToken: ${$.form.token}%0D%0AStatus Code: null`;
    }
  },
  computed: {
    isChecked() {
      return this.form.checked;
    },
    isComplete() {
      return (
        !this.status.isLoading &&
        !this.status.passwordError &&
        !this.status.passwordAgainError &&
        this.form.userName &&
        this.form.userEmail &&
        this.form.userRole &&
        this.form.userPassowrd &&
        this.form.userPassowrdAgain
      );
    },
  },
  methods: {
    showTosAndPP: function () {
      const $ = this;

      $.dialog.type = 'TosAndPP';
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    postUserRegister: function () {
      const $ = this;
      $.dismissDialog();
      $.status.isLoading = true;

      let reqBody = new Object();
      reqBody.code = $.form.code;
      reqBody.user_name = $.form.userName;
      reqBody.user_password = $.form.userPassowrd;

      apiAccountRegister(reqBody)
        .then(function (response) {
          const res = response.data;
          $.status.registerSuccess = true;
          setTimeout(function () {
            $.$router.push('/user/login/');
          }, 3000);
          $.status.isLoading = false;
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            const errMsg = error.response.data.msg;
            if (res.msg === 'REG_CODE_NOT_MATCH') {
              $.message.type = 'RegisterLinkInvalid';
            } else if (res.msg === 'REG_CODE_EXPIRED') {
              $.message.type = 'RegisterCodeExpire';
              $.message.text = `%0D%0A%0D%0A---%0D%0ARegister code : ${$.form.code}%0D%0AToken: ${$.form.token}%0D%0AName: ${$.form.userName}%0D%0AStatus Code: 23`;
            } else if (res.msg === 'ACCOUNT_ALREADY_REGISTERED') {
              $.message.type = 'AlreadyRegistered';
            } else {
              $.message.apiError = res.msg;
            }
            $.status.isLoading = false;
          } else {
            $.status.isLoading = false;
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        });
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
  },
};
</script>

<style scoped>
.main-block {
  display: flex;
  height: 100vh;
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #ffffff;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
  align-items: center;
}

.main-item {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.lang-item {
  width: 160px;
  position: absolute;
  top: 40px;
  right: 60px;
}

.dialog {
  left: calc(((100vw - 20% - 680px) / 2) + 20%);
  align-items: center;
}

.dialog__desc {
  box-sizing: border-box;
  width: 90%;
  height: 300px;
  overflow-y: scroll;
  border: solid 1px #d8d8d8;
  padding: 20px;
  text-align: left;
}

.dialog__desc::-webkit-scrollbar {
  display: unset;
  -webkit-appearance: none;
  width: 5px;
}

.dialog__desc::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #d8d8d8;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
