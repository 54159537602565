<template>
  <div class="site-main">
    <app-header :title="$t('side_bar_menu_faq')"></app-header>
    <div class="main-block">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div class="main-content">
        <div class="faq" v-for="(faqGroup, faqTitle, index) in faqs">
          <div class="faq__title" v-bind:class="{ 'mt-60': index !== 0 }">{{ $t(`faq_group_title_${faqTitle}`) }}</div>
          <div class="faq__block mt-20" v-for="faq in faqGroup">
            <div class="faq__question" v-on:click="faq.show = !faq.show">
              <div class="question mr-40" v-html="faq.question"></div>
              <div class="question__arrow">
                <span class="icon-arrow-up" v-if="faq.show"></span><span class="icon-arrow-down" v-else></span>
              </div>
            </div>
            <div class="faq__answer mt-20" v-if="faq.show" v-html="faq.answer"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './base/header.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'Faq',
  data() {
    return {
      faqs: null,
      online_faqs: {
        numsearch: [
          {
            question: this.$i18n.t('faq_numsearch_q1'),
            answer: this.$i18n.t('faq_numsearch_a1'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q2'),
            answer: this.$i18n.t('faq_numsearch_a2'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q3'),
            answer: this.$i18n.t('faq_numsearch_a3'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q4'),
            answer: this.$i18n.t('faq_numsearch_a4'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q5'),
            answer: this.$i18n.t('faq_numsearch_a5'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q6'),
            answer: this.$i18n.t('faq_numsearch_a6'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q11'),
            answer: this.$i18n.t('faq_numsearch_a11'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q12'),
            answer: this.$i18n.t('faq_numsearch_a12'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q14'),
            answer: this.$i18n.t('faq_numsearch_a14'),
            show: false,
          },
        ],
        member: [
          {
            question: this.$i18n.t('faq_member_q1'),
            answer: this.$i18n.t('faq_member_a1'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_member_q2'),
            answer: this.$i18n.t('faq_member_a2'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_member_q3'),
            answer: this.$i18n.t('faq_member_a3'),
            show: false,
          },
        ],
        banned: [
          {
            question: this.$i18n.t('faq_banned_q1'),
            answer: this.$i18n.t('faq_banned_a1'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_banned_q2'),
            answer: this.$i18n.t('faq_banned_a2'),
            show: false,
          },
        ],
        other: [
          {
            question: this.$i18n.t('faq_other_q1'),
            answer: this.$i18n.t('faq_other_a1'),
            show: false,
          },
        ],
      },
      offline_faqs: {
        numsearch: [
          {
            question: this.$i18n.t('faq_numsearch_q1'),
            answer: this.$i18n.t('faq_numsearch_a1'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q4'),
            answer: this.$i18n.t('faq_numsearch_a4'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q6'),
            answer: this.$i18n.t('faq_numsearch_a6'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q12'),
            answer: this.$i18n.t('faq_numsearch_a12'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q14'),
            answer: this.$i18n.t('faq_numsearch_a14'),
            show: false,
          },
        ],
        member: [
          {
            question: this.$i18n.t('faq_member_q1'),
            answer: this.$i18n.t('faq_member_a1'),
            show: false,
          },
        ],
        other: [
          {
            question: this.$i18n.t('faq_other_q1'),
            answer: this.$i18n.t('faq_other_a1'),
            show: false,
          },
        ],
        dataupdate: [
          {
            question: this.$i18n.t('faq_dataupdate_q1'),
            answer: this.$i18n.t('faq_dataupdate_a1'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_dataupdate_q2'),
            answer: this.$i18n.t('faq_dataupdate_a2'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_dataupdate_q3'),
            answer: this.$i18n.t('faq_dataupdate_a3'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_dataupdate_q4'),
            answer: this.$i18n.t('faq_dataupdate_a4'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_dataupdate_q5'),
            answer: this.$i18n.t('faq_dataupdate_a5'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_dataupdate_q6'),
            answer: this.$i18n.t('faq_dataupdate_a6'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_dataupdate_q7'),
            answer: this.$i18n.t('faq_dataupdate_a7'),
            show: false,
          },
        ],
        tokenupdate: [
          {
            question: this.$i18n.t('faq_tokenupdate_q1'),
            answer: this.$i18n.t('faq_tokenupdate_a1'),
            show: false,
          },
        ],
      },
    };
  },
  components: {
    'app-header': Header,
    'app-sidebar': Sidebar,
  },
  created() {
    const $ = this;

    $.checkFaqVersion();
    $.updateRemainLicenseQuota();
  },
  methods: {
    checkFaqVersion: function () {
      switch (process.env.VUE_APP_VERSION) {
        case 'offline':
          this.faqs = this.offline_faqs;
          break;
        default:
          this.faqs = this.online_faqs;
      }
    },
  },
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #eaeaea;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.faq__title {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  text-align: left;
}

.faq__block {
  background-color: #ffffff;
  padding: 20px;
  text-align: left;
}

.faq__question {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  cursor: pointer;
}

.question {
  width: calc(100% - 60px);
}

.question__arrow {
  width: 40px;
  text-align: center;
}

.faq__answer {
  font-size: 14px;
  color: #000000;
}
</style>
