<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message :type="message.type" :message="message.text"></full-page-message>
    </div>
  </div>
</template>

<script>
import FullPageMessage from './partials/FullPageMessage.vue';

export default {
  name: 'UserLogout',
  data() {
    return {
      isForceLogout: this.$store.state.isForceLogout,
      userData: [],
      message: {
        type: null,
        text: null,
      },
    };
  },
  components: {
    'full-page-message': FullPageMessage,
  },
  created() {
    // TODO: 補閒置太久強制登出的畫面
    this.logout();
  },
};
</script>

<style scoped>
.site-main {
  flex-basis: 80%;
  background-color: #fff;
  overflow-y: scroll;
}

.main-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
