<template>
  <div class="site-main">
    <app-header :title="$t('side_bar_menu_model_num_search')"></app-header>
    <div class="main-block">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div class="main-content">
        <div class="main-item">
          <div class="query__block">
            <div class="query__block__step1--title">
              <div class="query__title">
                <span class="query__title--icon icon-number-1 mr-5"></span>
                {{ $t('numsearch_model_upload_title_choose_file') }}
              </div>
            </div>
            <div class="query__block__step1--content">
              <div class="query__file__title">{{ $t('numsearch_model_upload_content_file') }}</div>
              <div class="query__files" v-for="(file, index) in form.files" v-bind:key="index">
                <div class="query__file mt-30" v-bind:class="{ 'query__file--invalid': checkFileFormat(file) }">
                  <span class="query__file--name">{{ file.name }}</span>
                  <span class="query__file--delete icon-close" v-on:click="deleteFile(index)"></span>
                </div>
                <div class="query__file--alert mt-5" v-show="checkFileFormat(file)">
                  {{ $t('numsearch_model_upload_hint_choose_file_fail') }}
                </div>
              </div>
              <div class="query__action--file mt-30">
                <label for="file-upload" class="custom-file-upload main-button">{{
                  $t('numsearch_model_upload_button_view_file')
                }}</label>
                <input
                  ref="fileUploader"
                  id="file-upload"
                  type="file"
                  v-on:click="resetFileUploader"
                  v-on:change="appendFiles"
                  multiple
                />
              </div>
            </div>
            <div class="query__block__step2--title mt-5">
              <div class="query__title">
                <span class="query__title--icon icon-number-2 mr-5"></span>
                {{ $t('numsearch_model_upload_title_choose_dimension') }}
              </div>
            </div>
            <div class="query__block__step2--content">
              <div class="query__action--dimensions">
                <div
                  class="query__action__item--dimension round-button mr-10 mt-5"
                  v-bind:class="{
                    'round-button--active':
                      form.dimensions.includes(item) || form.dimensions.length === customerDimensions.length - 1,
                  }"
                  v-for="(item, index) in customerDimensions"
                  v-bind:key="index"
                  v-on:click="appendDimensions(item)"
                >
                  {{ $t(`numsearch_model_upload_button_${item}`) }}
                </div>
              </div>
            </div>
            <div class="query__block__step3--title mt-5" v-if="hasCustomSnapshot">
              <div class="query__title">
                <span class="query__title--icon icon-number-3 mr-5"></span>
                {{ $t('numsearch_model_upload_title_choose_snapshot') }}
              </div>
            </div>
            <div class="query__block__step3--content" v-if="hasCustomSnapshot">
              <div class="query__action--snapshot">
                <multiselect
                  class="query__action__item--snapshot"
                  v-model="form.snapshots"
                  :options="customerSnapshots"
                  group-values="snapshots"
                  group-label="all"
                  :selectLabel="$t('numsearch_model_upload_button_choose_snapshot_select_label')"
                  :deselectLabel="$t('numsearch_model_upload_button_choose_snapshot_deselect_label')"
                  :selectedLabel="$t('numsearch_model_upload_button_choose_snapshot_selected')"
                  selectGroupLabel=""
                  deselectGroupLabel=""
                  :group-select="true"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :placeholder="$t('numsearch_model_upload_button_choose_snapshot')"
                  :searchable="true"
                >
                  <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">
                      {{
                        $t('numsearch_model_upload_hint_choose_snapshot_is_choose', { count: form.snapshots.length })
                      }}
                    </span>
                  </template>
                </multiselect>
                <div class="query__action--hint mt-10" v-if="form.snapshots.length > 0">
                  {{
                    $t('numsearch_model_upload_hint_choose_snapshot_is_choose_detail', {
                      snapshot_list: sortFormSnapshots.join(', '),
                    })
                  }}
                </div>
                <div class="query__action--hint mt-10" v-else>
                  {{ $t('numsearch_model_upload_hint_choose_snapshot_not_choose') }}
                </div>
              </div>
            </div>
            <div class="query__block__step4--title mt-5">
              <div class="query__title">
                <span class="query__title--icon icon-number-4 mr-5" v-if="hasCustomSnapshot"></span>
                <span class="query__title--icon icon-number-3 mr-5" v-else></span>
                {{ $t('numsearch_model_upload_title_upload_file') }}
              </div>
            </div>
            <div class="query__block__step4--content">
              <div class="query__submit long-button mt-40" :disabled="!isComplete" v-on:click="genFilesFormData">
                {{ $t('numsearch_model_upload_button_upload_file') }}
              </div>
              <div class="query__error mt-10" v-if="form.err !== null">
                {{ $t('numsearch_model_upload_hint_upload_file_fail', { err_msg: form.err }) }}
              </div>
            </div>
            <div class="query__block--mask" v-if="spaceIsNotEnough()">
              {{ $t('numsearch_model_upload_mask_hint_space_error') }}
            </div>
          </div>
          <div class="status__block mt-40">
            <div class="status__action">
              <div class="status__action__left">
                <div class="status__action__title">{{ $t('numsearch_model_status_title_search_status') }}</div>
              </div>
              <div class="status__action__right">
                <div class="status__action__dateblock mr-40">
                  <vc-date-picker
                    class="status__action__dateblock--datepicker"
                    mode="range"
                    :max-date="new Date()"
                    v-model="reportStatus.range"
                  />
                  <div class="status__action__dateblock--hint mt-5" v-if="reportStatus.rangeDiff <= 90">
                    {{ $t('numsearch_model_search_adjust_interval_hint', { interval: 90 }) }}
                  </div>
                  <div class="status__action__dateblock--hint status__action__dateblock--err mt-5" v-else>
                    {{
                      $t('numsearch_model_search_adjust_interval_error_message', {
                        interval: reportStatus.rangeDiff - 90,
                      })
                    }}
                  </div>
                </div>
                <div class="status__action--refresh main-button" v-on:click="getAllReportStatus">
                  {{ $t('numsearch_model_search_refresh_button') }}
                </div>
              </div>
            </div>
            <div class="status__meta__block--error mt-40 mr-10" v-if="reportStatus.err !== null">
              {{ reportStatus.err }}
            </div>
            <div class="status__meta__block--empty mt-40 mr-10" v-else-if="reportStatus.resultData.length === 0">
              {{ $t('numsearch_model_search_status_empty_message') }}
            </div>
            <div
              class="status__meta__block--hasdata mt-20"
              v-else
              v-for="(meta, index) in reportStatus.resultData"
              v-bind:key="index"
            >
              <!-- File -->
              <div class="status__meta__block" v-if="meta.source">
                <div class="status__meta__title">
                  <span class="status__meta--filename mr-10">{{ meta.source }}</span>
                  <span class="status__meta--status">{{
                    $t(`numsearch_model_search_status_info_${meta.status}`)
                  }}</span>
                  <div class="status__meta--arrow" v-on:click="meta.show = !meta.show">
                    <span class="icon-arrow-up" v-if="meta.show"></span>
                    <span class="icon-arrow-down" v-else></span>
                  </div>
                </div>
                <div class="status__meta__content status__meta__dimensions">
                  {{ $t('numsearch_model_search_status_info_dimension') }} {{ formatDimensions(meta.dimensions) }}
                </div>
                <div class="status__meta__content status__meta__snapshot" v-if="meta.snapshot">
                  {{ $t('numsearch_model_search_status_info_snapshot') }} {{ meta.snapshot }}
                </div>
                <div class="status__meta__content status__meta__snapshot" v-else>
                  {{ $t('numsearch_model_search_status_info_snapshot')
                  }}{{ $t('numsearch_model_search_status_info_snapshot_content') }}
                </div>
                <div class="status__meta__content status__meta__errmsg" v-if="meta.status == 'failed'">
                  {{ $t('numsearch_model_search_status_info_error_message') }}{{ meta.msg }}
                </div>
                <div class="status__meta__detail" v-if="meta.show">
                  <div class="status__meta__content status__meta__owner mt-30">
                    {{ $t('numsearch_model_search_status_info_mail_uploader') }}{{ meta.owner }}
                  </div>
                  <div class="status__meta__content status__meta__cclist" v-if="hasSendMailInAPI">
                    {{ $t('numsearch_model_search_status_info_search_noti_mail') }}<br />
                    <div v-for="(recipient, index) in meta.mail_recipients">
                      {{ recipient }}
                    </div>
                    <br /><br />
                    {{ $t('numsearch_model_search_status_hint_mail') }}<br />
                  </div>
                  <div
                    class="status__content__action mt-30"
                    v-if="meta.status === 'running' || meta.status === 'succeeded'"
                  >
                    <div
                      class="main-button status__content__action--download"
                      v-on:click="downloadReports(meta.id)"
                      :disabled="downloadListEmpty(meta.id)"
                    >
                      {{ $t('numsearch_model_search_status_button_download_result') }}
                    </div>
                  </div>
                  <div class="status__content__block mt-30" v-for="(content, csv) in meta.contents" v-bind:key="csv">
                    <div class="status__content__row status__content__block--head">
                      <div class="status__content__block--item status__content__block--checkbox">
                        <input
                          type="checkbox"
                          id="checkbox"
                          :checked="checkSplitCSVCheckedCnt(meta.id, content)"
                          v-on:click="appendDownloadList($event, meta.id, content)"
                          v-if="checkSplitCSVStatus(content)"
                        />
                      </div>
                      <div class="user__block__list-item status__content__block--csv">{{ csv }}</div>
                    </div>
                    <div
                      class="status__content__row status__content__block--content"
                      v-for="(csv, index) in content"
                      v-bind:key="index"
                    >
                      <div class="status__content__block--item status__content__block--checkbox">
                        <input
                          type="checkbox"
                          id="checkbox"
                          v-if="csv.status == 'succeeded'"
                          v-model="downloadForm.list"
                          :value="`${meta.id}/${csv.file.split('.')[0]}`"
                          checked
                        />
                      </div>
                      <div class="status__content__block--item status__content__block--filename">{{ csv.file }}</div>
                      <div class="status__content__block--item status__content__block--status">
                        {{ $t(`numsearch_model_search_status_info_${csv.status}`) }}
                      </div>
                      <div class="status__content__block--item status__content__block--ctime">
                        {{ csv.ctime | dtFormat }}
                      </div>
                      <div class="status__content__block--item status__content__block--msg">{{ csv.msg }}</div>
                    </div>
                  </div>
                  <div class="status__content__delete mt-60">
                    <div
                      class="main-button status__content__delete--delete"
                      v-on:click="deleteReportMetaConfirm(meta.id)"
                      :disabled="meta.status === 'deleted' || meta.status === 'deleting'"
                    >
                      {{ $t('numsearch_model_search_status_button_delete_result') }}
                    </div>
                  </div>
                  <div
                    class="status__content__delete--error mt-10"
                    v-if="deleteForm.err !== null && deleteForm.err.startsWith(meta.id)"
                  >
                    {{ deleteForm.err.split(' - ')[1] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'ReportDeleteConfirm'">
        <div class="dialog__title">{{ $t('numsearch_model_search_status_button_delete_result') }}</div>
        <div class="dialog__desc mt-20">{{ $t('numsearch_model_search_status_button_delete_result_hint') }}</div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialog">{{
            $t('numsearch_model_search_status_button_delete_result_no')
          }}</a>
          <a class="main-button dialog__button ml-20" v-on:click="deleteReportMeta">{{
            $t('numsearch_model_search_status_button_delete_result_yes')
          }}</a>
        </div>
      </div>
    </div>
    <div class="full-loading" v-if="status.isFullLoading">
      <full-page-loading :message="$t('numsearch_200_on_search_warning_title')"></full-page-loading>
    </div>
    <div id="canvas-img"></div>
    <a id="download-btn" class="reports__download__btn" v-bind:href="downloadForm.path" download>Download</a>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import Multiselect from 'vue-multiselect';

import { apiReportStatus, apiReportGenerateFiles, apiReportDelete } from '../api.js';
import FullPageLoading from './partials/FullPageLoading.vue';
import Header from './base/header.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'NumReport',
  props: {
    msg: String,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      hasSendMailInAPI: /^true$/i.test(process.env.VUE_APP_HAS_SEND_MAIL_IN_API),
      customerSnapshots: [
        {
          all: this.$i18n.t('numsearch_model_upload_button_choose_snapshot_select_all'),
          snapshots: process.env.VUE_APP_SNAPSHOTS.split(', '),
        },
      ],
      reportsPath: process.env.VUE_APP_REPORTS_PATH,
      status: {
        isFullLoading: false,
      },
      dialog: {
        type: null,
      },
      form: {
        filenames: [],
        files: [],
        snapshots: [],
        dimensions: [],
        generateFilesForm: new FormData(),
        err: null,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
      reportStatus: {
        range: {
          start: new Date(),
          end: new Date(),
        },
        rangeDiff: 0,
        resultData: [],
        err: null,
      },
      downloadForm: {
        list: [],
        err: null,
        path: null,
      },
      deleteForm: {
        metaID: null,
        err: null,
      },
    };
  },
  components: {
    Multiselect,
    'app-header': Header,
    'app-sidebar': Sidebar,
    'full-page-loading': FullPageLoading,
  },
  created() {
    const $ = this;

    // Set reportStatus default start date
    var startDate = new Date();
    startDate = startDate.setDate(startDate.getDate() - 14);
    $.reportStatus.range.start = new Date(startDate);

    $.updateLicenseStatus();
    $.getAllReportStatus();
    $.updateSystemVersion();
  },
  filters: {
    roundToInt: function (value) {
      return Math.round(value);
    },
    dtFormat: function (datetime) {
      if (!datetime) return '';
      return moment.tz(datetime, 'UTC').tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
    },
  },
  computed: {
    customerDimensions() {
      return ['all', ...this.$store.state.license.dimensions];
    },
    hasCustomSnapshot() {
      return process.env.VUE_APP_SNAPSHOTS !== '';
    },
    sortFormSnapshots() {
      return this.form.snapshots.sort();
    },
    isComplete() {
      return (this.form.dimensions.length > 0) & (this.form.files.length > 0);
    },
  },
  methods: {
    resetFileUploader() {
      this.$refs.fileUploader.value = '';
    },
    spaceIsNotEnough() {
      return this.$store.state.system.remain_disk.replace('GB', '') <= 0;
    },
    formatDimensions: function (dimensions) {
      const dims = dimensions.split('/');
      var tDims = [];
      for (var i = 0; i < dims.length; i++) {
        tDims.push(this.$i18n.t(`numsearch_model_upload_button_${dims[i]}`));
      }
      return tDims.join('/');
    },
    getAllReportStatus: function () {
      const $ = this;

      const start = moment($.reportStatus.range.start);
      const end = moment($.reportStatus.range.end);
      $.reportStatus.rangeDiff = Math.abs(moment.duration(start.diff(end)).asDays());
      if ($.reportStatus.rangeDiff > 90) {
        return;
      }

      $.status.isFullLoading = true;
      $.reportStatus.err = null;
      $.reportStatus.resultData = [];
      $.downloadForm.list = [];

      apiReportStatus({
        stime: moment($.reportStatus.range.start).format('YYYYMMDD'),
        etime: moment($.reportStatus.range.end).format('YYYYMMDD'),
      })
        .then(function (response) {
          if (response.data === null) {
            $.reportStatus.resultData = [];
          } else {
            for (var i = 0; i < response.data.length; i++) {
              response.data[i]['show'] = false;

              // append all succeed report to download list
              const metaID = response.data[i].id;
              if (response.data[i].hasOwnProperty('contents')) {
                for (const [csv, splitCSVs] of Object.entries(response.data[i].contents)) {
                  for (var j = 0; j < splitCSVs.length; j++) {
                    if (splitCSVs[j].status === 'succeeded') {
                      const downloadID = `${metaID}/${splitCSVs[j].file.split('.')[0]}`;
                      $.downloadForm.list.push(downloadID);
                    }
                  }
                }
              }
            }
            $.reportStatus.resultData = response.data;
          }
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            $.reportStatus.err = error.response.data.msg;
          } else {
            $.reportStatus.err = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        })
        .finally(function () {
          $.status.isFullLoading = false;
        });
    },
    appendFiles: function (e) {
      const $ = this;

      for (var i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        if ($.form.filenames.indexOf(file.name) === -1) {
          $.form.filenames.push(file.name);
          $.form.files.push(file);
        }
      }
    },
    checkFileFormat: function (file) {
      var regexFileName = /^[\w]{1,40}\.(zip)+([.0-9])*$/;

      if (!regexFileName.test(file.name)) {
        return true;
      }

      return false;
    },
    deleteFile: function (index) {
      const $ = this;
      $.form.files.splice(index, 1);
    },
    appendDimensions: function (dimension) {
      const $ = this;

      if (dimension === 'all') {
        if ($.form.dimensions.length === $.customerDimensions.length - 1) {
          $.form.dimensions = [];
        } else {
          $.form.dimensions = [];
          for (var i = 0; i < $.customerDimensions.length; i++) {
            if ($.customerDimensions[i] !== 'all') {
              $.form.dimensions.push($.customerDimensions[i]);
            }
          }
        }
      } else {
        const index = $.form.dimensions.indexOf(dimension);
        if (index === -1) {
          $.form.dimensions.push(dimension);
        } else {
          $.form.dimensions.splice(index, 1);
        }
      }
    },
    genFilesFormData: function () {
      const $ = this;

      if ($.form.snapshots.length === 0) {
        $.form.generateFilesForm = new FormData();
        for (var i = 0; i < $.form.files.length; i++) {
          $.form.generateFilesForm.append('files', $.form.files[i]);
        }
        for (var j = 0; j < $.form.dimensions.length; j++) {
          $.form.generateFilesForm.append('dimensions', $.form.dimensions[j]);
        }
        $.postGenerateFiles();
      } else {
        for (var i = 0; i < $.form.snapshots.length; i++) {
          $.form.generateFilesForm = new FormData();
          $.form.generateFilesForm.append('snapshot', $.form.snapshots[i]);
          for (var j = 0; j < $.form.files.length; j++) {
            $.form.generateFilesForm.append('files', $.form.files[j]);
          }
          for (var k = 0; k < $.form.dimensions.length; k++) {
            $.form.generateFilesForm.append('dimensions', $.form.dimensions[k]);
          }
          $.postGenerateFiles();
        }
      }
    },
    downloadListEmpty(metaID) {
      const $ = this;
      for (var i = 0; i < $.downloadForm.list.length; i++) {
        if ($.downloadForm.list[i].startsWith(metaID)) {
          return false;
        }
      }
      return true;
    },
    downloadReports: function (metaID) {
      const $ = this;
      var dList = [];
      for (var i = 0; i < $.downloadForm.list.length; i++) {
        if ($.downloadForm.list[i].startsWith(metaID)) {
          dList.push(`${$.reportsPath}/${$.downloadForm.list[i]}.zip`);
        }
      }

      var i = 0;
      var ref = setInterval(() => {
        $.downloadForm.path = dList[i];
        document.getElementById('download-btn').click();
        if (i == dList.length) clearInterval(ref);
        i++;
      }, 1000);
    },
    postGenerateFiles: function () {
      const $ = this;
      $.form.err = null;
      $.status.isFullLoading = true;

      apiReportGenerateFiles($.form.generateFilesForm)
        .then(function () {
          $.resetQueryForm();
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            $.form.err = error.response.data.msg;
          } else {
            $.form.err = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        })
        .finally(function () {
          $.getAllReportStatus();
          $.status.isFullLoading = false;
        });
    },
    checkSplitCSVCheckedCnt: function (metaID, splitCSVs) {
      const $ = this;
      var cnt = 0;
      for (var i = 0; i < splitCSVs.length; i++) {
        const value = `${metaID}/${splitCSVs[i].file.split('.')[0]}`;
        const index = $.downloadForm.list.indexOf(value);
        if (index !== -1) {
          cnt += 1;
        }
      }
      return cnt === splitCSVs.length;
    },
    appendDownloadList: function (event, metaID, splitCSVs) {
      const $ = this;

      if (event.target.checked) {
        for (var i = 0; i < splitCSVs.length; i++) {
          const value = `${metaID}/${splitCSVs[i].file.split('.')[0]}`;
          const index = $.downloadForm.list.indexOf(value);
          if (index === -1) {
            $.downloadForm.list.push(value);
          }
        }
      } else {
        for (var i = 0; i < splitCSVs.length; i++) {
          const value = `${metaID}/${splitCSVs[i].file.split('.')[0]}`;
          const index = $.downloadForm.list.indexOf(value);
          if (index !== -1) {
            $.downloadForm.list.splice(index, 1);
          }
        }
      }
    },
    checkSplitCSVStatus: function (splitCSVs) {
      for (var i = 0; i < splitCSVs.length; i++) {
        if (splitCSVs[i].status === 'succeeded') {
          return true;
        }
      }

      return false;
    },
    deleteReportMetaConfirm: function (metaID) {
      const $ = this;
      $.deleteForm.metaID = metaID;
      $.dialog.type = 'ReportDeleteConfirm';
    },
    deleteReportMeta: function () {
      const $ = this;
      $.deleteForm.err = null;

      apiReportDelete({
        id: $.deleteForm.metaID,
      })
        .then(function (response) {
          $.getAllReportStatus();
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            $.deleteForm.err = `${metaID} - ${error.response.data.msg}`;
          } else {
            $.deleteForm.err = `${metaID} - ${Object.getOwnPropertyDescriptor(error, 'message').value}`;
          }
        })
        .finally(function () {
          $.deleteForm.metaID = null;
          $.dismissDialog();
        });
    },
    resetQueryForm: function () {
      this.form.filenames = [];
      this.form.files = [];
      this.form.snapshots = [];
      this.form.dimensions = [];
      this.form.generateFilesForm = new FormData();
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
/* override default multiselect styles */
.multiselect__option--group {
  background: #fff !important;
  color: #35495e;
}

.multiselect__option--group-selected {
  background: #ededed !important;
}

.multiselect__option--highlight {
  background: #ededed !important;
  color: #35495e !important;
}

.multiselect__option--highlight:after {
  background: #ededed !important;
  color: #35495e !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ededed !important;
  color: #35495e !important;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ededed !important;
  color: #35495e !important;
}
</style>
<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #eaeaea;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.main-item {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.query__block {
  position: relative;
  background-color: #ffffff;
  padding: 30px 40px;
}

.query__block--mask {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
  font-size: 14px;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}

.status__meta__block {
  background-color: #ffffff;
  padding: 30px 40px;
}

.status__meta__content {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.query__files {
  display: flex;
  flex-direction: column;
}

.query__file {
  display: flex;
  width: 50%;
  line-height: 40px;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  padding: 0 20px;
}

.query__file--invalid {
  border: 1px solid #870136;
}

.query__file--name {
  width: calc(100% - 12px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.query__file--delete {
  line-height: 40px;
  color: #d8d8d8;
  cursor: pointer;
}

.query__file--alert {
  width: 50%;
  font-size: 12px;
  text-align: left;
  color: #870136;
  padding: 0 0 0 20px;
}

.query__action--hint {
  font-size: 12px;
  color: #aeaeae;
}

.query__action__item--dimension {
  user-select: none;
}

.query__error {
  font-size: 12px;
  color: #870136;
  text-align: center;
}

.status__meta__block--error {
  color: #870136;
  text-align: center;
}

.status__meta__block--empty {
  text-align: center;
}

.status__action__title,
.status__meta__title {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.status__action {
  display: flex;
}

.status__action__left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.status__action__right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.status__action__dateblock--hint {
  font-size: 12px;
  text-align: center;
}

.status__action__dateblock--err {
  color: #870136;
}

.status__meta--arrow {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.status__content__row {
  display: flex;
  align-items: center;
  justify-content: left;
}

.status__content__block--head {
  height: 30px;
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.status__content__block--content {
  min-height: 60px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

.status__content__block--item {
  padding: 0 5px;
  overflow: hidden;
}

.status__content__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.status__content__delete {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status__content__delete--error {
  font-size: 12px;
  color: #870136;
  text-align: center;
}

.status__content__block--checkbox {
  width: 40px;
}

.status__content__block--filename {
  flex: 2;
}

.status__content__block--ctime,
.status__content__block--status,
.status__content__block--msg {
  flex: 1;
}

.search__left__block.active,
.search__right__block.active {
  border: 10px solid #640061;
}

.search__block {
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search__left__block,
.search__right__block {
  box-sizing: border-box;
  flex-basis: calc((100% - 50px) / 2);
  max-width: 400px;
  height: 400px;
  background-color: #ffffff;
  padding: 0 40px;
  text-align: center;
}

.multiple__search__result__block {
  display: flex;
  flex-basis: 100%;
  max-width: 560px;
  flex-direction: column;
}

input[type='file'] {
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
}

.reports__download__btn {
  display: none;
}

.query__title--icon {
  font-size: 20px;
}

.query__block__step1--content,
.query__block__step2--content,
.query__block__step3--content,
.query__block__step4--content {
  margin: 0 0 0 4px;
  padding: 20px;
}

.query__block__step1--content,
.query__block__step2--content,
.query__block__step3--content {
  border-left: 3px dashed #000;
}

.query__submit {
  margin: 0;
}
</style>
