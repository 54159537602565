<template>
  <div class="loader">
    <div class="loader-box">
      <div id="largeBox"></div>
      <div id="smallBox"></div>
    </div>
    <div class="loading__desc mt-10">{{ $t('login_loading') }}</div>
  </div>
</template>

<script>
export default {
  name: 'InlineLoading',
};
</script>

<style>
.loader {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.loader-box {
  position: relative;
  left: calc((100% - 20px) / 2);
  width: 20px;
  height: 20px;
  animation: loaderAnim 1.25s infinite ease-in-out;
  outline: 1px solid transparent;
}

#largeBox {
  height: 20px;
  width: 20px;
  /* background-color: #ffffff; */
  background-image: linear-gradient(to bottom, #640061, rgba(100, 0, 97, 0.5));
  outline: 1px solid transparent;
  position: fixed;
}

#smallBox {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  /* background-image: linear-gradient(to bottom, #640061, rgba(100, 0, 97, 0.5)); */
  position: fixed;
  z-index: 1;
  outline: 1px solid transparent;
  animation: smallBoxAnim 1.25s alternate infinite ease-in-out;
}

@keyframes smallBoxAnim {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(0.75);
  }
}

@keyframes loaderAnim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.loading__desc {
  font-size: 12px;
  color: #640061;
}
</style>
