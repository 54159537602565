export const IDENTITY_REPORT = {
  FACTORS_CATEGORY: {
    credit: {
      info_special_tag: '1_number_info',
      info_special_categ: '1_number_info',
      info_spam_categ: '1_number_info',
      info_yp_categ: '1_number_info',
      info_yp_tag: '1_number_info',
      status_verify_yn: '1_number_info',
      status_has_abnormal_pattern_2_yn_1y: '2_activity_track',
      status_reachable_count_3y: '2_activity_track',
      status_social_node_count: '2_activity_track',
      status_reachable_count_1y: '2_activity_track',
      status_last_call_activity_1st_day_range_3y: '2_activity_track',
      call_compare_w_else_z_score_6m: '2_activity_track',
      status_verify_yn_2: '2_activity_track',
      cluster_fin_high_interest_loans_qc_count_in_6m: '3_special_fin',
      cluster_fin_high_interest_loans_qc_count_out_6m: '3_special_fin',
      cluster_fin_installment_qc_count_in_6m: '3_special_fin',
      cluster_fin_installment_qc_count_out_6m: '3_special_fin',
      cluster_fin_dummy_account_recruitment_qc_count_in_6m: '3_special_fin',
      cluster_fin_dummy_account_recruitment_qc_count_out_6m: '3_special_fin',
      cluster_fin_dummy_account_recruitment_total_count_in_6m: '3_special_fin',
      cluster_fin_special_career_qc_count_in_6m: '3_special_fin',
      cluster_fin_special_career_total_count_out_6m: '3_special_fin',
      cluster_fin_special_career_total_count_in_6m: '3_special_fin',
      cluster_fin_credit_consultants_qc_count_in_6m: '3_special_fin',
      cluster_fin_credit_consultants_qc_count_out_6m: '3_special_fin',
      cluster_fin_credit_consultants_total_count_out_6m: '3_special_fin',
      cluster_fin_credit_consultants_total_count_in_6m: '3_special_fin',
      cluster_fin_high_interest_loans_total_count_out_6m: '3_special_fin',
      cluster_fin_pawn_shop_qc_count_in_6m: '3_special_fin',
      cluster_fin_pawn_shop_qc_count_out_6m: '3_special_fin',
      cluster_fin_installment_qc_count_out_1m: '3_special_fin',
      cluster_fin_installment_qc_count_in_1m: '3_special_fin',
      cluster_fin_pawn_shop_duration_sum_out_6m: '3_special_fin',
      cluster_fin_pawn_shop_total_count_in_6m: '3_special_fin',
      cluster_fin_dummy_account_recruitment_total_compare_w_else_z_score_in_6m: '3_special_fin',
      cluster_fin_special_career_qc_count_out_6m: '3_special_fin',
      cluster_fin_credit_consultants_total_compare_w_else_z_score_in_6m: '3_special_fin',
      cluster_fin_high_interest_loans_total_compare_w_else_z_score_in_6m: '3_special_fin',
      cluster_fin_installment_total_compare_w_else_z_score_in_6m: '3_special_fin',
      cluster_fin_pawn_shop_total_compare_w_else_z_score_in_6m: '3_special_fin',
      cluster_fin_debt_collection_qc_count_out_6m: '4_debt_collection_all',
      cluster_fin_debt_collection_qc_count_in_6m: '4_debt_collection_all',
      cluster_fin_debt_collection_total_count_out_1m: '4_debt_collection_all',
      cluster_fin_debt_collection_qc_count_in_1m: '4_debt_collection_all',
      cluster_fin_credit_card_debt_collection_qc_count_in_6m: '5_debt_collection_finance_inst',
      cluster_fin_credit_card_debt_collection_total_count_out_6m: '5_debt_collection_finance_inst',
      cluster_fin_credit_card_debt_collection_total_count_in_6m: '5_debt_collection_finance_inst',
      cluster_fin_debt_collection_agency_qc_count_in_6m: '6_debt_collection_finance_second',
      cluster_fin_debt_collection_agency_qc_count_out_6m: '6_debt_collection_finance_second',
      cluster_fin_installment_collection_qc_count_in_6m: '6_debt_collection_finance_second',
      cluster_fin_installment_collection_qc_count_out_6m: '6_debt_collection_finance_second',
      cluster_fin_car_loan_debt_collection_qc_count_in_6m: '6_debt_collection_finance_second',
      cluster_fin_shark_loan_debt_collection_qc_count_in_6m: '6_debt_collection_finance_second',
      cluster_fin_car_loan_debt_collection_total_count_out_6m: '6_debt_collection_finance_second',
      cluster_fin_car_loan_debt_collection_total_count_in_6m: '6_debt_collection_finance_second',
      cluster_fin_phone_bill_collection_qc_count_out_6m: '7_debt_collection_life',
      cluster_fin_phone_bill_collection_qc_count_in_6m: '7_debt_collection_life',
      cluster_fin_phone_bill_collection_duration_sum_out_6m: '7_debt_collection_life',
      cluster_fin_loans_qc_count_out_6m: '8_finance',
      cluster_fin_third_party_payment_qc_count_out_6m: '8_finance',
      cluster_fin_mortgage_qc_count_out_6m: '8_finance',
      cluster_fin_credit_card_telemarketing_qc_count_out_6m: '8_finance',
      cluster_fin_car_loan_qc_count_out_6m: '9_life',
      cluster_life_legal_consultancy_service_qc_count_in_6m: '9_life',
      cluster_life_legal_consultancy_service_qc_count_out_6m: '9_life',
      cluster_life_job_hunting_qc_count_out_6m: '9_life',
      cluster_life_temp_agencies_qc_count_in_6m: '9_life',
      cluster_life_temp_agencies_qc_count_out_6m: '9_life',
      cluster_life_car_rental_qc_count_in_6m: '9_life',
      cluster_life_prison_qc_count_out_6m: '9_life',
      cluster_life_beetle_nut_shop_qc_count_out_6m: '9_life',
      cluster_spam_telemarketing_qc_count_out_6m: '10_spam',
      cluster_spam_telemarketing_qc_count_in_6m: '10_spam',
      cluster_spam_harassment_qc_count_out_6m: '10_spam',
      cluster_spam_harassment_qc_count_in_6m: '10_spam',
      rank: 'credit_risk',
    },
    marketing: {
      cluster_spam_telemarketing_total_count_in_6m: '1_cluster_spam',
      cluster_spam_telemarketing_total_count_out_6m: '1_cluster_spam',
      device_wifi_network_count_6m: '2_device',
      rank: 'credit_risk',
    },
  },
};
