import Cookies from 'js-cookie';

import {
  apiCheckUserStatus,
  apiLicenseQuotaRemain,
  apiLicenseStatus,
  apiLogout,
  apiSystemVersion,
  apiSystemStatus,
} from './api.js';
import pathJoin from './path_join.js';

export const utils = {
  methods: {
    containsKey: function (obj, key) {
      return Object.keys(obj).includes(key);
    },
    isOnLoginPage: function () {
      return this.$route.meta.requiresAuth;
    },
    checkBrandIDFormat: function (brandID) {
      const regexBrandID = /^[a-zA-Z0-9\-_]+$/;

      if (brandID && !regexBrandID.test(brandID)) {
        return true;
      }

      return false;
    },
    checkMailFormat: function (mail) {
      const regexMail = /^([\w_.+-])+@[\w-.]+\.+[\w]+$/;

      if (mail && !regexMail.test(mail)) {
        return true;
      }

      return false;
    },
    checkPasswordFormat: function (password) {
      if (password.length > 64 || password.length < 8) {
        return true;
      }

      const forbiddenChars = /[\s\t\n\r]/.test(password);
      if (forbiddenChars) {
        return true;
      }

      const hasLetter = /[a-zA-Z]/.test(password);
      const hasNumber = /[0-9]/.test(password);
      const hasSpecialChar = /[^a-zA-Z0-9]/.test(password);

      const typesCount = [hasLetter, hasNumber, hasSpecialChar].filter(Boolean).length;

      if (typesCount === 3 && password.length >= 8) {
        return false;
      } else if (typesCount === 2 && password.length >= 10) {
        return false;
      }

      return true;
    },
    checkPasswordEqual: function (password, passwordAgain) {
      if (passwordAgain && password !== passwordAgain) {
        return true;
      }

      return false;
    },
    checkNumberFormat: function (number) {
      const regexNumber = /^[0-9+-\s]+$/;

      if (!regexNumber.test(number)) {
        return true;
      }

      return false;
    },
    updateLicenseStatus: function () {
      const $ = this;

      apiLicenseStatus()
        .then(function (response) {
          let licenseData = response.data;
          $.linkValid = true;

          let scenario = [];
          if ('data_args' in licenseData) {
            for (const [, dimVals] of Object.entries(licenseData.data_args)) {
              for (const [key, value] of Object.entries(dimVals)) {
                if (key === 'scenario') {
                  scenario.push(value);
                }
                if (key === 'display_credit_risk') {
                  licenseData.display_credit_risk = value;
                }
              }
            }
          }
          licenseData.scenario = scenario;

          $.$store.commit('setLicenseData', {
            licenseData,
          });

          if (Array.isArray(licenseData.mail_service)) {
            licenseData.mail_service.forEach((element) => {
              const msData = element;
              $.$store.commit('setMailServiceData', { msData });
            });
          }
        })
        .catch(function (error) {
          alert(error);
        });

      apiSystemStatus()
        .then(function (response) {
          const res = response.data;
          $.$store.commit('setRemainDisk', res.remain_size);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    updateRemainLicenseQuota: function () {
      const $ = this;

      apiLicenseQuotaRemain()
        .then(function (response) {
          const quotaData = {
            remain_quota: response.data.remain_quota,
            unlimited_quota: response.data.unlimited_quota,
          };
          $.$store.commit('setLicenseRemainQuota', { quotaData });
        })
        .catch(function (error) {
          alert(error);
        });
    },
    logout: function () {
      const $ = this;

      if (process.env.VUE_APP_VERSION === 'offline') {
        Cookies.remove('session');
        $.$router.push('/user/login/');
      } else {
        apiLogout()
          .then(function (response) {
            const res = response.data;

            if (res.msg === 'OK') {
              $.$router.push('/user/login/');
            }
          })
          .catch(function (error) {
            alert(error);
          });
      }
    },
    checkUserStatus: function () {
      const $ = this;

      if (process.env.VUE_APP_VERSION === 'offline') {
        if (Cookies.get('session')) {
          $.$router.push('/');
        }
      } else {
        apiCheckUserStatus()
          .then(function (response) {
            const res = response.data;

            if (res.msg === 'OK') {
              $.$router.push('/');
            }
          })
          .catch(function (error) {
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          });
      }
    },
    pathJoin: function (...args) {
      return pathJoin(...args);
    },
    updateSystemVersion: function () {
      const $ = this;

      apiSystemVersion()
        .then(function (response) {
          const versionData = response.data;
          $.$store.commit('setSystemVersion', { versionData });
        })
        .catch(function (error) {
          alert(error);
        });
    },
    checkDataSetFileNameFormat: function (fileName) {
      const brand_id = this.$store.state.user.brand_id.substring(0, 3).toUpperCase();
      const dataset = this.$store.state.license.dataset;
      const regexFileName = new RegExp(`^1_${brand_id}\\d{10}_${dataset}.zip`);

      if (fileName && !regexFileName.test(fileName)) {
        return true;
      }

      return false;
    },
    checkCustomTableFileNameFormat: function (fileName, dimension) {
      const brand_id = this.$store.state.user.brand_id.substring(0, 3).toUpperCase();
      const regexFileName = new RegExp(`^0_${brand_id}\\d{10}_${dimension}.zip`);

      if (fileName && !regexFileName.test(fileName)) {
        return true;
      }

      return false;
    },
    isQueryValidDataset: function () {
      const validDataset = ['bank', 'rental', 'identity_report'];
      if (validDataset.includes(this.$store.state.license.dataset)) {
        return true;
      }
      return false;
    },
    isReportValidDataset: function () {
      const validDataset = ['das', 'dfs'];
      if (validDataset.includes(this.$store.state.license.dataset)) {
        return true;
      }
      return false;
    },
    isOnline: function () {
      return process.env.VUE_APP_VERSION === 'online';
    },
    isOffline: function () {
      return process.env.VUE_APP_VERSION === 'offline';
    },
    sortingIdentityReportFactors: function (key1, key2) {
      let reg = /^(\d+)_.*$/;
      let num1 = key1.replace(reg, '$1');
      let num2 = key2.replace(reg, '$1');
      return num1 - num2;
    },
  },
};
