<template>
  <section class="header">
    <div class="header__icon">
      <img class="witcher__header__logo" :src="`${$baseUrl}witcher_header_logo.png`" />
    </div>
    <div class="header__left">
      <span class="header__title ml-40">{{ title }}</span>
    </div>
    <div class="header__right">
      <div class="header__polygon"></div>
      <div class="header__text">
        <span class="header__sayhi pr-20">{{ $t('header_bar_hello', { username: $store.state.user.name }) }}</span>
        <span class="header__logout" v-on:click="showDialogConfirm">{{ $t('header_bar_logout') }}</span>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" v-if="dialog.type == 'LogoutConfirm'">
        <div class="dialog__title">{{ $t('logout_confirm_dialog') }}</div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialog">{{
            $t('logout_confirm_dialog_cancel')
          }}</a>
          <a class="main-button dialog__button ml-20" v-on:click="$router.push('/user/logout/')">{{
            $t('logout_confirm_dialog_confirm')
          }}</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      dialog: {
        type: null,
      },
    };
  },
  props: {
    title: String,
    name: String,
  },
  methods: {
    showDialogConfirm: function () {
      const $ = this;

      $.dialog.type = 'LogoutConfirm';
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  display: flex;
  flex-basis: 100%;
  height: 60px;
  top: 0;
  background-color: #640061;
  color: #ffffff;
  line-height: 60px;
  overflow: hidden;
}

.header__icon {
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__left {
  flex-basis: 60%;
  text-align: left;
}

.header__right {
  position: relative;
  flex-basis: 20%;
}

.header__polygon {
  position: absolute;
  height: 0;
  width: 100%;
  border-left: 80px solid transparent;
  border-top: 150px solid #3d003c;
  top: 0;
  right: 0;
}

.header__title {
  font-size: 24px;
  color: #ffffff;
}

.header__text {
  position: absolute;
  display: flex;
  width: 100%;
  height: 60px;
  z-index: 2;
}

.header__sayhi {
  box-sizing: border-box;
  flex-basis: calc(100% - 120px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
}

.header__logout {
  cursor: pointer;
  padding-right: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
