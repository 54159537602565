<template>
  <div class="site-main">
    <div class="message-block">
      <full-page-message :type="message.type"></full-page-message>
    </div>
  </div>
</template>

<script>
import FullPageMessage from './partials/FullPageMessage.vue';

export default {
  name: 'UnsupportBrowser',
  data() {
    return {
      message: {
        type: 'UnsupportedBrowser',
      },
    };
  },
  components: {
    'full-page-message': FullPageMessage,
  },
};
</script>
