<template>
  <div class="site-main">
    <div v-if="message.type" class="message-block">
      <full-page-message :type="message.type" :message="message.text"></full-page-message>
    </div>
    <div v-if="status.linkValid && !message.type" class="main-block">
      <app-sidebar></app-sidebar>
      <div class="main-content">
        <div class="lang-item">
          <lang-selector></lang-selector>
        </div>
        <div class="main-item">
          <img class="witcher__logo" :src="`${$baseUrl}witcher_logo.png`" />
        </div>
        <div class="main-item mt-40">
          <div class="page__title">{{ $t('license_active_page_title') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('license_active_col_companyid') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.brandIDError }"
            v-model.trim="form.brandID"
            v-on:blur="status.brandIDError = checkBrandIDFormat(form.brandID)"
            v-on:focus="
              status.brandIDError = null;
              message.apiError = null;
            "
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.brandIDError" class="form__error">{{ $t('license_active_client_companyid_format') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('license_active_col_admin_email') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.mailError }"
            v-model.trim="form.adminEmail"
            v-on:blur="status.mailError = checkMailFormat(form.adminEmail)"
            v-on:focus="
              status.mailError = null;
              message.apiError = null;
            "
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.mailError" class="form__error">{{ $t('license_active_client_email_format') }}</div>
        </div>
        <div class="main-item mt-30">
          <div class="form__title">{{ $t('license_active_col_license_code') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-model="form.license"
            v-on:focus="message.apiError = null"
          />
        </div>
        <div class="main-item mt-60">
          <a class="long-button" :disabled="!isComplete" v-on:click="postLicenseActive">{{ $t('register_button') }}</a>
        </div>
        <div class="main-item mt-20" v-if="message.apiError">
          <div class="form__api__error">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiLicenseActive, apiRegCodeStatus } from '../api.js';
import FullPageMessage from './partials/FullPageMessage.vue';
import InlineLoading from './partials/InlineLoading.vue';
import Sidebar from './base/sidebar.vue';
import LangSelector from './partials/LangSelector.vue';

export default {
  name: 'LicenseActive',
  data() {
    return {
      status: {
        linkValid: false,
        brandIDError: false,
        mailError: false,
        isLoading: false,
      },
      form: {
        code: this.$route.query.rc,
        brandID: null,
        adminEmail: null,
        license: null,
      },
      message: {
        type: null,
        text: null,
        apiError: null,
      },
    };
  },
  components: {
    'app-sidebar': Sidebar,
    'full-page-message': FullPageMessage,
    'inline-loading': InlineLoading,
    'lang-selector': LangSelector,
  },
  created() {
    const $ = this;

    if ($.form.code && $.form.code.length === 6) {
      apiRegCodeStatus({
        code: $.form.code,
      })
        .then(function (response) {
          const res = response.data;

          if (res.msg === 'OK') {
            $.status.linkValid = true;
          } else {
            $.message.type = 'LicenseCodeError';
            $.message.text = `%0D%0A%0D%0A---%0D%0ALicense code: ${$.form.code}%0D%0AStatus Code: 4`;
          }
        })
        .catch(function (error) {
          $.message.type = 'APIError';
          $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
        });
    } else {
      $.message.type = 'LicenseCodeError';
      $.message.text = `%0D%0A%0D%0A---%0D%0ALicense code: ${$.form.code}%0D%0AStatus Code: null`;
    }
  },
  computed: {
    isComplete() {
      return (
        !this.status.brandIDError &&
        !this.status.mailError &&
        !this.status.isLoading &&
        this.form.brandID &&
        this.form.adminEmail &&
        this.form.license
      );
    },
  },
  methods: {
    postLicenseActive: function () {
      const $ = this;
      $.status.isLoading = true;
      $.message.apiError = null;

      apiLicenseActive({
        code: $.form.code,
        brand_name: $.form.brandID,
        admin_email: $.form.adminEmail,
        license: $.form.license,
      })
        .then(function (response) {
          const res = response.data;

          $.message.type = 'LicenseActivatedSuccess';
          $.status.isLoading = false;
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            const errMsg = error.response.data.msg;
            if (errMsg === 'LICENSE_INVALID') {
              $.message.apiError = $.$i18n.t('license_active_error_400_13');
            } else if (errMsg === 'LICENSE_EXPIRED') {
              $.message.apiError = $.$i18n.t('license_active_error_400_14');
            } else if (errMsg === 'MISMATCH_INFO') {
              $.message.apiError = $.$i18n.t('license_active_error_400_15');
            } else if (errMsg === 'REG_CODE_NOT_MATCH') {
              $.message.type = 'LicenseCodeError';
              $.message.text = `%0D%0A%0D%0A---%0D%0ALicense code : ${$.form.code}%0D%0ACompany ID: ${$.form.brandID}%0D%0AEmail: ${$.form.adminEmail}%0D%0ALicense: ${$.form.license}%0D%0AStatus code: 22`;
            } else if (errMsg === 'REG_CODE_EXPIRED') {
              $.message.type = 'LicenseCodeError';
              $.message.text = `%0D%0A%0D%0A---%0D%0ALicense code : ${$.form.code}%0D%0ACompany ID: ${$.form.brandID}%0D%0AEmail: ${$.form.adminEmail}%0D%0ALicense: ${$.form.license}%0D%0AStatus code: 23`;
            } else {
              $.message.type = 'APIError';
              $.message.text = errMsg;
            }
          } else {
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          }

          $.status.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.main-block {
  display: flex;
  height: 100vh;
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #ffffff;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
  align-items: center;
}

.main-item {
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.lang-item {
  width: 160px;
  position: absolute;
  top: 40px;
  right: 60px;
}
</style>
