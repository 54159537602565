<template>
  <div class="site-main">
    <div style="position: absolute; top: -100px">
      <input type="text" title="Chaff for Chrome Smart Lock" />
      <input type="password" title="Chaff for Chrome Smart Lock" />
    </div>
    <app-header></app-header>
    <div class="main-block">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div class="main-content">
        <div class="main-item manage__info">
          <div class="action__block">
            <a class="main-button" :disabled="!isMemberCheck" v-on:click="deleteUserConfirm">{{
              $t('member_delete_button')
            }}</a>
            <a class="main-button ml-20" :disabled="!isMemberFull" v-on:click="addUser">{{
              $t('member_invite_button')
            }}</a>
            <div class="remain__user">
              {{
                $t('member_info_remain_account', {
                  remain_member: licenseData.remain_account,
                  acc_limit: licenseData.account,
                })
              }}
            </div>
          </div>
        </div>
        <div class="main-item mt-40">
          <div class="user__block">
            <div class="user__block__list user__block__head">
              <div class="user__block__list-item user-checkbox"></div>
              <div class="user__block__list-item" v-bind:class="{ 'user-email': version === 'online' }">
                {{ $t('member_table_head_account') }}
              </div>
              <div class="user__block__list-item" v-bind:class="{ 'user-role': version === 'online' }">
                {{ $t('member_table_head_role') }}
              </div>
              <div class="user__block__list-item" v-bind:class="{ 'user-info': version === 'online' }">
                {{ $t('member_table_head_last_login') }}
              </div>
              <div v-if="version === 'online'" class="user__block__list-item user-status">
                {{ $t('member_table_head_status') }}
              </div>
              <div
                v-if="hasSendMailInAPI && isReportValidDataset()"
                class="user__block__list-item"
                v-bind:class="{ 'user-info': version === 'online' }"
                v-html="$t('member_table_head_search_noti_mails')"
              ></div>
            </div>
            <div class="user__block__list user__block__content" v-for="(item, index) in userData" v-bind:key="index">
              <div class="user__block__list-item user-checkbox">
                <input
                  type="checkbox"
                  id="checkbox"
                  v-model="userDataChecked"
                  :value="{ uuid: item.uuid, email: item.email }"
                  v-if="item.role === 'user'"
                />
              </div>
              <div class="user__block__list-item" v-bind:class="{ 'user-email': version === 'online' }">
                {{ item.email }}
              </div>
              <div
                class="user__block__list-item"
                v-bind:class="{ 'user-role': version === 'online' }"
                v-if="item.role === 'admin'"
              >
                {{ $t('member_role_admin') }}
              </div>
              <div class="user__block__list-item" v-bind:class="{ 'user-role': version === 'online' }" v-else>
                {{ $t('member_role_user') }}
              </div>
              <div class="user__block__list-item" v-bind:class="{ 'user-info': version === 'online' }">
                {{ item.last_login | dtFormat }}
              </div>
              <div v-if="version === 'online'" class="user__block__list-item user-status">
                <div v-if="item.status === 1">{{ $t('member_status_1') }}</div>
                <div v-else-if="item.status === 2">
                  <span class="mr-20">{{ $t('member_status_2') }}</span
                  ><span class="reset-btn"
                    ><a class="small-button" v-on:click="resentInvite(item.email)">{{
                      $t('member_reinvite_button')
                    }}</a></span
                  >
                </div>
                <div v-else>{{ $t('member_status_4') }}</div>
              </div>
              <div
                class="user__block__list-item"
                v-if="hasSendMailInAPI && isReportValidDataset()"
                v-bind:class="{ 'user-info': version === 'online' }"
              >
                <div v-for="cc in item.query_recipients" :key="cc.id">{{ cc }}<br /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'UserManageAdd' && version === 'online'">
        <div class="dialog__title">{{ $t('member_invite_dialog_title') }}</div>
        <div class="dialog__desc input__title main-item mt-20">{{ $t('member_invite_dialog_desc') }}</div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.mailError }"
            v-model.trim="form.inviteEmail"
            v-on:blur="status.mailError = checkMailFormat(form.inviteEmail)"
            v-on:focus="
              status.mailError = null;
              message.apiError = null;
            "
            v-on:keyup.enter="postUserInvite"
          />
        </div>
        <div v-if="status.mailError" class="main-item mt-6">
          <div class="form__error input__error">{{ $t('member_invite_dialog_client_email_format') }}</div>
        </div>
        <div class="main-item mt-60">
          <a class="long-button" :disabled="!isComplete" v-on:click="postUserInvite">{{
            $t('member_invite_dialog_button')
          }}</a>
        </div>
        <div class="main-item mt-20">
          <div v-if="message.apiError" class="form__api__error">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
        <div class="dialog__cancel icon-close" v-on:click="dismissDialog"></div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'UserManageAdd' && version === 'offline'">
        <div class="dialog__title">{{ $t('member_invite_dialog_title') }}</div>
        <div class="dialog__desc input__title main-item mt-20">{{ $t('member_invite_dialog_desc_offline') }}</div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            maxlength="50"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.mailError }"
            v-model.trim="form.inviteEmail"
            v-on:blur="status.mailError = checkMailFormat(form.inviteEmail)"
            v-on:focus="
              status.mailError = null;
              message.apiError = null;
            "
            v-on:keyup.enter="postUserInvite"
          />
        </div>
        <div v-if="status.mailError" class="main-item mt-6">
          <div class="form__error input__error">{{ $t('member_invite_dialog_client_email_format') }}</div>
        </div>
        <div class="main-item mt-20">
          <div class="dialog__desc input__title main-item mt-20">{{ $t('register_col_username') }}</div>
        </div>
        <div class="main-item mt-6">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-model="offlineForm.inviteUserName"
            maxlength="50"
            v-on:focus="message.apiError = null"
            v-on:keyup.enter="postUserInvite"
          />
        </div>
        <div class="main-item mt-30">
          <div class="dialog__desc input__title main-item mt-20">{{ $t('register_col_role') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="text"
            v-bind:class="{ 'has-loading': status.isLoading }"
            v-model="offlineForm.inviteUserRole"
            disabled="disabled"
          />
        </div>
        <div class="main-item mt-30">
          <div class="dialog__desc input__title main-item mt-20">{{ $t('register_col_set_pwd') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="password"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.passwordError }"
            v-model="offlineForm.inviteUserPassword"
            v-on:blur="
              status.passwordError = checkPasswordFormat(offlineForm.inviteUserPassword);
              status.passwordAgainError = checkPasswordEqual(
                offlineForm.inviteUserPassword,
                offlineForm.inviteUserPasswordAgain
              );
            "
            v-on:focus="
              status.passwordError = null;
              message.apiError = null;
            "
            v-on:keyup.enter="postUserInvite"
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.passwordError" class="form__error">{{ $t('register_client_diff_pwd') }}</div>
        </div>
        <div class="main-item mt-6">
          <div class="form__hint" v-html="$t('register_desc_set_pwd')"></div>
        </div>
        <div class="main-item mt-30">
          <div class="dialog__desc input__title main-item mt-20">{{ $t('register_col_set_pwd_check') }}</div>
        </div>
        <div class="main-item mt-10">
          <input
            class="form__input"
            type="password"
            v-bind:class="{ 'has-loading': status.isLoading, 'has-error': status.passwordAgainError }"
            v-model="offlineForm.inviteUserPasswordAgain"
            @blur="checkPasswordEqual"
            v-on:blur="
              status.passwordAgainError = checkPasswordEqual(
                offlineForm.inviteUserPassword,
                offlineForm.inviteUserPasswordAgain
              )
            "
            v-on:focus="
              status.passwordAgainError = null;
              message.apiError = null;
            "
            v-on:keyup.enter="postUserInvite"
          />
        </div>
        <div class="main-item mt-6">
          <div v-if="status.passwordAgainError" class="form__error">{{ $t('register_client_diff_pwd') }}</div>
        </div>
        <div class="main-item mt-60">
          <a class="long-button" :disabled="!isOfflineComplete" v-on:click="postUserInvite">{{
            $t('member_invite_dialog_button_offline')
          }}</a>
        </div>
        <div class="main-item mt-20">
          <div v-if="message.apiError" class="form__api__error">{{ message.apiError }}</div>
        </div>
        <div v-if="status.isLoading" class="main-item mt-30">
          <inline-loading></inline-loading>
        </div>
        <div class="dialog__cancel icon-close" v-on:click="dismissDialog"></div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'UserAddDisabled'">
        <div class="dialog__title">{{ $t('member_invite_error_400_17_title ') }}</div>
        <div
          class="dialog__desc mt-20"
          v-html="$t('member_invite_error_400_17_desc', { contactus_business_email: $t('contactus_business_email') })"
        ></div>
        <div class="main-item mt-60">
          <a class="long-button" v-on:click="dismissDialog">{{ $t('member_invite_error_400_17_button') }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'UserInviteAlreadyRegistered'">
        <div class="dialog__title">{{ $t('member_invite_error_400_24_title') }}</div>
        <div class="main-item mt-60">
          <a class="long-button" v-on:click="dismissDialog">{{ $t('member_invite_error_400_24_button') }}</a>
        </div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'UserInviteSuccess'">
        <div class="dialog__title">{{ $t('member_invite_200_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('member_invite_200_desc') }}</div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'UserReinviteSuccess'">
        <div class="dialog__title">{{ $t('member_reinvite_200_invite_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('member_reinvite_200_invite_desc') }}</div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'UserInviteError'">
        <div class="dialog__title">Error</div>
        <div class="dialog__desc">{{ $t('member_reinvite_error_400_25') }}</div>
      </div>
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'UserDeleteConfirm'">
        <div class="dialog__title">{{ $t('member_delete_dialog_title') }}</div>
        <div class="dialog__desc mt-20">{{ $t('member_delete_confirm_title') }}</div>
        <div class="dialog__btns mt-60">
          <a class="second-button dialog__button" v-on:click="dismissDialog">{{
            $t('member_delete_confirm_button_cancel')
          }}</a>
          <a class="main-button dialog__button ml-20" v-on:click="deleteUser">{{
            $t('member_delete_confirm_button_confirm')
          }}</a>
        </div>
      </div>
    </div>
    <div class="full-loading" v-if="status.isFullLoading">
      <full-page-loading></full-page-loading>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import sha256 from 'js-sha256';
import URI from 'urijs';

import { apiAccountDelete, apiAccountUserInvite, apiAccountUsers, apiAccountRegister } from '../api.js';
import CustomDialog from './partials/CustomDialog.vue';
import FullPageLoading from './partials/FullPageLoading.vue';
import InlineLoading from './partials/InlineLoading.vue';
import Header from './base/header.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'UserManage',
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      hasSendMailInAPI: /^true$/i.test(process.env.VUE_APP_HAS_SEND_MAIL_IN_API),
      licenseData: {
        account: this.$store.state.license.license_account,
        remain_account: this.$store.state.license.license_remain_account,
      },
      dialog: {
        type: null,
      },
      userData: [],
      userDataChecked: [],
      message: {
        apiError: null,
      },
      status: {
        mailError: false,
        isFullLoading: false,
        isLoading: false,
        passwordError: false,
        passwordAgainError: false,
      },
      form: {
        inviteEmail: null,
      },
      offlineForm: {
        inviteUserName: null,
        inviteUserRole: 'user',
        inviteUserPassword: null,
        inviteUserPasswordAgain: null,
      },
    };
  },
  components: {
    'app-header': Header,
    'app-sidebar': Sidebar,
    'custom-dialog': CustomDialog,
    'full-page-loading': FullPageLoading,
    'inline-loading': InlineLoading,
  },
  filters: {
    dtFormat: function (datetime) {
      if (!datetime) return '';
      return moment.tz(datetime, 'UTC').tz('Asia/Taipei').format('YYYY-MM-DD HH:mm:ss');
    },
  },
  created() {
    this.getLicenseUsers();
  },
  computed: {
    isMemberCheck() {
      if (this.userDataChecked.length === 0) return false;

      return true;
    },
    isMemberFull() {
      if (this.licenseData.remain_account === 0) return false;

      return true;
    },
    isComplete() {
      return !this.status.mailError && this.form.inviteEmail !== '';
    },
    isOfflineComplete() {
      return (
        !this.status.mailError &&
        !this.checkPasswordFormat(this.offlineForm.inviteUserPassword) &&
        !this.checkPasswordEqual(this.offlineForm.inviteUserPassword, this.offlineForm.inviteUserPasswordAgain) &&
        !this.status.isLoading &&
        this.form.inviteEmail !== '' &&
        this.offlineForm.inviteUserRole &&
        this.offlineForm.inviteUserPassword &&
        this.offlineForm.inviteUserPasswordAgain
      );
    },
  },
  methods: {
    getLicenseUsers: function () {
      const $ = this;

      apiAccountUsers()
        .then(function (response) {
          $.userData = response.data;
          $.refreshRemainAccount();
        })
        .catch(function (error) {
          $.message.type = 'APIError';
          $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
        });
    },
    deleteUserConfirm: function () {
      const $ = this;

      if ($.userDataChecked.length > 0) {
        $.dialog.type = 'UserDeleteConfirm';
      }
    },
    deleteUser: function () {
      const $ = this;
      $.dialog.type = null;
      $.message.apiError = null;
      $.status.isFullLoading = true;

      let postData = null;

      if ($.version === 'offline') {
        postData = [];
        for (var i = 0; i < $.userDataChecked.length; i++) {
          postData.push($.userDataChecked[i]['uuid']);
        }
      } else {
        postData = {
          brand_id: this.$store.state.user.brand_id,
          delete_data: $.userDataChecked,
        };
      }

      apiAccountDelete({
        data: postData,
      })
        .then(function () {
          $.userDataChecked = [];
          $.getLicenseUsers();
          $.status.isFullLoading = false;
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'UNSUPPORT_FORMAT') {
              $.message.apiError = $.$i18n.t('error_400_27');
            } else {
              $.message.apiError = error.response.data.msg;
            }
          } else {
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        });
    },
    addUser: function () {
      const $ = this;

      if ($.licenseData.remain_account === 0) {
        $.dialog.type = 'UserAddDisabled';
      } else {
        $.resetInviteForm();
        $.dialog.type = 'UserManageAdd';
      }
    },
    refreshRemainAccount: function () {
      const remain_account = this.licenseData.account - this.userData.length;
      this.$store.commit('setLicenseUserRemainAccount', remain_account);
      this.licenseData.remain_account = remain_account;
    },
    postUserRegister: function (reg_code) {
      const $ = this;
      $.dismissDialog();
      $.status.isLoading = true;

      let reqBody = new Object();
      reqBody.code = reg_code;
      reqBody.name = $.offlineForm.inviteUserName;
      reqBody.password = sha256($.offlineForm.inviteUserPassword);

      apiAccountRegister(reqBody)
        .then(function () {
          $.status.isLoading = false;
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'INPUT_DATA_INVALID') {
              $.message.apiError = $.$i18n.t('register_error_400_21');
            } else if (error.response.data.msg === 'REG_CODE_NOT_MATCH') {
              $.message.type = 'RegisterLinkInvalid';
            } else if (error.response.data.msg === 'REG_CODE_EXPIRED') {
              $.message.type = 'RegisterCodeExpire';
              $.message.text = `%0D%0A%0D%0A---%0D%0ARegister code : ${$.form.code}%0D%0AToken: ${$.form.token}%0D%0AName: ${$.form.userName}%0D%0AStatus Code: 23`;
            } else if (error.response.data.msg === 'ACCOUNT_ALREADY_REGISTERED') {
              $.message.type = 'AlreadyRegistered';
            } else {
              $.message.apiError = error.response.data.msg;
            }
          } else {
            $.status.isLoading = false;
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        });
    },
    postUserInvite: function () {
      const $ = this;
      $.message.apiError = null;

      if ($.version === 'online' && !$.isComplete) {
        return false;
      } else if ($.version === 'offline' && !$.isOfflineComplete) {
        return false;
      }

      $.status.isLoading = true;
      apiAccountUserInvite({
        invite_email: [$.form.inviteEmail],
      })
        .then(function (response) {
          if ($.version === 'offline') {
            const res = response.data;
            var qs = URI(res['links'][0]).query(true);
            $.postUserRegister(qs.rc);
          } else {
            $.dialog.type = 'UserInviteSuccess';
            $.hideDialogAfterTwoSec();
          }
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'ACCOUNT_ALREADY_REGISTERED') {
              $.dialog.type = 'UserInviteAlreadyRegistered';
            } else if (error.response.data.msg === 'LICENSE_EXCEED_ACC_LIMIT') {
              $.dialog.type = 'UserAddDisabled';
              $.getLicenseUsers();
            } else {
              $.message.apiError = error.response.data.msg;
            }
          } else {
            $.status.isLoading = false;
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        })
        .finally(function () {
          $.getLicenseUsers();
          $.status.isLoading = false;
        });
    },
    dismissDialog: function () {
      const $ = this;

      $.dialog.type = null;
    },
    resentInvite: function (email) {
      const $ = this;
      $.status.isFullLoading = true;

      apiAccountUserInvite({
        invite_email: [email],
      })
        .then(function () {
          $.dialog.type = 'UserReinviteSuccess';
          $.hideDialogAfterTwoSec();
          $.status.isFullLoading = false;
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            if (error.response.data.msg === 'ACCOUNT_ALREADY_REGISTERED') {
              $.dialog.type = 'UserInviteAlreadyRegistered';
            } else if (error.response.data.msg === 'LICENSE_EXCEED_ACC_LIMIT') {
              $.dialog.type = 'UserAddDisabled';
              $.getLicenseUsers();
            } else {
              $.dialog.type = 'UserInviteError';
              $.hideDialogAfterTwoSec();
            }
          } else {
            $.message.type = 'APIError';
            $.message.text = Object.getOwnPropertyDescriptor(error, 'message').value;
          }
        });
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');

      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
    hideDialogAfterTwoSec: function () {
      const $ = this;

      setTimeout(function () {
        $.dialog.type = null;
      }, 2000);
    },
    resetInviteForm: function () {
      const $ = this;
      $.form.inviteEmail = null;
      $.offlineForm.inviteUserName = null;
      $.offlineForm.inviteUserPassword = null;
      $.offlineForm.inviteUserPasswordAgain = null;
      $.status.mailError = false;
      $.status.passwordError = false;
      $.status.passwordAgainError = false;
    },
  },
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}

.main-block {
  display: flex;
  height: calc(100vh - 60px);
}

.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #eaeaea;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}

.main-item {
  max-width: 1080px;
}

.action__block {
  display: flex;
}

.user__block {
  background-color: #ffffff;
  padding: 30px 40px;
}

.user__block__list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user__block__list-item {
  width: 345px;
  word-break: break-word;
  padding: 0 5px;
}

.user__block__head {
  background-color: #161616;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.user__block__content {
  min-height: 60px;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid #d8d8d8;
}

.user-checkbox {
  width: 40px;
}

.user-email {
  width: 280px;
}

.user-role {
  width: 160px;
}

.user-status {
  width: 280px;
}

.user-info {
  width: 240px;
}

.remain__user {
  flex-basis: calc(100% - 180px);
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  color: #000000;
  text-align: right;
}

.main-button:disabled,
.main-button[disabled] {
  pointer-events: auto;
}

.reset-btn {
  display: inline-block;
}

.input__title {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.input__error {
  width: 100%;
  max-width: 400px;
  text-align: right;
  margin-left: auto;
  margin-right: auto;
}
.form__hint {
  margin-left: auto;
  margin-right: auto;
}

/* dialog scroll bar */
.dialog::-webkit-scrollbar {
  display: unset;
  -webkit-appearance: none;
  width: 35px;
  right: 10px;
}
.dialog::-webkit-scrollbar-thumb {
  border: 4px solid #d8d8d8;
  height: 6px;
  border: 15px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.dialog::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.dialog::-webkit-scrollbar-corner {
  background-color: transparent;
}
</style>
