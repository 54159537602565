<template>
  <div class="site-main">
    <app-header :title="$t('side_bar_menu_contactus')"></app-header>
    <div class="main-block">
      <app-sidebar :isLogin="isOnLoginPage()"></app-sidebar>
      <div class="main-content">
        <div class="main-item">
          <div class="contact__block">
            <div class="contact__left__block">
              <div class="contact__title">{{ $t('contactus_group_title_extend') }}</div>
              <div class="contact__content mt-20">
                <div class="contact__text">{{ $t('contactus_group_title_extend_detail') }}</div>
              </div>
              <div class="contact__title mt-40">{{ $t('contactus_group_title_support') }}</div>
              <div class="contact__content mt-20">
                <div class="contact__subtitle">{{ $t('contactus_service_time') }}</div>
                <div class="contact__text mt-20" v-html="$t('contactus_service_time_detail')"></div>
                <div class="contact__hint mt-20">{{ $t('contactus_desc') }}</div>
                <a class="main-button mt-15" v-if="isQueryValidDataset()" v-on:click="contactTech">{{
                  $t('contactus_group_support_button')
                }}</a>
                <a
                  class="main-button mt-15"
                  v-else
                  :href="
                    'mailto:witcherfin.service@gogolook.com.tw?subject=Technical Support&body=%0D%0A%0D%0A---%0D%0ACompany ID : ' +
                    $store.state.user.brand_id +
                    '%0D%0AUUID : ' +
                    $store.state.user.uuid
                  "
                  >{{ $t('contactus_group_support_button') }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-item" v-if="dialog.type" v-on:click="hidePanel">
      <div class="dialog" id="dialog-main" v-if="dialog.type == 'ContactTech'">
        <div class="dialog-main__header">
          <div class="dialog__cancel icon-close" v-on:click="dismissDialog"></div>
        </div>
        <div class="dialog-main__content">
          <div class="dialog__title">{{ $t('contactus_group_support_dialog_title') }}</div>
          <div class="dialog__block mt-20">
            <div>{{ $t('contactus_group_support_dialog_category') }}</div>
            <!-- <v-select v-model="form.category" :options="options"></v-select> -->
            <div class="custom-selector mt-10">
              <span class="quota-selector-container">
                <div class="quota-selector-label">{{ form.category | splitLine(0) }}</div>
                <select @change="getQuestions" class="quota-selector" v-model="form.category">
                  <option v-bind:value="placeholders">{{ placeholders }}</option>
                  <option
                    v-for="(item, index) in faq.faq_category"
                    v-bind:value="item.value + '|' + item.key"
                    v-bind:key="index"
                  >
                    {{ item.value }}
                  </option>
                </select>
              </span>
            </div>
            <div class="question__title mt-30" v-bind:class="{ disabled: form.category == placeholders }">
              {{ $t('contactus_group_support_dialog_question') }}
            </div>
            <!-- <v-select v-model="form.question" :options="currentQuestions"></v-select> -->
            <div class="custom-selector mt-10">
              <span class="quota-selector-container">
                <div class="quota-selector-label" v-bind:class="{ disabled: form.category == placeholders }">
                  {{ form.question | splitLine(0) }}
                </div>
                <select
                  class="quota-selector"
                  v-model="form.question"
                  v-bind:class="{ disabled: form.category == placeholders }"
                >
                  <option v-bind:value="placeholders">{{ placeholders }}</option>
                  <option
                    v-for="(item, index) in currentQuestions"
                    v-bind:value="item.question + '|' + item.answer"
                    v-bind:key="index"
                  >
                    {{ item.question }}
                  </option>
                </select>
              </span>
            </div>
            <div class="answer__block mt-30">
              <div class="answer__block__placeholder" v-if="form.question == placeholders">
                {{ $t('contactus_group_support_dialog_question_answer_placeholder') }}
              </div>
              <div v-else v-html="$options.filters.splitLine(form.question, 1)"></div>
            </div>
          </div>
        </div>
        <div class="dialog__btns mt-40">
          <a
            class="second-button dialog__button"
            :disabled="form.question == placeholders"
            :href="
              'mailto:witcherfin.service@gogolook.com.tw?subject=Technical Support：' +
              $options.filters.splitLine(form.category, 0) +
              '&body=%0D%0A%0D%0A---%0D%0ACompany ID : ' +
              $store.state.user.brand_id +
              '%0D%0AUUID : ' +
              $store.state.user.uuid
            "
            >{{ $t('contactus_group_support_dialog_contact_us_button') }}</a
          >
          <a
            class="main-button dialog__button ml-20"
            :disabled="form.question == placeholders"
            v-on:click="dismissDialog"
            >{{ $t('contactus_group_support_dialog_ok_button') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

import Header from './base/header.vue';
import Sidebar from './base/sidebar.vue';

export default {
  name: 'ContactUs',
  data() {
    return {
      options: [
        { id: 1, label: 'foo' },
        { id: 3, label: 'bar' },
        { id: 2, label: 'baz' },
      ],
      status: {
        quotaFormatError: false,
        memberFormatError: false,
      },
      form: {
        category: this.$i18n.t('contactus_group_support_dialog_placeholder'),
        categoryID: null,
        question: this.$i18n.t('contactus_group_support_dialog_placeholder'),
        answer: null,
      },
      dialog: {
        type: null,
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      placeholders: this.$i18n.t('contactus_group_support_dialog_placeholder'),
      currentQuestions: [],
      faq: {
        faq_category: [
          {
            key: 'faq_numsearch',
            value: this.$i18n.t('faq_group_title_numsearch'),
          },
          {
            key: 'faq_member',
            value: this.$i18n.t('faq_group_title_member'),
          },
          {
            key: 'faq_banned',
            value: this.$i18n.t('faq_group_title_banned'),
          },
          {
            key: 'faq_other',
            value: this.$i18n.t('faq_group_title_other'),
          },
        ],
        faq_numsearch: [
          {
            question: this.$i18n.t('faq_numsearch_q1'),
            answer: this.$i18n.t('faq_numsearch_a1'),
          },
          {
            question: this.$i18n.t('faq_numsearch_q2'),
            answer: this.$i18n.t('faq_numsearch_a2'),
          },
          {
            question: this.$i18n.t('faq_numsearch_q3'),
            answer: this.$i18n.t('faq_numsearch_a3', {
              contactus_business_email: this.$i18n.t('contactus_business_email'),
            }),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q4'),
            answer: this.$i18n.t('faq_numsearch_a4'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q5'),
            answer: this.$i18n.t('faq_numsearch_a5'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q6'),
            answer: this.$i18n.t('faq_numsearch_a6'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q11'),
            answer: this.$i18n.t('faq_numsearch_a11'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q12'),
            answer: this.$i18n.t('faq_numsearch_a12'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_numsearch_q14'),
            answer: this.$i18n.t('faq_numsearch_a14'),
            show: false,
          },
          {
            question: this.$i18n.t('contactus_group_support_dialog_question_others'),
            answer: this.$i18n.t('contactus_group_support_dialog_question_others_answer'),
            show: false,
          },
        ],
        faq_member: [
          {
            question: this.$i18n.t('faq_member_q1'),
            answer: this.$i18n.t('faq_member_a1', {
              contactus_business_email: this.$i18n.t('contactus_business_email'),
            }),
            show: false,
          },
          {
            question: this.$i18n.t('faq_member_q2'),
            answer: this.$i18n.t('faq_member_a2'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_member_q3'),
            answer: this.$i18n.t('faq_member_a3'),
            show: false,
          },
          {
            question: this.$i18n.t('contactus_group_support_dialog_question_others'),
            answer: this.$i18n.t('contactus_group_support_dialog_question_others_answer'),
            show: false,
          },
        ],
        faq_banned: [
          {
            question: this.$i18n.t('faq_banned_q1'),
            answer: this.$i18n.t('faq_banned_a1'),
            show: false,
          },
          {
            question: this.$i18n.t('faq_banned_q2'),
            answer: this.$i18n.t('faq_banned_a2'),
            show: false,
          },
          {
            question: this.$i18n.t('contactus_group_support_dialog_question_others'),
            answer: this.$i18n.t('contactus_group_support_dialog_question_others_answer'),
            show: false,
          },
        ],
        faq_other: [
          {
            question: this.$i18n.t('faq_other_q1'),
            answer: this.$i18n.t('faq_other_a1'),
            show: false,
          },
          {
            question: this.$i18n.t('contactus_group_support_dialog_question_others'),
            answer: this.$i18n.t('contactus_group_support_dialog_question_others_answer'),
            show: false,
          },
        ],
      },
    };
  },
  components: {
    'v-select': vSelect,
    'app-header': Header,
    'app-sidebar': Sidebar,
  },
  filters: {
    splitLine: function (value, index) {
      return value.split('|')[index];
    },
  },
  created() {
    const $ = this;

    $.updateRemainLicenseQuota();
  },
  methods: {
    contactTech: function () {
      const $ = this;
      $.dialog.type = 'ContactTech';
    },
    getQuestions: function () {
      const $ = this;
      $.form.question = $.placeholders;

      if ($.form.category.split('|')[1] === $.placeholders) {
        $.currentQuestions = [];
      } else {
        $.currentQuestions = $.faq[$.form.category.split('|')[1]];
      }
    },
    hidePanel: function (event) {
      var dm = document.getElementById('dialog-main');
      if (dm) {
        if (!dm.contains(event.target)) {
          this.dialog.type = null;
        }
      }
    },
    dismissDialog: function () {
      const $ = this;
      $.dialog.type = null;
    },
  },
};
</script>

<style scoped>
.site-main {
  display: flex;
  flex-direction: column;
}
.main-block {
  display: flex;
  height: calc(100vh - 60px);
}
.main-content {
  display: flex;
  box-sizing: border-box;
  flex-basis: 80%;
  background-color: #eaeaea;
  flex-direction: column;
  overflow-y: scroll;
  z-index: 1;
  padding: 40px;
}
.contact__block {
  flex-basis: 100%;
  display: flex;
  align-items: center;
}
.contact__left__block,
.contact__right__block {
  box-sizing: border-box;
  flex-basis: 45%;
  max-width: 400px;
  height: 400px;
  text-align: left;
}
.contact__title {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}
.contact__content {
  background-color: #ffffff;
  padding: 20px 40px;
}
.contact__subtitle {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.contact__text {
  font-size: 14px;
  color: #000000;
}
.contact__hint {
  font-size: 12px;
  color: #aeaeae;
}
.dialog {
  height: calc(100% - 40px);
  max-height: 640px;
  padding: 0;
}
.dialog-main__header {
  height: 60px;
}
.dialog-main__content {
  box-sizing: border-box;
  height: calc(100% - 180px);
  padding: 0 70px;
  overflow-y: auto;
  flex-direction: column;
}
.dialog-main__content::-webkit-scrollbar {
  display: unset;
  -webkit-appearance: none;
  width: 35px;
  right: 10px;
}
.dialog-main__content::-webkit-scrollbar-thumb {
  border: 4px solid #d8d8d8;
  height: 6px;
  border: 15px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.dialog-main__content::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.dialog-main__content::-webkit-scrollbar-corner {
  background-color: transparent;
}
.dialog__title {
  width: 540px;
}
.dialog__block {
  width: 540px;
  text-align: left;
}
.dialog__btns {
  width: 540px;
  display: flex;
  justify-content: center;
}
.answer__block {
  height: 19vh;
  max-height: 120px;
  padding: 20px;
  border: solid 1px #d8d8d8;
  overflow-y: scroll;
}
.answer__block::-webkit-scrollbar {
  display: unset;
  -webkit-appearance: none;
  width: 35px;
  right: 10px;
}
.answer__block::-webkit-scrollbar-thumb {
  border: 4px solid #d8d8d8;
  height: 6px;
  border: 15px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
.answer__block::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.answer__block::-webkit-scrollbar-corner {
  background-color: transparent;
}
.answer__block__placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.quota-selector-container {
  /* width: 160px; */
  position: relative;
  overflow: hidden;
  display: block;
  height: 40px;
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
}
.quota-selector-container::after {
  display: block;
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  width: 16px;
  height: 16px;
  font-size: 60%;
  line-height: 40px;
  font-family: 'witcherfin';
  content: '\e905';
  pointer-events: none;
}
.quota-selector-label {
  padding-right: 25px;
  font-size: 14px;
  color: #000000;
  line-height: 40px;
  padding: 0 20px;
}
.quota-selector-label.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.quota-selector {
  cursor: pointer;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  -webkit-appearance: none;
}
.question__title.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.input__block {
  width: 100%;
  height: 40px;
  background-color: #eaeaea;
}
.input__block.disabled {
  background-color: rgba(234, 234, 234, 0.3);
  border: 1px solid rgba(234, 234, 234, 0.3);
}
.form__error {
  max-width: unset;
}
.inline__input {
  box-sizing: border-box;
  width: calc(100% - 80px);
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  font-size: 14px;
  word-break: normal;
  word-wrap: normal;
}
.inline__input:disabled,
.inline__input[disabled] {
  background-color: rgba(234, 234, 234, 0.3);
  border: 1px solid rgba(234, 234, 234, 0.3);
}
.input__unit {
  font-size: 14px;
  color: #000000;
}
</style>
